import styled, { css } from 'styled-components';

interface ArrowProps {
  image: string;
  position: 'left' | 'right'
}

export const Arrow = styled.div.withConfig({
  shouldForwardProp: (prop) => !['image'].includes(prop),
})<ArrowProps>(
  ({
    theme: {
      device: { isMobile },
    },
    image,
    position
  }) => css`
      width: 30px;
      height: 30px;
      object-fit: contain;
      cursor: pointer;
      background-image: url('${image}');
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(((100vh - 15vh) / 2) + 10px);
      ${position === 'left' ? 'left: 5%;' : 'right: 5%;'}
  `
);