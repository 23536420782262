import styled from 'styled-components';
import { TK_PLAYER_DIV_ID_2D, TK_PLAYER_DIV_ID_3D } from '../../../utils/constants';

interface WrapperProps {
  height?: string;
  maxHeight?: string;
  width?: string;
  border?: string;
  isRotable?: boolean;
  conditionalCSS?: boolean;
  fullScreen: boolean;
}

export const Wrapper = styled.div
  .attrs<WrapperProps>(({ theme, isRotable, conditionalCSS }) => ({
    style: {
      cursor: isRotable ? 'grab' : 'default',
      borderRight: theme.device.isMobile ? 'none' : '1px solid #ccc',
      height: theme.device.isMobile ? '' : '100vh',
      flex: !theme.device.isMobile ? '2 0' : '1 0',
      opacity: conditionalCSS ? '1' : '0',
    },
  }))
  .withConfig({
    shouldForwardProp: (prop) =>
      !['isRotable', 'conditionalCSS', 'fullScreen'].includes(prop),
  })<WrapperProps>(
  ({ fullScreen }) => `
  position: ${fullScreen ? '' : 'relative'};
  user-select: none;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  &:active {
    cursor: grabbing;
  }

  #${TK_PLAYER_DIV_ID_2D}, #${TK_PLAYER_DIV_ID_3D} {
      height: ${fullScreen ? '100vh' : '100%'};
      width: ${fullScreen ? '100vw' : '100%'};
      position: ${fullScreen ? 'absolute' : 'relative'};
      top: ${fullScreen ? '0' : ''};
      left: ${fullScreen ? '0' : ''};
      background-color: ${fullScreen ? 'white' : 'transparent'};
      z-index: ${fullScreen ? 9999999 : 0};
      overflow: hidden;

      @media (max-width: 768px) {
        min-height: 40%;
        transform: translate(0%, 0%);
      }

      div[class*='threekit'] {
        div[class*='holder'] {
          div[class*='player'] {
            div[class*='logo'] {
              display: none;
            }
          }
        }
      }
    }
`
);
