import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { Buffer } from 'buffer';
import {
  getInitialConfiguration,
  getIsChina,
  getPreviousCamera,
  getReadableConfigurationWithAttributeType,
  setConfiguration,
  setSavedConfigurationData,
  setPage,
} from '../../../store/threekitSlicer';
import { ButtonContainer, ButtonWrapper, Dot, LabelBold, LoaderWrapper, LoadingText } from './OptionsButton.style';
import {
  RESET_ICON,
  INSTOCK_ICON,
  SHARE_ICON,
  CART_ICON_OOB,
  CART_ICON,
  EDIT_ICON,
  ZOOM_ICON,
  UNZOOM_ICON,
  INFO_PRESS_ICON,
  COPIED_ICON,
  SHARING_ICON,
  STOP_SHARING_ICON,
  QR,
  SHARE_PRODUCT_ICON,
} from '../../../assets';
import { getGlobalSettingsParams } from '../../../store/globalSettingsSlicer/selectors';
import {
  ADDTOCART_BUTTON_LABEL,
  CATALOGDESKTOP_APPNAME,
  EDIT_BUTTON_LABEL,
  FINISH_BUTTON_LABEL,
  INFO_ZOOM_BUTTON_LABEL,
  INSTOCK_BUTTON_LABEL,
  JOURNEYWECOM_APPNAME,
  LEADTIME_BUTTON_LABEL,
  OOB_APPNAME,
  QRCODE_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  SHARE_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING,
  START_SHARING_BUTTON_LABEL,
  STOP_SHARING_BUTTON_LABEL,
  TK_SAVED_CONFIG_PARAM_KEY,
  ZOOM_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL_DISABLED,
  CONTINUE_BUTTON_LABEL,
  SHARE_PRODUCT_LABEL,
  STOP_SHARING_AFTER_ADDTOCART,
  KEEP_SHARING_BUTTON_LABEL,
  PICTURE_FORMAT,
  constructImageUrl,
} from '../../../utils/constants';
import { copyTextToClipboard } from '../../../utils/function/functions';
import { useAttribute, useWindowSize } from '../../../utils/threekitHooks';
import { getSavedConfigurationData } from '../../../store/threekitSlicer/selectors/savedConfigurationData';
import { IParams, setGlobalSettingsParams, setStep } from '../../../store/globalSettingsSlicer';
import { useProductName, useSocket } from '../../../hooks';
import { getSavedConfig } from '../../../utils/ApiCalls/ApiCalls';
import { usePrice } from '../../../hooks';
import useParams from '../../../hooks/useParams';
import {
  getFormTextInputFields,
  getFormWarningMessages,
  setFormTextInputFields,
} from '../../../store/validationSlicer';
import { PopUpType, showPopUp } from '../../PopUp/ShowPopUp';
import { popUpTypes } from '../../PopUp/PopUpType';
import { ILeadTimeAndPrice, parseRecipe } from '../../../utils/function/mapping';
import { getIsSessionLive, getUrlCopied } from '../../../store/liveSessionSlicer';
import { isClickableButtonLabel, sendEventToDynatrace } from '../../../utils/dyntatraceUtils';
import StopSharing from '../../../pages/HomePage/components/StopSharing';
import Modal from '../../../pages/HomePage/components/Modal';

interface OptionsButtonProps {
  buttonName: string;
  buttonText?: string;
  summaryPage?: boolean;
  shouldDisableFinishButton?: boolean;
  leadTime?: {
    min: number;
    max: number;
  };
  notOrderable?: boolean;
  notOrderableMessage?: string;
  fnButton?: Function;
  isFullScreen?: boolean;
  isDisabled?: boolean;
}

const OptionsButton: React.FC<OptionsButtonProps> = ({
  buttonName,
  buttonText = '',
  summaryPage = false,
  shouldDisableFinishButton = false,
  leadTime,
  notOrderable = false,
  isFullScreen = false,
  fnButton = () => { },
  isDisabled = false,
}) => {
  const dispatch = useDispatch();
  const isSessionLive = useSelector(getIsSessionLive);
  const copied = useSelector(getUrlCopied);

  const { isMobile, isDesktop } = useWindowSize();
  const [clicked, setClicked] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [saveConfigurationError, setSaveConfigurationError] = useState<boolean>(false);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName } = globalSettingsParams || {};
  const { baseUrlClient } = useParams();
  const productName = useProductName();
  const { currency, priceWithCurrency, priceValue } = usePrice();
  const [, setCamera] = useAttribute('Camera');
  const defaultCameraId = useSelector(getPreviousCamera);
  const minLeadTime = leadTime?.min;
  const maxLeadTime = leadTime?.max;
  const isChina = useSelector(getIsChina);
  const readableConfiguration = useSelector(getReadableConfigurationWithAttributeType());
  const formTextInputFields = useSelector(getFormTextInputFields);
  const formWarningMessages = useSelector(getFormWarningMessages);
  const configurationSavedData = useSelector(getSavedConfigurationData);
  const initialConfig = useSelector(getInitialConfiguration);
  const { disconnect: disconnectSocket } = useSocket();

  const dataDrivenSku = window.dataDrivenConfiguratorExtension?.getStatus().sku?.value;

  const handleReset = useCallback(async (): Promise<void> => {
    dispatch<any>(setConfiguration(initialConfig));
    dispatch(setStep(0));

    const newTextInputs = Object.keys(formTextInputFields).reduce((accumulator: any, key) => {
      accumulator[key] = '';
      return accumulator;
    }, {});

    dispatch(setFormTextInputFields(newTextInputs));
  }, [dispatch, initialConfig, formTextInputFields]);

  // const {
  //   data: token,
  //   isLoading: isTokenLoading,
  //   isError: isTokenError,
  //   error: tokenError,
  // } = useQuery({
  //   queryKey: ['token'],
  //   queryFn: () => fetchToken(),
  //   staleTime: 300000,
  //   refetchOnWindowFocus: false,
  //   enabled: true,
  //   retry: false,
  // });

  const clientUrl = useMemo((): string | undefined => {
    if (!globalSettingsParams?.configId || !baseUrlClient) return;
    //put back when tokens work
    // const result = `https://${baseUrlClient}/${globalSettingsParams?.configId}${
    //   token ? `?token=${token?.token}` : ''
    // }`

    const result = `https://${baseUrlClient}/${globalSettingsParams?.configId}`;
    return encodeURI(result);
  }, [globalSettingsParams, baseUrlClient]);

  const handleShare = useCallback(async (): Promise<void> => {
    setClicked(true);
    if (!clientUrl) {
      console.error('No clientUrl found (probably no configId)');
      return;
    }

    if (appName === OOB_APPNAME || appName === CATALOGDESKTOP_APPNAME) {
      const eventDataShare = {
        eventName: 'ShareClientUrlAlpha',
        eventData: { clientUrl },
      };
      window.parent.postMessage(eventDataShare, '*');
    } else {
      await copyTextToClipboard(clientUrl);
    }
    showPopUp({
      popUpType: popUpTypes.linkCopied as PopUpType,
      functionOnClose: () => setClicked(false),
    });
  }, [clientUrl, appName]);

  const handleFinish = useCallback((): void => {
    setClicked(true);
    const hasFormWarnings: any = formWarningMessages && Object.keys(formWarningMessages).length > 0;

    console.log({ clientUrl });

    if ((shouldDisableFinishButton || notOrderable || hasFormWarnings) && !baseUrlClient?.startsWith('prp')) {
      if (!hasFormWarnings && (shouldDisableFinishButton || notOrderable)) {
        showPopUp({ popUpType: popUpTypes.notOrderableError as PopUpType });
      }

      if (hasFormWarnings) {
        Object.values(formWarningMessages).forEach((message) =>
          showPopUp({
            popUpType: popUpTypes.missingFieldError as PopUpType,
            message,
          })
        );
      }

      if (notOrderable) {
        showPopUp({ popUpType: popUpTypes.notOrderableError as PopUpType });
      }

      setClicked(false);
      return;
    }
    const locale = globalSettingsParams['lng'] || 'en_E1';
    defaultCameraId && setCamera(defaultCameraId);

    const getSavedConfiguration = async () => {
      const data = await window?.threekit?.controller.saveConfiguration({
        priceWithCurrency,
        productName,
      });
      return data;
    };

    const getAttachementsThroughAPI = async ({ recipeId }: { recipeId: string; }) => {
      const data = await getSavedConfig({
        recipeId,
        isMobile,
      });

      if (data?.attachments) {
        return {
          front: constructImageUrl(data?.attachments?.Front, PICTURE_FORMAT),
          backAndFront: constructImageUrl(data?.attachments?.Open, PICTURE_FORMAT),
          back: constructImageUrl(data?.attachments?.Side, PICTURE_FORMAT)
        };
      } else {
        return {};
      }
    };

    getSavedConfiguration().then((data) => {
      window.dataDrivenConfiguratorExtension
        .saveConfiguration({ Patch: window.playerMonogram }, locale, {
          priceCurrency: currency || 'EUR',
          priceAmount: priceValue || 999999,
          productName,
          sku: dataDrivenSku,
          appName,
        })
        .then(async (recipeId: string) => {
          const attachments = await getAttachementsThroughAPI({ recipeId });
          dispatch(setSavedConfigurationData({ ...data, attachments }));
          const params: IParams = {
            ...globalSettingsParams,
            sku: dataDrivenSku,
            recipeId,
            price: priceWithCurrency,
            productName: productName,
          };
          if (recipeId) {
            params[TK_SAVED_CONFIG_PARAM_KEY] = recipeId;
          }
          if (!params?.[TK_SAVED_CONFIG_PARAM_KEY]) delete params[TK_SAVED_CONFIG_PARAM_KEY];
          dispatch(setGlobalSettingsParams(params));
          dispatch(setPage('summary'));
        })
        .catch(() => {
          setSaveConfigurationError(true);
          setClicked(false);
          showPopUp({
            popUpType: popUpTypes.savedConfigurationError as PopUpType,
          });
        });
    });
  }, [
    defaultCameraId,
    setCamera,
    dispatch,
    priceWithCurrency,
    globalSettingsParams,
    dataDrivenSku,
    productName,
    currency,
    priceValue,
    appName,
    setClicked,
    formWarningMessages,
    shouldDisableFinishButton,
    notOrderable,
    isMobile,
    setSaveConfigurationError,
    clientUrl,
    baseUrlClient,
  ]);

  const handleEdit = useCallback((): void => {
    setClicked(false);
    dispatch(setPage('home'));
  }, [dispatch]);

  const handleAddToCart = useCallback(async (): Promise<void> => {
    if (isSessionLive && !isConfirmModalOpen) {
      setConfirmModalOpen(true);
      return;
    }
    setClicked(true);
    const leadTimeAndPrice: ILeadTimeAndPrice = {
      price: priceValue || 999999,
      currency: currency || 'EUR',
      minLeadTime: minLeadTime || 1,
      maxLeadTime: maxLeadTime || 99,
    };

    const json = {
      ...configurationSavedData,
      metadata: { readableConfiguration },
      createdAt: new Date().toISOString(),
      clientUrl,
      productName,
      inStore: configurationSavedData?.inStore || false,
      sku: dataDrivenSku,
    };

    let parsedJsonToSend: any = parseRecipe(json, leadTimeAndPrice);

    if (!dataDrivenSku?.startsWith('P')) {
      parsedJsonToSend.shortId = '';
    } else {
      parsedJsonToSend.shortId = globalSettingsParams['configId'] || '';
    }

    if (isChina) {
      parsedJsonToSend = Buffer.from(JSON.stringify(parsedJsonToSend)).toString('base64');

      if (appName === JOURNEYWECOM_APPNAME) {
        //@ts-ignore
        wx?.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            //@ts-ignore
            wx.miniProgram.postMessage({
              data: { threekitPersoProduct: parsedJsonToSend },
            });
            // @ts-ignore
            wx?.miniProgram.redirectTo({
              url: `/packageA/cart/cart`,
            });
          }
        });
      } else {
        //@ts-ignore
        wx?.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            //@ts-ignore
            wx.miniProgram.postMessage({
              data: { threekitPersoProduct: parsedJsonToSend },
            });
            // @ts-ignore
            wx?.miniProgram.switchTab({
              url: `/pages/Basket/Basket`,
            });
          }
        });
      }
    }

    if (window.webkit?.messageHandlers?.onAddToCart) {
      window.webkit.messageHandlers.onAddToCart.postMessage({
        message: parsedJsonToSend,
      });
      console.log('jsonAddToCart', parsedJsonToSend);
    } else if (appName === OOB_APPNAME) {
      const eventData = {
        eventName: 'AddToCartAlpha',
        eventData: { parsedJsonToSend },
      };
      window.parent.postMessage(eventData, '*');
    } else if ((window as any)[String(appName)]?.configureAddToCart) {
      (window as any)[String(appName)]?.configureAddToCart(parsedJsonToSend);
      console.info({ parsedJsonToSend });
      console.info('jsonAddToCart', parsedJsonToSend);
    } else {
      console.info('jsonAddToCart', parsedJsonToSend);
    }

    setTimeout(() => {
      console.log('AddToCartReleased');
      setClicked(false);
    }, 10000);
  }, [
    isChina,
    currency,
    minLeadTime,
    maxLeadTime,
    priceValue,
    configurationSavedData,
    appName,
    clientUrl,
    globalSettingsParams,
    dataDrivenSku,
    productName,
    readableConfiguration,
    isConfirmModalOpen,
    isSessionLive,
  ]);

  useEffect(() => {
    setClicked(false);
  }, [dataDrivenSku]);

  let label;
  let iconSrc;
  let secondIconSrc;
  let functionOnclick: Function;

  switch (buttonName) {
    case INSTOCK_BUTTON_LABEL:
      label = t('button.label.inStock', 'In Stock');
      iconSrc = INSTOCK_ICON;
      functionOnclick = () => { };
      break;
    case CONTINUE_BUTTON_LABEL:
      label = t('button.label.continue', CONTINUE_BUTTON_LABEL);
      functionOnclick = fnButton;
      break;
    case LEADTIME_BUTTON_LABEL:
      label = buttonText?.startsWith('Lead time') ? (
        <>
          {buttonText?.slice(0, 9)}
          <LabelBold>{buttonText?.slice(10)}</LabelBold>
        </>
      ) : (
        buttonText || 'Not orderable'
      );
      functionOnclick = () => { };
      break;
    case ZOOM_BUTTON_LABEL:
      label = '';
      iconSrc = isFullScreen ? UNZOOM_ICON : ZOOM_ICON;
      functionOnclick = fnButton;
      break;
    case QRCODE_BUTTON_LABEL:
      label = '';
      iconSrc = QR;
      functionOnclick = fnButton;
      break;
    case SHARE_PRODUCT_LABEL:
      label = t('product.label.share', SHARE_PRODUCT_LABEL);
      iconSrc = SHARE_PRODUCT_ICON;
      functionOnclick = handleShare;
      break;
    case SHARE_SCREEN_BUTTON_LABEL_MOBILE:
      label = '';
      iconSrc = isSessionLive ? SHARING_ICON : copied ? COPIED_ICON : SHARE_ICON;
      functionOnclick = fnButton;
      break;
    case START_SHARING_BUTTON_LABEL:
      label = t('button.label.start.sharing', START_SHARING_BUTTON_LABEL);
      functionOnclick = fnButton;
      break;
    case COPIED_URL_MODAL_BUTTON_LABEL:
    case COPIED_URL_MODAL_BUTTON_LABEL_DISABLED:
      label = t('info.copied_url', COPIED_URL_MODAL_BUTTON_LABEL);
      functionOnclick = fnButton;
      break;
    case SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING:
      label = '';
      secondIconSrc = SHARING_ICON;
      iconSrc = STOP_SHARING_ICON;
      functionOnclick = fnButton;
      break;
    case INFO_ZOOM_BUTTON_LABEL:
      label = t('info.zoom_press', 'Press and hold to zoom');
      iconSrc = ZOOM_ICON;
      secondIconSrc = INFO_PRESS_ICON;
      functionOnclick = () => { };
      break;
    case RESET_BUTTON_LABEL:
      label = t('button.label.reset', 'Reset');
      iconSrc = RESET_ICON;
      functionOnclick = handleReset;
      break;
    case SHARE_SCREEN_BUTTON_LABEL:
      label =
        buttonText === '' && !isSessionLive
          ? buttonText
          : isSessionLive
            ? t('button.label.stop.sharing', 'Stop')
            : copied
              ? t('button.label.copied_to_clipboard', 'Link Copied to Clipboard')
              : t('button.label.share_screen', SHARE_SCREEN_BUTTON_LABEL);
      iconSrc = isSessionLive ? SHARING_ICON : copied ? COPIED_ICON : SHARE_ICON;
      functionOnclick = fnButton;
      break;
    case SHARE_BUTTON_LABEL:
      label = t('button.label.share', 'Share');
      iconSrc = SHARE_ICON;
      functionOnclick = handleShare;
      break;
    case KEEP_SHARING_BUTTON_LABEL:
      label = t('button.label.stop.sharing_continue', KEEP_SHARING_BUTTON_LABEL);
      functionOnclick = fnButton;
      break;
    case STOP_SHARING_BUTTON_LABEL:
      label = t('button.label.stop.sharing_validation', 'Yes, stop sharing');
      iconSrc = STOP_SHARING_ICON;
      functionOnclick = fnButton;
      break;
    case EDIT_BUTTON_LABEL:
      label = t('button.label.edit', EDIT_BUTTON_LABEL);
      iconSrc = EDIT_ICON;
      functionOnclick = handleEdit;
      break;
    case FINISH_BUTTON_LABEL:
      label = t('button.label.finish', 'Finish');
      iconSrc = null;
      functionOnclick = !showLoader ? handleFinish : () => { };
      break;
    case ADDTOCART_BUTTON_LABEL:
      label = t('button.label.cart', 'Add to Cart');
      iconSrc = appName === OOB_APPNAME && isDesktop ? CART_ICON_OOB : CART_ICON;
      functionOnclick = handleAddToCart;
      break;
    default:
      label = buttonName;
      iconSrc = null;
      functionOnclick = () => { };
  }

  const shareButtonDisabled = useMemo((): boolean => {
    return buttonName === SHARE_BUTTON_LABEL && clicked;
  }, [buttonName, clicked]);

  const AddToCartButtonDisabled = useMemo((): boolean => {
    return buttonName === ADDTOCART_BUTTON_LABEL && clicked;
  }, [buttonName, clicked]);

  useEffect(() => {
    const button = document.getElementById(buttonName);

    const handleClick = () => {
      console.log(`Button ${buttonName} was clicked!`);
      if (isClickableButtonLabel(buttonName)) sendEventToDynatrace(`button_${buttonName}_click`);
      console.log(`Button ${buttonName} was finished!`);
    };

    if (button) {
      button.addEventListener('click', handleClick);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick);
      }
    };
  }, [buttonName]);

  useEffect(() => {
    const isLoading =
      buttonName === FINISH_BUTTON_LABEL &&
      clicked &&
      !shouldDisableFinishButton &&
      !saveConfigurationError &&
      !notOrderable;
    setShowLoader(isLoading);
  }, [clicked, shouldDisableFinishButton, notOrderable, buttonName, saveConfigurationError]);

  return (
    <>
      <ButtonContainer
        buttonName={buttonName}
        data-dtname={`dt-${buttonName}`}
        id={buttonName}
        copied={copied}
        sharing={isSessionLive}
        isFullScreen={isFullScreen}
        onClick={(e) => {
          functionOnclick(e);
        }}
        disabled={
          (((shareButtonDisabled || AddToCartButtonDisabled || shouldDisableFinishButton || saveConfigurationError) &&
            clicked) ||
            isDisabled) &&
          !baseUrlClient?.startsWith('prp')
        }
        summaryPage={summaryPage}
      >
        {showLoader ? (
          <Loader />
        ) : (
          <>
            {secondIconSrc && <img data-dtname={`dt-${buttonName}`} alt="" src={secondIconSrc} />}
            {iconSrc && <img data-dtname={`dt-${buttonName}`} alt="" src={iconSrc} />}
            {!((buttonName === SHARE_BUTTON_LABEL || buttonName === RESET_BUTTON_LABEL) && isMobile) &&
              buttonName !== INFO_ZOOM_BUTTON_LABEL &&
              label}
            {buttonName === INFO_ZOOM_BUTTON_LABEL && <ButtonWrapper>{label}</ButtonWrapper>}
          </>
        )}
      </ButtonContainer>
      {buttonName === ADDTOCART_BUTTON_LABEL && (
        <Modal
          opacity={isConfirmModalOpen ? 1 : 0}
          isOpen={isConfirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          title={t('Remote configurator')}
          isMobile={isMobile}
        >
          <StopSharing
            handleCloseModal={() => {
              setConfirmModalOpen(false);
              functionOnclick();
            }}
            handleStopSharing={() => {
              sendEventToDynatrace(`button_${STOP_SHARING_AFTER_ADDTOCART}_click`);
              disconnectSocket();
              setConfirmModalOpen(false);
            }}
            showCancelButton={true}
          ></StopSharing>
        </Modal>
      )}
    </>
  );
};

export default OptionsButton;

const Loader = (): ReactElement => (
  <LoaderWrapper>
    <LoadingText>Loading</LoadingText>
    <Dot delay="0s" />
    <Dot delay="0.15s" />
    <Dot delay="0.3s" />
  </LoaderWrapper>
);
