import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../../pages/HomePage/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import StopSharing from '../../pages/HomePage/components/StopSharing';
import StartSharing from '../../pages/HomePage/components/StartSharing/';
import {
  getServerSocket,
  getServerSocketPath,
  getIsSessionLive,
  getRoomId,
  setIsSessionLive,
  setRoomId,
  setUrlCopied,
  setIsInvalidCode,
  getBaseUrlClientRemoteViewer,
  getHubName,
} from '../../store/liveSessionSlicer';
import {
  getParams,
  paramsObjectToNavigationString,
} from '../../utils/function/navigationParams';
import { useSocket, useSocketEvents } from '../../hooks';
import { copyTextToClipboard } from '../../utils/function/functions';
import { useQuery } from '@tanstack/react-query';
import { fetchSocketJwtToken } from '../../utils/ApiCalls/ApiCalls';
import { CATALOGDESKTOP_APPNAME, LOCAL_STORAGE_ROOM_ID_KEY, OOB_APPNAME, VALID_ROOM_ID_LENGTH } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { getIsChina } from '../../store/threekitSlicer';
import { PopUpType, showPopUp } from '../PopUp/ShowPopUp';
import { popUpTypes } from '../PopUp/PopUpType';
import { displayModes } from './displayModes';

function SharingModal({
  isOpen,
  handleClose,
  isMobile,
  timeoutRef,
}: {
  isOpen: boolean;
  handleClose: () => void;
  isMobile: boolean;
  timeoutRef: MutableRefObject<NodeJS.Timeout | null>;
}) {
  useSocketEvents({ handleClose });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChina = useSelector(getIsChina);
  const { roomId: roomIdParam, appName, displayMode = displayModes.device } = getParams() || {};
  const [QRModalOpen, setQrModalOpen] = useState(false);
  const isSessionLive = useSelector(getIsSessionLive);
  const HUB_NAME = useSelector(getHubName);
  const SERVER_SOCKET = useSelector(getServerSocket);
  const SERVER_SOCKET_PATH = useSelector(getServerSocketPath);
  const BASE_URL_CLIENT_REMOTE_VIEWER = useSelector(getBaseUrlClientRemoteViewer);
  const roomId = useSelector(getRoomId);
  const {
    connect: connectSocket,
    disconnect: disconnectSocket
  } = useSocket();

  const handleStartSession = useCallback(
    async (room: string) => {
      const httpPre = SERVER_SOCKET.includes('localhost') ? 'http' : 'https';
      const isValid = await connectSocket({
        url: `${httpPre}://${SERVER_SOCKET}`,
        path: `${SERVER_SOCKET_PATH}/${HUB_NAME}`,
        query: {
          role: 'seller',
          roomId: room,
          hub: HUB_NAME,
        },
      });

      if (isValid) {
        handleClose();
        dispatch(setIsSessionLive(true));
      } else {
        dispatch(setIsInvalidCode(true));
      }
    },
    [SERVER_SOCKET_PATH, SERVER_SOCKET, dispatch, handleClose, connectSocket]
  );

  useEffect(() => {
    const stringRoomIdParam = roomIdParam + '';
    const roomIdFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_ROOM_ID_KEY);
    if (
      ((roomIdFromLocalStorage &&
        roomIdFromLocalStorage?.length === VALID_ROOM_ID_LENGTH) ||
        (!roomId &&
          stringRoomIdParam &&
          stringRoomIdParam.length === VALID_ROOM_ID_LENGTH)) &&
      !isSessionLive
    ) {
      dispatch(setRoomId(roomIdFromLocalStorage || stringRoomIdParam));
      handleStartSession(roomIdFromLocalStorage || stringRoomIdParam);
    }
  }, [roomIdParam, dispatch, isSessionLive, handleStartSession, roomId]);

  // This functions adds a RoomId to the url paramater
  useEffect(() => {
    if (
      !isSessionLive ||
      (roomId && !roomIdParam) ||
      (roomId && roomIdParam + '' !== roomId)
    ) {
      const params = getParams();
      params.roomId = Number(roomId);
      const finalParams = paramsObjectToNavigationString(params, isChina);
      navigate(`/${finalParams}`);
    }
  }, [roomId, isSessionLive]);


  const fetchWithTimeout = <T,>(promise: Promise<T>,
    timeoutDuration: number) => {
    return Promise.race([
      promise,
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
      ),
    ]);
  };

  const {
    data: socketJwtToken = '',
    isLoading: isSocketTokenLoading,
    isError: isSocketTokenError,
  } = useQuery({
    queryKey: ['socketJwtToken', { roomId: roomId }],
    queryFn: () => fetchWithTimeout(fetchSocketJwtToken({ roomId, displayMode }), 5000),
    staleTime: 300000,
    refetchOnWindowFocus: false,
    enabled: roomId.length === VALID_ROOM_ID_LENGTH,
    retry: (failureCount) => {
      return failureCount < 12;
    },
  });

  const clientUrl = useMemo(() => {
    const baseUrl = window.location.host.includes('localhost')
      ? 'localhost:3001'
      : BASE_URL_CLIENT_REMOTE_VIEWER;

    return roomId && socketJwtToken
      ? `${baseUrl}/${roomId}?displayMode=${displayMode}&token=${socketJwtToken}`
      : isSocketTokenLoading
        ? 'loading'
        : isSocketTokenError
          ? 'error'
          : 'loading';
  }, [roomId, BASE_URL_CLIENT_REMOTE_VIEWER, socketJwtToken, isSocketTokenLoading, isSocketTokenError]);

  const copyURL = useCallback(() => {
    dispatch(setUrlCopied(true));
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      dispatch(setUrlCopied(false));
      timeoutRef.current = null;
    }, 2_000);
    if (appName === OOB_APPNAME || appName === CATALOGDESKTOP_APPNAME) {
      const eventDataShare = {
        eventName: 'ShareClientUrlAlpha',
        eventData: { clientUrl },
      };
      window.parent.postMessage(eventDataShare, '*');
    } else {
      copyTextToClipboard(clientUrl);
    }
    showPopUp({
      popUpType: popUpTypes.linkCopied as PopUpType,

    });
  }, [dispatch, clientUrl, timeoutRef]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Modal
      opacity={QRModalOpen ? 0 : 1}
      isOpen={isOpen}
      onClose={handleClose}
      title={t('Remote configurator')}
      isMobile={isMobile}
    >
      {isSessionLive ? (
        <StopSharing
          handleCloseModal={handleClose}
          handleStopSharing={disconnectSocket}
        />
      ) : (
        <StartSharing
          handleCloseModal={handleClose}
          QRModalOpen={QRModalOpen}
          setQrModalOpen={setQrModalOpen}
          isMobile={isMobile}
          handleStartSession={handleStartSession}
          copyURL={copyURL}
          clientUrl={clientUrl}
        />
      )}
    </Modal>
  );
}

export default SharingModal;
