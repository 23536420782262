import { useSelector } from 'react-redux';
import { getForm } from '../../store/threekitSlicer';
import { useWindowSize } from '../../utils/threekitHooks';
import { Container } from './Form.style';
import Carousel from './Carousel/Carousel';
import Accordion from './Accordion/Accordion';

const Form = () => {
  const { isMobile } = useWindowSize();
  const formData = useSelector(getForm);

  const aboveFormEl = document.querySelector('.productAndAboveForm-container');
  const aboveFormHeight = aboveFormEl?.clientHeight || 0;

  return formData ? (
    <Container aboveFormHeight={aboveFormHeight}>
      {isMobile ? <Carousel /> : <Accordion />}
    </Container>
  ) : null;
};

export default Form;
