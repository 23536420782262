import Form from '../../../components/Form/Form';
import {
  AboveFormContainer,
  InnerContainer,
  ProductAndAboveContainer,
  ProductName,
  ProductNameAndReset,
  Reset,
  ShareScreen,
  WrapperContainer,
} from '../Home.styles';
import StockStatus from '../../../components/Form/StockStatus/StockStatus';
import ProductInfos from './ProductInfos';
import AutoDisappearElement from './AutoDisappearElement';
import { useProductName } from '../../../hooks';
import {
  RESET_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL,
} from '../../../utils/constants';
import OptionsButton from '../../../components/Form/OptionsButton';
import { useSelector } from 'react-redux';
import { getGlobalSettingsParams } from '../../../store/globalSettingsSlicer';
import { ConfiguratorProps } from './FormProps';
import { getHideRemoteViewer } from '../../../store/liveSessionSlicer';

export const RegularForm = ({
  handleShareScreenClick,
}: ConfiguratorProps) => {
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const productName = useProductName();
  const hideRemoteViewer = useSelector(getHideRemoteViewer);

  return (
    <InnerContainer>
      <WrapperContainer>
        <ProductAndAboveContainer className="productAndAboveForm-container">
          <AutoDisappearElement>
            <ProductNameAndReset>
              <ProductName>{productName}</ProductName>
              {!hideRemoteViewer && (<ShareScreen onClick={handleShareScreenClick}>
                <OptionsButton
                  buttonName={SHARE_SCREEN_BUTTON_LABEL}
                />
              </ShareScreen>)}
              <Reset>
                <OptionsButton buttonName={RESET_BUTTON_LABEL} />
              </Reset>
            </ProductNameAndReset>
          </AutoDisappearElement>
          <AboveFormContainer>
            <ProductInfos
              globalSettingsParams={globalSettingsParams}
              isRegular
            />
            <StockStatus />
          </AboveFormContainer>
        </ProductAndAboveContainer>
        <Form />
      </WrapperContainer>
    </InnerContainer>
  );
};

export default RegularForm;
