export const COLORS = {
  black: '#000000',
  white: '#FFFFFF',
  lightGray: '#E6E6E6',
  mediumGray: '#7e7e7e',
  mediumGray2: '#A6A6A6',
  darkGray: '#2C2C2E',
  darkBrown: '#18110C',
  ivory: '#F5F5F5',
  scarlet: '#D90000',
};
