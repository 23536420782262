import { t } from 'i18next';
import OptionsButton from '../OptionsButton';
import { ButtonContainerShimmer } from '../OptionsButton/OptionsButton.style';
import {
  INSTOCK_BUTTON_LABEL,
  LEADTIME_BUTTON_LABEL,
} from '../../../utils/constants';
import useStock from '../../../hooks/useStock';
import useLeadTime from '../../../hooks/useLeadTime';
import React, { ReactElement } from 'react';

interface IStockStatus {
  summaryPage?: boolean | undefined;
}

const StockStatus = React.memo(
  ({ summaryPage }: IStockStatus): ReactElement => {
    const { data: stockQuantityData, isLoading: isLoadingStock } = useStock();
    const {
      isLoading: isLoadingLeadTime,
      translatedLeadTime,
      leadTime: leadTimeData,
    } = useLeadTime();

    if (isLoadingLeadTime || isLoadingStock) {
      return <ButtonContainerShimmer />;
    }

    if (stockQuantityData.totalQuantity > 0) {
      return (
        <OptionsButton
          buttonName={INSTOCK_BUTTON_LABEL}
          buttonText={t('product.label.instock', 'In stock')}
          summaryPage={summaryPage}
        />
      );
    }

    if (translatedLeadTime) {
      return (
        <OptionsButton
          buttonName={LEADTIME_BUTTON_LABEL}
          buttonText={translatedLeadTime}
          summaryPage={summaryPage}
          leadTime={leadTimeData}
        />
      );
    }

    return (
      <OptionsButton
        buttonName={LEADTIME_BUTTON_LABEL}
        buttonText={t('product.label.unorderable', 'Not orderable')}
        summaryPage={summaryPage}
      />
    );
  }
);

export default StockStatus;
