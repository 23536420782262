import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

function Options({ children }: { children?: ReactNode }) {
  return <Container>{children}</Container>;
}

export default Options;
