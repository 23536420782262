import { NavigateFunction } from 'react-router-dom';
import { getParams, paramsObjectToNavigationString } from './function/navigationParams';

type Params = { navigate: NavigateFunction; isChina?: boolean };

export function deleteRoomIdFromParams({ navigate, isChina }: Params) {
  const params = getParams();
  if (params.roomId) delete params.roomId;
  const finalParams = paramsObjectToNavigationString(params, isChina);
  navigate(`/${finalParams}`);
}
