import { isRightToLeft } from '../../utils/function/functions';
import { PopUpPosition } from './PopUpType';

// const width = Math.max(
//   window.screen.width,
//   window.innerWidth,
//   document.documentElement.clientWidth
// );

// const height = Math.min(
//   window.screen.height,
//   window.innerHeight,
//   document.documentElement.clientHeight
// );

export const popUpPosition = (width: number, height: number): PopUpPosition => {
  return isRightToLeft()
    ? width < height
      ? { vertical: 'top', horizontal: 'right' }
      : { vertical: 'bottom', horizontal: 'right' }
    : width < height
      ? { vertical: 'top', horizontal: 'left' }
      : { vertical: 'bottom', horizontal: 'left' };
};
