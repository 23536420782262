import { ProductPageState, ThreekitState } from '..';
import { PayloadAction } from '@reduxjs/toolkit';

export const productPage2DReducers = {
  setToogle(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['toogle'][number]>
  ) {
    if (state.productPage.toogle[1] !== action.payload) {
      state.productPage.toogle[1] = action.payload;
    }
  },

  setToogle2(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['toogle'][number]>
  ) {
    if (state.productPage.toogle[2] !== action.payload) {
      state.productPage.toogle[2] = action.payload;
    }
  },

  setIsOpen(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['isOpen']>
  ) {
    if (state.productPage.isOpen !== action.payload) {
      state.productPage.isOpen = action.payload;
    }
  },

  setPreviousCamera(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['previousCamera']>
  ) {
    if (state.productPage.previousCamera !== action.payload) {
      state.productPage.previousCamera = action.payload;
    }
  },

  setLastAngle(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['lastAngle']>
  ) {
    if (state.productPage.lastAngle !== action.payload) {
      state.productPage.lastAngle = action.payload;
    }
  },

  setTotalEngraving(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['totalEngraving']>
  ) {
    if (state.productPage.totalEngraving !== action.payload) {
      state.productPage.totalEngraving = action.payload;
    }
  },

  setToggleSlider(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['toggleSlider']>
  ) {
    if (state.productPage.toggleSlider !== action.payload) {
      state.productPage.toggleSlider = action.payload;
    }
  },

  setPlayer3DImage(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['player3DImage']>
  ) {
    if (state.productPage.player3DImage !== action.payload) {
      state.productPage.player3DImage = action.payload;
    }
  },

  setIsFullScreen(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['isFullScreen']>
  ) {
    if (state.productPage.isFullScreen !== action.payload) {
      state.productPage.isFullScreen = action.payload;
    }
  },

  setIsRotatable(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['isRotatable']>
  ) {
    if (state.productPage.isRotatable !== action.payload) {
      state.productPage.isRotatable = action.payload;
    }
  },

  setHasPatch(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['hasPatch']>
  ) {
    if (state.productPage.hasPatch !== action.payload) {
      state.productPage.hasPatch = action.payload;
    }
  },

  setPlayer3DStep(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['player3DStep']>
  ) {
    if (state.productPage.player3DStep !== action.payload) {
      state.productPage.player3DStep = action.payload;
    }
  },
};
