import { webPurifyLanguageMappings } from '../../utils/function/mapping';
import { popUpTypes } from '../../components/PopUp/PopUpType';
import { showPopUp } from '../../components/PopUp/ShowPopUp';
import { getParams } from '../../utils/function/navigationParams';
import { DEFAULT_SERVER_URL } from '../constants';
import { sendEventToDynatrace } from '../dyntatraceUtils';
import { DisplayModes, displayModes } from '../../components/SharingModal/displayModes';

interface FetchLeadTimeParams {
  storeCode: string;
  sku: string | undefined;
  country: string;
  isMobile: boolean;
}

//NOT SURE - need to check how the data is coming in
interface LeadTimeResponse {
  distribution_leadtime?: {
    min: number;
    max: number;
  };
  min: number;
  max: number;
}

interface FetchStockQuantityParams {
  storeCode: string;
  sku: string;
  isMobile: boolean;
}

interface StockQuantityResponse {
  totalQuantity: number;
}

interface FetchPriceParams {
  storeCode: string | undefined;
  sku: string | undefined;
  country: string | undefined;
  isMobile: boolean;
}

interface PriceResponse {
  priceData: {
    price_value: number | null;
    currency: string;
  };
  price_value: number | null;
  currency: string;
}

interface GetProductIdParams {
  sku: string | undefined;
}

interface GetSavedConfigParams {
  recipeId: string;
  isMobile: boolean;
}

interface CheckTextPurityParams {
  text: string;
  lng: string;
  isMobile: boolean;
}

interface CheckTextResponse {
  valid: boolean;
}

function timeoutPromise(milliseconds: number): Promise<never> {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out'));
    }, milliseconds);
  });
}

const { sku, appName, token, timestamp } = getParams() || {};
const tokenjwt = localStorage.getItem('tokenjwt');

const location = window.location.host.includes('localhost');
const baseURL = location ? DEFAULT_SERVER_URL : `https://${window.location.host}`;

export const fetchConfigVariables = async (): Promise<any> => {
  try {
    const queryParams = new URLSearchParams({
      appName: String(appName),
      sku: String(sku),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();

    const response = await fetch(`${baseURL}/api/getConfigVariables?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_configVariables');

    return data || {};
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    return Promise.reject(errorMessage);
  }
};

export const fetchLeadTime = async ({
  storeCode,
  sku,
  country,
}: FetchLeadTimeParams): Promise<LeadTimeResponse> => {
  try {
    const queryParams = new URLSearchParams({
      storeCode: String(storeCode),
      sku: String(sku),
      country: String(country),
      appName: String(appName),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();
    const fetchPromise = fetch(`${baseURL}/api/getSkuAvailability?${queryParams}`);

    const response = await Promise.race([fetchPromise, timeoutPromise(20000)]);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_leadTime');

    return (
      data.distribution_leadtime || {
        distribution_leadtime: { min: 0, max: 0 },
      }
    );
  } catch (error) {
    let errorMessage: string;
    if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = 'An unknown error occurred';
    }
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.leadTimeError });
    return Promise.reject(errorMessage);
  }
};

export const fetchStockQuantity = async ({
  storeCode,
  sku,
  isMobile,
}: FetchStockQuantityParams): Promise<StockQuantityResponse> => {
  try {
    const queryParams = new URLSearchParams({
      storeCode: String(storeCode),
      sku: String(sku),
      appName: String(appName),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();
    const fetchPromise = fetch(`${baseURL}/api/getStockQuantity?${queryParams}`);

    const response = await Promise.race([fetchPromise, timeoutPromise(20000)]);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_fetchStockQuantity');

    return data || { totalQuantity: 0 };
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.quantityError });
    return Promise.reject(errorMessage);
  }
};

export const fetchPrice = async ({
  storeCode,
  sku,
  country,
  isMobile,
}: FetchPriceParams): Promise<PriceResponse> => {
  try {
    const countryProp: string = country !== undefined ? country : 'fr';
    const queryParams = new URLSearchParams({
      storeCode: String(storeCode),
      sku: String(sku),
      country: countryProp,
      appName: String(appName),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();
    const response = await fetch(`${baseURL}/api/getPrice?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_fetchPrice');
    return data.priceData || { price_value: null, currency: '' };
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.priceError });
    return Promise.reject(errorMessage);
  }
};

export const getCatalogProductBySku = async ({ sku }: GetProductIdParams) => {
  try {
    const queryParams = new URLSearchParams({
      sku: String(sku),
      appName: String(appName),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();

    const response = await fetch(`${baseURL}/api/getCatalogProductBySku?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_getCatalogProductBySku');

    return data || {};
  } catch (error) {
    console.error('Error:', error.message);
    return Promise.reject(error.message);
  }
};

type savedConfig = {
  attachments?: any;
};

export const getSavedConfig = async ({
  recipeId,
  isMobile,
}: GetSavedConfigParams): Promise<savedConfig> => {
  try {
    const queryParams = new URLSearchParams({
      recipeId: String(recipeId),
      sku: String(sku),
      appName: String(appName),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();

    const response = await fetch(`${baseURL}/api/getCustomConfiguration?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (!data || typeof data !== 'object') {
      throw new Error('Invalid JSON data in response');
    }
    sendEventToDynatrace('api_getSavedConfig');

    return data || {};
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.getConfigurationError });
    return Promise.reject(errorMessage);
  }
};

export const checkTextPurity = async ({
  text,
  lng,
  isMobile,
}: CheckTextPurityParams): Promise<CheckTextResponse> => {
  try {
    const lang = webPurifyLanguageMappings(lng);

    const queryParams = new URLSearchParams({
      text: String(text),
      lang: String(lang),
      sku: String(sku),
      appName: String(appName),
      token: String(token),
      tokenjwt: String(tokenjwt),
      timestamp: String(timestamp),
      mode: 'ALPHA'
    }).toString();

    const response = await fetch(`${baseURL}/api/checkText?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_web_purify');

    if (data?.found === '1') {
      return { valid: false };
    } else {
      return { valid: true };
    }
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.webPurifyError });
    return Promise.reject(errorMessage);
  }
};

export const fetchToken = async () => {
  try {
    const queryParams = new URLSearchParams({
      appName: String(appName),
      timestamp: String(timestamp),
      token: String(token),
      sku: String(sku),
      mode: 'ALPHA'
    }).toString();
    const response = await fetch(`${baseURL}/api/getToken?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    sendEventToDynatrace('api_fetchToken');
    return data || {};
  } catch (error) {
    console.error('Error:', error.message);
    return Promise.reject(error.message);
  }
};

interface FetchSocketJwtTokenParams { roomId: string | number, displayMode: DisplayModes }

export const fetchSocketJwtToken = async ({ roomId, displayMode }: FetchSocketJwtTokenParams) => {
  try {
    const queryParams = new URLSearchParams({
      roomId: String(roomId),
      appName: String(appName),
      timestamp: String(timestamp),
      token: String(token),
      sku: String(sku),
      mode: 'ALPHA',
      displayMode: String(displayMode)
    }).toString();
    const response = await fetch(`${baseURL}/api/getSocketJwtToken?${queryParams}`);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const tokenToSend = data?.token;
    sendEventToDynatrace('api_fetchSocketJWTToken');
    return tokenToSend || '';
  } catch (error) {
    console.error('Error:', error.message);
    return Promise.reject(error.message);
  }
};
