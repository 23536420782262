import { PayloadAction } from '@reduxjs/toolkit';
import { ProductPageState, ThreekitState } from '..';

export const productPage3DReducers = {
  setDisplaySize(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['displaySize']>
  ) {
    if (state.productPage.displaySize !== action.payload) {
      state.productPage.displaySize = action.payload;
    }
  },

  setPlaceHolderError(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['placeHolderError']>
  ) {
    if (state.productPage.placeHolderError !== action.payload) {
      state.productPage.placeHolderError = action.payload;
    }
  },

  setNewSize(state: ThreekitState, action: PayloadAction<ProductPageState['newSize']>) {
    if (state.productPage.newSize !== action.payload) {
      state.productPage.newSize = action.payload;
    }
  },

  setPage(state: ThreekitState, action: PayloadAction<ProductPageState['page']>) {
    if (state.productPage.page !== action.payload) {
      state.productPage.page = action.payload;
    }
  },

  setCharactRest(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['charactRest']>
  ) {
    if (state.productPage.charactRest !== action.payload) {
      state.productPage.charactRest = action.payload;
    }
  },

  setCurrentEngraving(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['currentEngraving']>
  ) {
    if (state.productPage.currentEngraving !== action.payload) {
      state.productPage.currentEngraving = action.payload;
    }
  },

  setPlayerFullScreen(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['playerFullScreen']>
  ) {
    if (state.productPage.playerFullScreen !== action.payload) {
      state.productPage.playerFullScreen = action.payload;
    }
  },

  setBaseUrlClient(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['baseUrlClient']>
  ) {
    if (state.productPage.baseUrlClient !== action.payload) {
      state.productPage.baseUrlClient = action.payload;
    }
  },

  setCurrentIndex(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['currentIndex']>
  ) {
    if (state.productPage.currentIndex !== action.payload) {
      state.productPage.currentIndex = action.payload;
    }
  },

  setDisplayAutofocus(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['displayAutofocus']>
  ) {
    if (state.productPage.displayAutofocus !== action.payload) {
      state.productPage.displayAutofocus = action.payload;
    }
  },

  setCurrentSlide(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['currentSlideActive']>
  ) {
    if (state.productPage.currentSlideActive !== action.payload) {
      state.productPage.currentSlideActive = action.payload;
    }
  },

  setSizeAutofocus(
    state: ThreekitState,
    action: PayloadAction<ProductPageState['sizeAutofocus']>
  ) {
    if (state.productPage.sizeAutofocus !== action.payload) {
      state.productPage.sizeAutofocus = action.payload;
    }
  },
};
