import { useCallback, useMemo } from 'react';
import socketInstance from '../../socket/socket';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomId, getServerSocket, getServerSocketPath, resetLiveSession, setIsFrozen, setIsSessionLive, setRoomId } from '../../store/liveSessionSlicer';
import { SocketActions } from '../../utils/function/apiFn';
import { deleteRoomIdFromParams } from '../../utils/deleteRoomIdFromParams';
import { useNavigate } from 'react-router-dom';
import { getIsChina } from '../../store/threekitSlicer';
import { LOCAL_STORAGE_ROOM_ID_KEY } from '../../utils/constants';

const useSocket = () => {
  const dispatch = useDispatch();
  const socket = socketInstance.socket;
  const roomId = useSelector(getRoomId);
  const navigate = useNavigate();
  const isChina = useSelector(getIsChina);
  const SERVER_SOCKET = useSelector(getServerSocket);
  const SERVER_SOCKET_PATH = useSelector(getServerSocketPath);
  const SERVER_SOCKET_URL = useMemo(() => {
    const httpPre = SERVER_SOCKET.includes('localhost') ? 'http' : 'https';
    return `${httpPre}://${SERVER_SOCKET}`;
  }, [SERVER_SOCKET]);

  const emit = useCallback(({ action, data }: { action: SocketActions; data: string }) => {
    socketInstance?.emit({ roomId, action, data });
  }, [roomId]);

  const connect = useCallback(
    async ({
      url,
      path = SERVER_SOCKET_PATH,
      query,
    }: {
      url?: string;
      path?: string;
      query: { role: string; roomId: string, hub: string };
    }) => {
      const isValid = await socketInstance.connectSocket({
        url: url || SERVER_SOCKET_URL,
        path,
        query,
      });

      dispatch(setIsSessionLive(true));
      return isValid;
    },
    [dispatch, SERVER_SOCKET_PATH, SERVER_SOCKET_URL]
  );

  const disconnect = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_ROOM_ID_KEY);
    dispatch(setRoomId(''));
    dispatch(setIsSessionLive(false));
    dispatch(setIsFrozen(false));
    emit({
      action: 'closeRoom',
      data: '',
    });
    socketInstance.disconnectSocket();
    dispatch(resetLiveSession(true));
    deleteRoomIdFromParams({ navigate, isChina })
  }, [dispatch, emit, isChina, navigate]);
  return { socket, connect, disconnect, emit };
};

export default useSocket;
