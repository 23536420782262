import { PayloadAction } from '@reduxjs/toolkit';
import { IGlobalSettingsState } from '..';
import { DisplayModes } from '../../../components/SharingModal/displayModes';

export const setGlobalSettings = (
  state: IGlobalSettingsState,
  action: PayloadAction<IGlobalSettingsState>
) => {
  return action.payload;
};
export interface IParams {
  token?: string;
  tokenjwt?: string;
  sku?: string;
  appName?: string;
  timestamp?: string;
  storeCode?: string;
  configId?: string;
  lng?: string;
  country?: string;
  productName?: string;
  recipeId?: string;
  price?: string;
  step?: number;
  roomId?: number;
  notistack?: boolean;
  displayMode?: DisplayModes
}

export const setGlobalSettingsParams = (
  state: IGlobalSettingsState,
  action: PayloadAction<IParams>
) => {
  state.params = action.payload;
  state.params.token = action.payload.token
    ? encodeURIComponent(action.payload.token)
    : '';
};

export const setGradient = (
  state: IGlobalSettingsState,
  action: PayloadAction<string>
) => {
  state.gradient = action.payload;
};

export const setStep = (state: IGlobalSettingsState, action: PayloadAction<number>) => {
  state.step = action.payload;
};
