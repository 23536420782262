import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  ContentWrapper,
  WrapperContainer,
  ConfigurationSection,
} from './SummaryPage.styles';
import { useThreekitInitStatus } from '../../utils/threekitHooks';
import TopSection from '../../components/TopSection/TopSection';
import { usePageTitle } from '../../hooks';
import ConfigurationSectionContent from '../components/ConfigurationSectionContent';
import { ButtonsSection } from './components/ButtonsSection';
import ProductImage from './components/ProductImage';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer/selectors';

const SummaryPage = ({ data }: any) => {
  usePageTitle({ page: 'summary' });
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName } = globalSettingsParams || {};
  const isLoaded = useThreekitInitStatus();
  const [attributesToDisplay, setAttributesToDisplay] = useState<any>();
  const [productImages, setProductImages] = useState({});

  useEffect(() => {
    if (data?.attachments) {
      setProductImages(data.attachments);
    }
    if (data?.metadata) {
      setAttributesToDisplay(Object.entries(data?.metadata?.readableConfiguration));
    }
  }, [data]);

  return (
    <>
      <Container>
        <ProductImage productImages={productImages} isLoading={isLoaded} />
        <ContentWrapper>
          <WrapperContainer>
            <TopSection summaryPage />
            <ConfigurationSection>
              <ConfigurationSectionContent attributesToDisplay={attributesToDisplay} />
            </ConfigurationSection>
            <ButtonsSection appName={appName} />
          </WrapperContainer>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default SummaryPage;
