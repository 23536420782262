import Form from '../../../components/Form/Form';
import { AboveFormContainer, ShareScreen } from '../Home.styles';
import StockStatus from '../../../components/Form/StockStatus/StockStatus';
import OptionsButton from '../../../components/Form/OptionsButton';
import {
  SHARE_SCREEN_BUTTON_LABEL_MOBILE,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING,
  ZOOM_BUTTON_LABEL,
} from '../../../utils/constants';
import { getIsFullScreen, setIsFullScreen } from '../../../store/threekitSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { PhotosDisplayer } from '../../../components';
import Options from './Options';
import { ConfiguratorProps } from './FormProps';
import { getHideRemoteViewer, getIsSessionLive } from '../../../store/liveSessionSlicer';

export const MobileForm = ({ handleShareScreenClick }: ConfiguratorProps) => {
  const isFullScreen = useSelector(getIsFullScreen);
  const isSessionLive = useSelector(getIsSessionLive);
  const hideRemoteViewer = useSelector(getHideRemoteViewer);
  const dispatch = useDispatch();
  const handleFullScreen = () => {
    dispatch(setIsFullScreen(!isFullScreen));
  };

  return (
    <>
      <AboveFormContainer>
        <StockStatus />
        <PhotosDisplayer />
        <Options>
          {!hideRemoteViewer && (<ShareScreen onClick={handleShareScreenClick}>
            <OptionsButton
              buttonName={
                isSessionLive
                  ? SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING
                  : SHARE_SCREEN_BUTTON_LABEL_MOBILE
              }
            />
          </ShareScreen>)}
          <OptionsButton
            buttonName={ZOOM_BUTTON_LABEL}
            fnButton={handleFullScreen}
            isFullScreen={isFullScreen}
          />
        </Options>
      </AboveFormContainer>
      <Form />
    </>
  );
};

export default MobileForm;
