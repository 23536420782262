import { COLORS } from '../../../components/Colors';
import styled, { css, keyframes } from 'styled-components';

export const TvCode = styled.div`
  position: relative;
`;

export const InvalidCodeValidationMessage = styled.p`
  color: ${COLORS.scarlet};
  margin-left: 20px;
  font-family: LouisVuitton_Regular;
`;

const spinForward = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
`;

interface ClearProps {
  roomId: string;
}

export const Clear = styled.img.withConfig({
  shouldForwardProp: (prop) => !['roomId'].includes(prop),
})<ClearProps>`
  position: absolute;
  top: 23px;
  right: 40px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: ${(props) => (props.roomId ? 'auto' : 'none')};
  opacity: ${(props) => (props.roomId ? 1 : 0)};
  &.spinningForward {
    animation: ${spinForward} 0.1s linear forwards;
  }
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

export const FakePlaceHolder = styled.div.withConfig({
  shouldForwardProp: (prop) => !['error'].includes(prop),
})<InputProps>`
  position: absolute;
  top: 22px;
  left: 50px;
  font-family: LouisVuitton_Regular;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.4px;
  color: ${(props) => (props.error ? COLORS.scarlet : COLORS.black)};
`;

interface ClearProps {
  roomId: string;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

export const Input = styled.input.withConfig({
  shouldForwardProp: (prop) => !['error'].includes(prop),
})<InputProps>`
  width: calc(100% - 40px);
  outline: none;
  font-family: LouisVuitton_Medium;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  margin: 0 20px;
  height: 65px;
  border-radius: 100px;
  /* border: 1px solid black; */
  border: 1px solid ${(props) => (props.error ? COLORS.scarlet : COLORS.black)};
  color: ${(props) => (props.error ? COLORS.scarlet : COLORS.black)};
  display: flex;
  align-items: center;
  text-align: right;
  margin-bottom: 10px;
  padding: 0 50px 0;
`;

export const QRCodeContainer = styled.div`
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-direction: column;
`;

export const instructionsStyle = css`
  color: ${COLORS.mediumGray2};
  font-family: LouisVuitton_Regular;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 14px;
`;

export const InstructionsText = styled.div`
  ${instructionsStyle}
`;
