export const LV_LOADER = require('./lvLoader.gif');
export const LEFT_ARROW = require('./leftArrow.png');
export const RIGHT_ARROW = require('./rightArrow.png');
export const CART_ICON = require('./cartIcon.png');
export const CART_ICON_OOB = require('./cartIconOOB.png');
export const EDIT_ICON = require('./editIcon.png');
export const RESET_ICON = require('./resetIcon.png');
export const SHARE_ICON = require('./shareScreenIcon.png');
export const STOP_SHARING_ICON = require('./stopSharing.png');
export const COPIED_ICON = require('./copied.png');
export const SHARING_ICON = require('./sharing.png');
export const SHARE_PRODUCT_ICON = require('./shareIcon.png');
export const STAR_ICON = require('./starIcon.png');
export const PLUS_ICON = require('./plusIcon.png');
export const MINUS_ICON = require('./minusIcon.png');
export const INSTOCK_ICON = require('./inStockIcon.png');
export const COPY_ICON = require('./copyIcon.png');
export const ICON_360 = require('./icon360.png');
export const INFO = require('./info.png');
export const DIALOG = require('./dialog.png');
export const DESCRIPTION_ICON = require('./descriptionIcon.png');
export const BAR_LINE_ICON = require('./barLineIcon.png');
export const CHECKED_RADIO_BUTTON_ICON = require('./checkedRadioButtonIcon.png');
export const UNCHECKED_RADIO_BUTTON_ICON = require('./uncheckedRadioButtonIcon.png');
export const PICTURE_PLACEHOLDER = require('./picturePlaceholder.png');
export const ADD_OPTIONAL_SELECTED = require('./addOptionalSelected.png');
export const ADD_OPTIONAL_UNSELECTED = require('./addOptionalUnselected.png');
export const DONT_ADD_OPTIONAL_SELECTED = require('./dontAddOptionalSelected.png');
export const DONT_ADD_OPTIONAL_UNSELECTED = require('./dontAddOptionalUnselected.png');
export const WARN_ICON = require('./warnIcon.png');
export const WARN_BLACK_ICON = require('./warnIconBlack.png');
export const V_ICON = require('./vIcon.png');
export const LEFT_ARROW_SLIDER: string = require('./leftArrowSlider.png');
export const RIGHT_ARROW_SLIDER: string = require('./rightArrowSlider.png');
export const ZOOM_ICON = require('./zoom.png');
export const UNZOOM_ICON = require('./unzoom.png');
export const QR = require('./qrCode.png');
export const INFO_PRESS_ICON = require('./press.png');
export const CLOSE_ICON = require('./closeIconX.png');
export const LouisVuittonDemi = require('./LouisVuitton-Demi.ttf');
export const LouisVuittonMedium = require('./LouisVuitton-Medium.ttf');
export const LouisVuittonRegular = require('./LouisVuitton-Regular.ttf');
