import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  TCurrentGroup,
  findModelPresentationOpen,
  findPlayerMode,
  updateForm,
} from '../../utils/function/attributeFn';
import { useAttribute, useValidAttributes } from '../../utils/threekitHooks';
import {
  getCurrentMode,
  getCurrentModelView,
  getCurrentModelViewInfos,
  getCurrentModelViewKey,
  getReadableConfigurationWithAttributeType,
  getRotationUpdate,
  getViewUpdate,
  setConfiguration,
  setCurrentMode,
  setCurrentModelView,
  setFormValidAttributes,
  setIsRotatable,
} from '../../store/threekitSlicer';
import {
  setFormRequiredAndChecked,
  setInitialFormTextInputFields,
} from '../../store/validationSlicer';
import { RootState, stateRoot } from 'store';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

function useUpdateFormAndPlayer({ form, currentStep }: any) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [playerMode, setPlayerMode] = useState<string>();
  const isViewUpdate = useSelector(getViewUpdate);
  const isRotationUpdate = useSelector(getRotationUpdate);
  const currentModelPresentation = useSelector(getCurrentModelView);
  const currentModelViewInfos = useSelector(getCurrentModelViewInfos);
  const currentModelViewKey = useSelector(getCurrentModelViewKey);
  const allAttributes = useSelector(
    (state: stateRoot) => state.threekit.configuration.attributes
  );
  const readableConfiguration = useSelector(getReadableConfigurationWithAttributeType());

  const { getIsAttributeValid } = useValidAttributes();
  const currentModeData = useSelector(getCurrentMode);
  const [, setModelPresentation] = useAttribute('Model Presentation');

  useEffect(() => {
    if (!form || isRotationUpdate) return;

    const formValidAttributes: any = updateForm(form, getIsAttributeValid);
    dispatch(setFormValidAttributes(formValidAttributes));
    const currentGroup: any | TCurrentGroup = Object.values(formValidAttributes)?.[
      currentStep
    ] || { 'MODEL Taille': {} };
    const currentModelPresentationOpen = findModelPresentationOpen(currentGroup);
    const newModelPresentation = currentModelPresentationOpen ? 'Open' : 'Closed';
    if (
      newModelPresentation !== currentModelPresentation &&
      playerMode === '2D' &&
      !isViewUpdate
    ) {
      setModelPresentation(newModelPresentation);
      dispatch(setCurrentModelView(newModelPresentation));
    }
    const currentMode = currentGroup ? findPlayerMode(currentGroup) : '2D';

    if (isViewUpdate) {
      dispatch(setCurrentMode(currentModelViewInfos?.PlayerType));
    }
    else {
      dispatch(setCurrentMode(currentMode));
    }

    const currentView =
      window?.dataDrivenConfiguratorExtension
        ?.getStatus()
        ?.carousel?.items?.[currentModelViewKey];
    dispatch(setIsRotatable(currentView?.isRotatable));

    if (allAttributes && readableConfiguration) {
      const allAttrArray = Object.entries(allAttributes)?.map(
        (el) => Object.values(el)[1]
      );
      const allAttrArrayRequired = allAttrArray?.filter(
        (el: any) => el?.metadata?.isRequired === 'true'
      );
      const readableConfigurationNames = Object.values(readableConfiguration).map(
        (el: any) => el?.name
      );
      const currentRequiredAttributesState: Record<string, any> = {};
      allAttrArrayRequired?.forEach((el: any) => {
        const name = el?.name;
        const value = el?.value;
        if (readableConfigurationNames.includes(name)) {
          currentRequiredAttributesState[name] = value;
        }
      });
      dispatch(setFormRequiredAndChecked(currentRequiredAttributesState));
      // update text input fields
      const allAttrArrayTextInput = allAttrArray?.filter(
        (el: any) => el?.metadata?.frontComponent === 'TextInput'
      );
      const currentTextInputAttributesState: Record<string, any> = {};
      allAttrArrayTextInput?.forEach((el: any) => {
        const name = el?.name;
        const value = el?.value;
        if (readableConfigurationNames.includes(name)) {
          currentTextInputAttributesState[name] = value;
        }
      });
      dispatch(setInitialFormTextInputFields(currentTextInputAttributesState));
    }
  }, [form]);

  useEffect(() => {
    const currentView =
      window?.dataDrivenConfiguratorExtension
        ?.getStatus()
        ?.carousel?.items?.[currentModelViewKey];
    const viewModelPresentation = currentView?.configuration?.['Model Presentation'] || 'Closed';
    const viewRotate = currentView?.configuration?.['Rotate Model'] || 0;
    dispatch(
      setConfiguration({
        'Model Presentation': viewModelPresentation,
        'Rotate Model': viewRotate,
      })
    );
    dispatch(setIsRotatable(currentView?.isRotatable));
  }, [currentModelViewKey]);

  useEffect(() => {
    try {
      if (!form) return;
      const formValidAttributes: any = updateForm(form, getIsAttributeValid);
      dispatch(setFormValidAttributes(formValidAttributes));
      const currentGroup: any | TCurrentGroup = Object.values(formValidAttributes)?.[
        currentStep
      ] || { 'MODEL Taille': {} };
      if (currentStep === -1 || !formValidAttributes) return;

      const currentMode = currentGroup ? findPlayerMode(currentGroup) : '2D';
      setPlayerMode(currentMode);
      dispatch(setCurrentMode(currentMode));

      const currentModelPresentationOpen = findModelPresentationOpen(currentGroup);
      const newModelPresentation = currentModelPresentationOpen ? 'Open' : 'Closed';
      if (newModelPresentation !== currentModelPresentation && playerMode === '2D') {
        setModelPresentation(newModelPresentation);
        dispatch(setCurrentModelView(newModelPresentation));
      }
      const isRotatable =
        window?.dataDrivenConfiguratorExtension
          ?.getStatus()
          ?.carousel?.items?.[currentModelViewKey]?.isRotatable;
      dispatch(setIsRotatable(isRotatable));
    } catch (e) {
      console.log(e);
    }
  }, [currentStep, currentModelViewKey]);

  return { playerMode };
}

export default useUpdateFormAndPlayer;
