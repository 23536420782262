import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { CLOSE_ICON } from '../../../assets';
import { ButtonContainer } from '../../../components/Form/OptionsButton/OptionsButton.style';

interface ModalContentProps {
  isOpen: boolean;
  opacity: 0 | 1;
  overlappingModal: boolean;
}

interface ModalWrapperProps {
  isOpen: boolean;
  overlappingModal: boolean;
}

const ModalWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['overlappingModal', 'isOpen'].includes(prop),
})<ModalWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: ${({ isOpen }) => (isOpen ? '1' : '-1')};
  ${({ overlappingModal }) => css`
    background: ${overlappingModal ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  `}

  ${({ isOpen }) => css`
    opacity: ${isOpen ? 1 : 0};
    top: ${isOpen ? 0 : '-100%'};
  `}
`;

const ModalContent = styled.div.withConfig({
  shouldForwardProp: (prop) => !['overlappingModal', 'isOpen', 'opacity'].includes(prop),
})<ModalContentProps>`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 85%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  ${({ opacity, overlappingModal }) => css`
    opacity: ${!overlappingModal && opacity === 0 ? '0 !important' : 1};
  `}
`;

export const HeaderCloseWrapper = styled.div`
  display: flex;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: -webkit-fill-available;
`;

const Title = styled.div`
  flex: 1;
  text-align: center;
  font-family: LouisVuitton_Medium;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  height: fit-content;
  cursor: pointer;
  > img {
    width: 25px;
  }
`;

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  overlappingModal?: boolean;
  opacity: 0 | 1;
  closeButton?: boolean;
  yesButton?: boolean;
  noButton?: boolean;
  onClickYes?: () => void;
  onClickNo?: () => void;
  id?: string;
}

const RelativeModal = ({
  isOpen,
  onClose,
  title,
  children,
  opacity = 1,
  overlappingModal = false,
  closeButton = true,
  yesButton = false,
  noButton = false,
  id = '',
  onClickYes = () => {},
  onClickNo = () => {},
}: ModalProps) => {
  const handleWrapperClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <ModalWrapper id={id} onClick={handleWrapperClick} isOpen={isOpen} overlappingModal={overlappingModal}>
      <ModalContent isOpen={isOpen} opacity={opacity} overlappingModal={overlappingModal}>
        <HeaderCloseWrapper>
          {closeButton && (
            <CloseButton onClick={onClose}>
              <img alt="close-modal" src={CLOSE_ICON} />
            </CloseButton>
          )}
          <ModalHeader>{title && <Title>{title}</Title>}</ModalHeader>
        </HeaderCloseWrapper>
        {children}
        {(yesButton || noButton) && (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {noButton && (
              <ButtonContainer buttonName={'no'} onClick={onClickNo}>
                No
              </ButtonContainer>
            )}
            {yesButton && (
              <ButtonContainer buttonName={'yes'} onClick={onClickYes}>
                Yes
              </ButtonContainer>
            )}
          </div>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

export default RelativeModal;
