import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormValidAttributes } from '../../store/threekitSlicer';
import { getStep } from '../../store/globalSettingsSlicer';

export const useAttributeTitle = () => {
  const currentStep = useSelector(getStep);

  const formValidAttributes = useSelector(getFormValidAttributes);

  const title = useMemo(
    (): string => Object.keys(formValidAttributes)[currentStep],
    [formValidAttributes, currentStep]
  );
  const allTitles = useMemo(
    (): string[] => Object.keys(formValidAttributes),
    [formValidAttributes]
  );

  return {title, allTitles}
};

export default useAttributeTitle;
