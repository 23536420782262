import { setGradient } from '../../../store/globalSettingsSlicer';

type GradientProps = {
  canvasId: string;
  dispatch: any;
  count?: number;
  currentGradient: string;
  rotationUpdate?: boolean;
};

export function setGradientBackgroundToCanvas({
  canvasId,
  dispatch,
  count = 1500,
  currentGradient,
  rotationUpdate = false
}: GradientProps) {
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement | null;

  if (!canvas) {
    console.error('Canvas element not found');
    return [];
  }

  if (currentGradient && !rotationUpdate) {
    dispatch(setGradient(currentGradient));
    return;
  }

  if (canvas.width === 0 || canvas.height === 0) {
    if (count > 1) {
      setTimeout(
        () => setGradientBackgroundToCanvas({ canvasId, dispatch, count: count - 1, currentGradient, rotationUpdate }),
        50
      );
    } else {
      console.error('Canvas width and height are zero, count limit reached.');
    }
    return [];
  }

  let ctx;
  if (canvas.getContext('webgl') || canvas.getContext('webgl2')) {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;
    const tempCtx = tempCanvas.getContext('2d', { willReadFrequently: true });
    tempCtx?.drawImage(canvas, 0, 0);
    ctx = tempCtx;
  } else {
    ctx = canvas.getContext('2d', { willReadFrequently: true });
  }

  if (!ctx) {
    console.error('Unable to get context from canvas');
    return [];
  }

  const width = canvas.width;
  const height = canvas.height;
  const centerX = width / 2;
  const centerY = height / 2;
  const minSide = Math.min(width, height);
  const maxSide = Math.max(width, height);
  const step = minSide * 0.01;
  const colors = [];
  const coords = [];

  for (let angle = -90; angle < 270; angle += 10) {
    const radians = (angle * Math.PI) / 180;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    let found = false;

    for (let radius = maxSide / 2; radius > 0 && !found; radius -= step) {
      const x = centerX + radius * cos;
      const y = centerY + radius * sin;

      try {
        const pixelData = ctx.getImageData(x, y, 1, 1).data;
        if (pixelData[3] === 255) {
          const color = `#${(
            (1 << 24) +
            (pixelData[0] << 16) +
            (pixelData[1] << 8) +
            pixelData[2]
          )
            .toString(16)
            .slice(1)}`;
          colors.push(color);
          coords.push({ x, y });
          found = true;
        }
      } catch (error) {
        console.error('Error reading pixel data:', error);
        continue;
      }
    }
  }

  let gradientCSS = 'conic-gradient(';
  const colorStep = 360 / colors.length;
  [...colors, colors[0]].forEach((color, index) => {
    gradientCSS += `${color} ${index * colorStep}deg, `;
  });
  gradientCSS = gradientCSS.slice(0, -2);
  gradientCSS += ')';

  canvas.style.backgroundImage = gradientCSS;

  if (!rotationUpdate)
    dispatch(setGradient(gradientCSS));

  return colors;
}
