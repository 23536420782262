import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ElsePhoto, ElsePhotosWrapper, ElsePhotoWrapper } from './photosDisplayer.styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentMode,
  getCurrentModelView,
  getForm,
  getLastAngle,
  setViewUpdate,
  setLastAngle,
  setConfiguration,
  setCurrentModelViewKey,
  setCurrentModelViewInfos,
  setIsRotatable,

} from '../../store/threekitSlicer';
import { getGradient, getStep } from '../../store/globalSettingsSlicer';
import { Arrow } from '../../components/ArrowsComponent/Arrows.styles';
import { LEFT_ARROW_SLIDER, RIGHT_ARROW_SLIDER } from '../../assets';
import { useValidAttributes, useWindowSize } from '../../utils/threekitHooks';
import { getFormTextInputFields } from '../../store/validationSlicer';
import { updateForm } from '../../utils/function/attributeFn';
import { isTextAttribute, hasPatch, isRightToLeft } from '../../utils/function/functions';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { setGradientBackgroundToCanvas } from '../PlayersThreekit/Player2D/GradientBackground';
import { TK_PLAYER_DIV_ID_2D } from '../../utils/constants';
import { ViewDot, ViewDotsContainer } from './viewsViewer.styles';

interface Photos {
  [key: string]: string;
}

interface PhotosDisplayerProps {
  photos: Photos;
  mainKey?: string;
  setPhotoLoaded: (loaded: boolean) => void;
  showPlayer?: boolean;
  playerKey?: string;
  clientPage: boolean;
}

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const PhotosDisplayer: React.FC<Partial<PhotosDisplayerProps>> = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { isMobile } = useWindowSize();
  const [mainPhotoKey, setMainPhotoKey] = useState<number>(0);
  const currentMode = useSelector(getCurrentMode);
  const lastAngle = useSelector(getLastAngle);
  const currentViewPresentation = useSelector(getCurrentModelView);
  const currentStep = useSelector(getStep);
  const { getIsAttributeValid } = useValidAttributes();
  const form = useSelector(getForm);
  const [modelViewsStable, setModelViewsStable] = useState([]);
  const formTextInputFields = useSelector(getFormTextInputFields);
  const currentModelPresentation = useSelector(getCurrentModelView);
  const [isViewClick, setIsViewClick] = useState(false);
  const [startMousePosition, setStartMousePosition] = useState([0, 0]);
  const [startTouchTime, setStartTouchTime] = useState<number>(0);
  const isRtl = useMemo(() => isRightToLeft(), []);
  const gradient = useSelector(getGradient)
  
 

  const currentGroup: Record<string, string> | undefined = useMemo(() => {
    if (form) {
      const formValidAttributes = updateForm(form, getIsAttributeValid);
      if (currentStep !== -1) {
        return Object.values(formValidAttributes)?.[currentStep];
      }
    }
  }, [formTextInputFields, currentStep]);

  const carousel = useMemo(() => {
    return window?.dataDrivenConfiguratorExtension?.getStatus()?.carousel;
  }, [window?.dataDrivenConfiguratorExtension?.getStatus()?.carousel?.items]);

  const modelViews = useMemo(() => {
    if (currentGroup && isTextAttribute(currentGroup)) {
      if (modelViewsStable?.length === 0) {
        return carousel?.items;
      } else if (modelViewsStable?.length > 0 && hasPatch()) {
        return modelViewsStable;
      }
    }
    return carousel?.items;
  }, [carousel, currentGroup]);

  const photoWidth = useMemo(() => {
    return modelViews ? 100 / modelViews.length + '%' : '20%';
  }, [modelViews]);

  // useEffect(() => {
  //   if (currentMode === '2D') {
  //     const viewIndex = modelViews?.findIndex(
  //       (element: any) =>
  //         element.configuration['Rotate Model'] === lastAngle &&
  //         element.configuration['Model Presentation'] === currentViewPresentation
  //     );
  //     //here
  //     if (viewIndex !== undefined && viewIndex !== -1 ) {
  //       if (modelViews[viewIndex].configuration) setMainPhotoKey(viewIndex);
  //     }
  //   }
  // }, [lastAngle]);

  useEffect(() => {
    if (modelViewsStable !== carousel?.items) {
      setModelViewsStable(carousel?.items);
    }
  }, [currentGroup]);

  
  const setNewView = useCallback(
    async (view: Record<string, any>, key: number) => {      
      setGradientBackgroundToCanvas({ canvasId: 'canvas', dispatch, currentGradient: gradient });
      
      if (view && 0 <= key && key <= modelViews.length) {
        dispatch(setCurrentModelViewKey(key));
        setIsViewClick(true);
        const viewModelPresentation = view?.configuration?.['Model Presentation'] || 'Closed';
        const viewRotate = view?.configuration?.['Rotate Model'] || 0;
        dispatch(
          setConfiguration({
            'Model Presentation': viewModelPresentation,
            'Rotate Model': viewRotate,
          })
        );
        dispatch(setIsRotatable(view?.isRotatable));
        dispatch(setLastAngle(viewRotate));
        setMainPhotoKey(key);
        dispatch(setCurrentModelViewInfos(modelViewsStable[key]));

        dispatch(setViewUpdate(true));
        setTimeout(() => {
          setIsViewClick(false);
          dispatch(setViewUpdate(false));
        }, 50);
      }
    },
    [modelViews, dispatch]
  );

  useEffect(() => {
    let index = -1;
    if (currentMode !== '3D') {
      index = modelViews?.findIndex(
        (element: Record<string, any>) =>
          element.PlayerType === currentMode &&
          currentModelPresentation === element.configuration['Model Presentation']
      );
    } else {
      index = modelViews.length - 1;
    }
    if (index !== undefined && index !== -1 && !isViewClick) {
      dispatch(setCurrentModelViewKey(index));
      setMainPhotoKey(index);
      setTimeout(() => {
        setMainPhotoKey(index);
      }, 500);
    }
  }, [currentMode, currentModelPresentation]);

  const nextIndex = useMemo(() => mainPhotoKey + 1, [mainPhotoKey]);
  const previousIndex = useMemo(() => mainPhotoKey - 1, [mainPhotoKey]);

  const nextView = useCallback(() => {
    if (nextIndex >= modelViews?.length) return;
    setNewView(modelViews?.[nextIndex], nextIndex);
  }, [modelViews, nextIndex, setNewView]);

  const previousView = useCallback(() => {
    if (previousIndex < 0) return;
    setNewView(modelViews?.[previousIndex], previousIndex);
  }, [modelViews, previousIndex, setNewView]);

  const handleTouchStart = useCallback(
    (e: any) => {
      const touch = e.touches[0];
      setStartMousePosition([touch.clientX, touch.clientY]);
      setStartTouchTime(new Date().getTime());
    },
    [setStartMousePosition, setStartTouchTime]
  );

  const handleTouchEnd = useCallback(
    (e: any) => {
      const touch = e.changedTouches[0];
      const endX = touch.clientX;
      const endTime = new Date().getTime();

      const distance = Math.abs(endX - startMousePosition[0]);
      const timeElapsed = endTime - startTouchTime;

      const swipeSpeed = distance / timeElapsed;

      const zoneSize = isMobile ? window.screen.width : window.screen.width / 2;

      if (
        (Math.abs(startMousePosition[0] - endX) < 180 ||
          (startMousePosition[0] > zoneSize * 0.1 &&
            startMousePosition[0] < zoneSize * 0.9)) &&
        (swipeSpeed < 0.4 ||
          (startMousePosition[0] > zoneSize * 0.1 &&
            startMousePosition[0] < zoneSize * 0.9))
      )
        return;

      if (startMousePosition[0] > endX) {
        nextView();
      } else if (startMousePosition[0] < endX) {
        previousView();
      }
    },
    [startMousePosition, startTouchTime, nextView, previousView]
  );

  useEffect(() => {
    if (isMobile) {
      const playerEl = document.getElementById(TK_PLAYER_DIV_ID_2D);
      if (!playerEl) return;
      playerEl.addEventListener('touchstart', handleTouchStart, false);
      playerEl.addEventListener('touchend', handleTouchEnd, false);
      return () => {
        playerEl.removeEventListener('touchstart', handleTouchStart, false);
        playerEl.removeEventListener('touchend', handleTouchEnd, false);
      };
    }
  }, [handleTouchStart, handleTouchEnd, isMobile]);

  return (
    <>
      {isMobile ? (
        <ViewDotsContainer>
          {modelViews?.map((view: Record<string, any>, key: number) =>
            view.PlayerType === '2D' ? (
              <ViewDot
                key={key}
                isActive={key === mainPhotoKey && currentMode === '2D'}
                onClick={() => setNewView(view, key)}
              />
            ) : null
          )}
          {modelViews && modelViews[modelViews.length - 1].PlayerType === '3D' && (
            <ViewDot
              key={modelViews.length - 1}
              isActive={currentMode === '3D'}
              onClick={() =>
                setNewView(modelViews[modelViews.length - 1], modelViews.length - 1)
              }
            />
          )}
        </ViewDotsContainer>
      ) : (
        <ElsePhotosWrapper>
          {modelViews?.length > 0 && (
            <>
              <Arrow id='left arrow'
                image={isRtl ? RIGHT_ARROW_SLIDER : LEFT_ARROW_SLIDER}
                onClick={() => {                  
                  const newIndex = mainPhotoKey === 0? modelViews.length -1 : mainPhotoKey -1
                  setNewView(modelViews[newIndex], newIndex)}
                }
                position='left'
                />
              <Arrow
                image={isRtl ? LEFT_ARROW_SLIDER : RIGHT_ARROW_SLIDER}
                onClick={() => {
                  const newIndex = mainPhotoKey+1 === modelViews.length? 0 : mainPhotoKey + 1
                  setNewView(modelViews[newIndex], newIndex)}
                }
                position='right'
              />
            </>
          )}
          {modelViews?.map(
            (item: Record<string, any>, key: number) =>
              item.PlayerType === '2D' && (
                <ElsePhotoWrapper
                  key={key}
                  onClick={() => setNewView(item, key)}
                  selected={key === mainPhotoKey && currentMode === '2D'}
                  width={photoWidth}
                >
                  <ElsePhoto imageToDisplay={item?.thumbnailUrl} />
                </ElsePhotoWrapper>
              )
          )}
          {modelViews && modelViews[modelViews.length - 1].PlayerType === '3D' && (
            <ElsePhotoWrapper
              onClick={() =>
                setNewView(modelViews[modelViews.length - 1], modelViews.length - 1)
              }
              selected={currentMode === '3D'}
              width={photoWidth}
            >
              <ElsePhoto imageToDisplay={modelViews[modelViews.length - 1]?.thumbnailUrl} />
            </ElsePhotoWrapper>
          )}
        </ElsePhotosWrapper>
      )}
    </>
  );
};

export default PhotosDisplayer;
