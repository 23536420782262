import { getCurrentMode, getCurrentModelView } from './../../store/threekitSlicer/selectors/currentMode';
import { Dispatch, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  emptyRotateModelUpdates,
  getRoomId,
  getRotationUpdates,
  setIsExpireModalShowing,
  setIsFrozen,
  setRoomClosedDueToInactivity,
  setClosingDate,
} from '../../store/liveSessionSlicer';
import { isEmptyObj } from '../../utils/function/functions';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer';
import { getParams } from '../../utils/function/navigationParams';

import {
  getAllAttributesForSetConfiguration,
  getIsRotatable,
  getPage,
  getProduct,
  getProductId,
} from '../../store/threekitSlicer';
import { useSocket } from '..';
import { SocketActions } from '../../utils/function/apiFn';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { sendEventToDynatrace } from '../../utils/dyntatraceUtils';
import {
  LOCAL_STORAGE_ROOM_ID_KEY,
  SESSION_STORAGE_PRODUCT_ID_KEY,
} from '../../utils/constants';
import useSavedConfiguration from '../useSavedConfiguration';
import useParams from '../useParams';


// Send player rotation events
const emitUpdateRotateModel = ({
  rotateModelUpdatesRef,
  socket,
  dispatch,
  emit,
}: {
  rotateModelUpdatesRef: React.MutableRefObject<any[] | undefined>;
  socket: any;
  dispatch: Dispatch<any>;
  emit: ({ action, data }: { action: SocketActions; data: string; }) => void;
}) => {
  const rotateModelUpdates = rotateModelUpdatesRef?.current;
  if (!rotateModelUpdates) return;
  if (socket && rotateModelUpdates.length > 0) {
    emit({
      action: 'updateProductData',
      data: JSON.stringify(rotateModelUpdates),
    });
    dispatch(emptyRotateModelUpdates(true));
  }
};

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

function useSocketEvents({ handleClose }: { handleClose: () => void; }) {
  const ROTATION_UPDATE_INTERVAL = 500;
  const FREEZE_TIMEOUT = 5000;

  const dispatch = useDispatch<AppThunkDispatch>();
  const { sku: paramSku } = getParams() || {};
  const { config } = useParams();
  const rotationUpdateInterval = useRef<NodeJS.Timeout | undefined>();
  const currentMode = useSelector(getCurrentMode);
  const currentModelView = useSelector(getCurrentModelView);
  const page = useSelector(getPage);
  const rotateModelUpdates = useSelector(getRotationUpdates);
  const roomId = useSelector(getRoomId);
  const { socket, disconnect: disconnectSocket, emit } = useSocket();
  const productInfos = useSelector(getProduct);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { configId, sku } = globalSettingsParams || { configId: '', sku: '' };
  const productId = useSelector(getProductId);
  let freezeTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const rotateModelUpdatesRef = useRef(rotateModelUpdates);
  const { savedConfigurationData } = useSavedConfiguration(configId!);
  const isRotatable = useSelector(getIsRotatable);

  useEffect(() => {
    rotateModelUpdatesRef.current = rotateModelUpdates;
  }, [rotateModelUpdates]);

  // Change the page home || summary
  useEffect(() => {
    if (socket) {
      emit({
        action: 'changePage',
        data: page,
      });
    }
  }, [page, socket, emit]);

  // Join room, init product infos, and listen for room closed and freeze seller events
  useEffect(() => {
    const initEmitToClient = () => {
      const currentConfiguration = Object.entries(
        getAllAttributesForSetConfiguration()
      ).map(([key, value]: any[]) => {
        return { [key]: value?.value };
      }) as any;

      if (productInfos && !isEmptyObj(productInfos) && currentConfiguration.length && config && !isEmptyObj(config)) {
        const newProductInfos = {
          ...productInfos,
          initialSku: paramSku || sku,
        };

        emit({
          action: 'configVariables',
          data: JSON.stringify(config),
        });

        emit({
          action: 'initProductInfos',
          data: JSON.stringify({ productInfos: newProductInfos, initialConfiguration: currentConfiguration }),
        });
      }

      emit({
        action: 'updateProductData',
        data: JSON.stringify(currentConfiguration),
      });
    };

    if (socket) {
      socket.emit('checkIsRoomValid', { roomId }, (isValid: boolean) => {
        console.log('Room ID validity:', isValid);
        if (isValid) {
          socket.emit('joinRoom', { roomId });
          localStorage.setItem(LOCAL_STORAGE_ROOM_ID_KEY, roomId);
          socket.on('clientJoined', initEmitToClient);
          const sessionStorageProductId = sessionStorage.getItem(
            SESSION_STORAGE_PRODUCT_ID_KEY
          );
          if (sessionStorageProductId !== productId) {
            emit({
              action: 'reload',
              data: '',
            });
            sessionStorage.setItem(SESSION_STORAGE_PRODUCT_ID_KEY, productId);
          } else {
            dispatch(setRoomClosedDueToInactivity(false));
            initEmitToClient();
          }

          socket.on('roomClosing', ({ closingTime }: { closingTime: Date; }) => {
            console.log('room closing warning');
            const closingDate = new Date(closingTime);

            dispatch(setIsExpireModalShowing(true));
            dispatch(setClosingDate(closingDate));
          });

          socket.on('keepRoomOpen', () => {
            dispatch(setIsExpireModalShowing(false));
            dispatch(setClosingDate(undefined));
          });

          socket.on('roomClosed', () => {
            dispatch(setRoomClosedDueToInactivity(true));
            disconnectSocket();
          });

          socket.on('freezeSeller', (data: { roomId: number; }) => {
            if (roomId !== `${data.roomId}`) return;

            dispatch(setIsFrozen(true));
            sendEventToDynatrace('clientRotation');
            if (freezeTimeout) {
              clearTimeout(freezeTimeout.current);
            }

            freezeTimeout.current = setTimeout(() => {
              dispatch(setIsFrozen(false));
            }, FREEZE_TIMEOUT);
          });
        } else {
          localStorage.removeItem(LOCAL_STORAGE_ROOM_ID_KEY);
        }
      });
    }
  }, [dispatch, socket, roomId, emit, productInfos]);

  // Update rotation model every ROTATION_UPDATE_INTERVAL ms
  useEffect(() => {
    if (socket) {
      if (!rotationUpdateInterval.current) {
        rotationUpdateInterval.current = setInterval(() => {
          emitUpdateRotateModel({ rotateModelUpdatesRef, socket, emit, dispatch });
        }, ROTATION_UPDATE_INTERVAL);
      }
    } else if (rotationUpdateInterval.current) {
      clearInterval(rotationUpdateInterval.current);
      rotationUpdateInterval.current = undefined;
    }

    // Clean up interval on component unmount
    return () => {
      if (rotationUpdateInterval.current) {
        clearInterval(rotationUpdateInterval.current);
        rotationUpdateInterval.current = undefined;
      }
    };
  }, [socket]);

  useEffect(() => {
    if (savedConfigurationData) {
      emit({
        action: 'savedCustomConfig',
        data: JSON.stringify(savedConfigurationData),
      });
    }
  }, [savedConfigurationData, emit]);

  useEffect(() => {
    emit({
      action: 'updateProductData',
      data: JSON.stringify({ currentMode }),
    });

    if (currentMode === '2D') {
      const currentConfiguration = Object.entries(
        getAllAttributesForSetConfiguration()
      ).map(([key, value]: any[]) => {
        return { [key]: value?.value };
      }) as any;
      emit({
        action: 'updateProductData',
        data: JSON.stringify(currentConfiguration),
      });
    }
  }, [currentMode, emit]);

  useEffect(() => {
    emit({
      action: 'updateProductData',
      data: JSON.stringify({ isRotatable }),
    });
  }, [isRotatable, emit]);

  useEffect(() => {
    emit({
      action: 'updateProductData',
      data: JSON.stringify({ currentModelView }),
    });
  }, [currentModelView, emit]);

}

export default useSocketEvents;
