import {
  ADDTOCART_BUTTON_LABEL,
  CONTINUE_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL_DISABLED,
  EDIT_BUTTON_LABEL,
  FINISH_BUTTON_LABEL,
  INFO_ZOOM_BUTTON_LABEL,
  INSTOCK_BUTTON_LABEL,
  LEADTIME_BUTTON_LABEL,
  QRCODE_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  SHARE_BUTTON_LABEL,
  SHARE_PRODUCT_LABEL,
  SHARE_SCREEN_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING,
  START_SHARING_BUTTON_LABEL,
  STOP_SHARING_AFTER_ADDTOCART,
  STOP_SHARING_BUTTON_LABEL,
  STOP_SHARING_BUTTON_LABEL_MOBILE,
  ZOOM_BUTTON_LABEL,
} from './constants';

const clickableButtonLabels = [
  FINISH_BUTTON_LABEL,
  CONTINUE_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING,
  STOP_SHARING_BUTTON_LABEL_MOBILE,
  START_SHARING_BUTTON_LABEL,
  QRCODE_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL_DISABLED,
  STOP_SHARING_BUTTON_LABEL,
  INSTOCK_BUTTON_LABEL,
  LEADTIME_BUTTON_LABEL,
  ADDTOCART_BUTTON_LABEL,
  STOP_SHARING_AFTER_ADDTOCART,
  ZOOM_BUTTON_LABEL,
  INFO_ZOOM_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  SHARE_BUTTON_LABEL,
  SHARE_PRODUCT_LABEL,
] as const;

export type ClickableButtonLabels = (typeof clickableButtonLabels)[number];
type buttonClickEvent = `button_${ClickableButtonLabels}_click`;
type rotationEvent = 'clientRotation' | 'startRotation';
type apiEvent =
  | 'api_fetchSocketJWTToken'
  | 'api_fetchToken'
  | 'api_web_purify'
  | 'api_getSavedConfig'
  | 'api_fetchPrice'
  | 'api_fetchStockQuantity'
  | 'api_leadTime'
  | 'api_checkIfRoomValid'
  | 'api_configVariables'
  | 'api_getCatalogProductBySku';
type optionsEvent = `dt-${string}-${string}`;
type EventType = buttonClickEvent | rotationEvent | apiEvent | optionsEvent;

export function isClickableButtonLabel(label: any): label is ClickableButtonLabels {
  return (
    typeof label === 'string' &&
    clickableButtonLabels.includes(label as ClickableButtonLabels)
  );
}

export const sendEventToDynatrace = (displayText: EventType) => {  
  // @ts-ignore
  if (typeof dtrum !== 'undefined' && dtrum) {
    // @ts-ignore
    const actionId = dtrum?.enterAction(displayText);
    setTimeout(() => {
      // @ts-ignore
      dtrum?.leaveAction(actionId);
    }, 500);
  } else {
    console.log('dynatrace not found');
  }
};
