import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer/selectors';
import { fetchLeadTime } from '../../utils/ApiCalls/ApiCalls';
import { useWindowSize } from '../../utils/threekitHooks';
import { calculateLeadtime } from '../../utils/function/apiFn';
import { translateLeadTime } from '../../utils/function/mapping';

export const useLeadTime = () => {
    const { isMobile } = useWindowSize();
    const params = useSelector(getGlobalSettingsParams);
    const { storeCode = '', sku, country = '' } = params || {};

    const queryKey = useMemo(
        () => ['productLeadTime', storeCode, sku, country],
        [storeCode, sku, country]
    );

    const {
        data: leadTime,
        isLoading: isLoadingLeadTime,
        isError,
        error,
    } = useQuery({
        queryKey,

        queryFn: () =>
            fetchLeadTime({ storeCode, sku, country, isMobile }),
        staleTime: 300000,
        enabled: !!sku && !!storeCode && !!country && !!t,
        refetchOnWindowFocus: false,
        retry: false,
    });

    return useMemo(() => {
        const isLoading = isLoadingLeadTime;


        const calculatedTime: Record<string, any> =
            Number(leadTime?.min || 0) > 0 &&
                Number(leadTime?.max || 0) > 0 &&
                leadTime
                ? calculateLeadtime(leadTime)
                : { unit: null, value: '' };

        const translatedLeadTime =
            calculatedTime?.value && calculatedTime?.unit
                ? translateLeadTime(calculatedTime?.value, calculatedTime?.unit)
                : '';

        return {
            translatedLeadTime,
            calculatedTime,
            leadTime,
            isLoading,
            isError,
            error,
        };
    }, [leadTime, isLoadingLeadTime, isError, error]);
};

export default useLeadTime;
