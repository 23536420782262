import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/animations.scss';
import { useThreekitInitStatus, useWindowSize } from '../../utils/threekitHooks';
import { Container } from './Home.styles';
import { AnimateItem, AwaitPlayerLoad, Player2D, SharingModal } from '../../components';
import { useNavigate } from 'react-router';
import {
  getGlobalSettingsParams,
  getGradient,
  getStep,
  setGlobalSettingsParams,
  setStep,
} from '../../store/globalSettingsSlicer';
import {
  getForm,
  getIsChina,
  getIsRotatable,
  getPage,
  setInitialConfiguration
} from '../../store/threekitSlicer';
import { RESET_STEP_ACCORDION, RESET_STEP_CAROUSEL } from '../../utils/constants';
import { usePageTitle } from '../../hooks';
import ProductInfos from './components/ProductInfos';
import SummaryPage from '../SummaryPage/SummaryPage';
import useSavedConfiguration from '../../hooks/useSavedConfiguration';
import useUpdateFormAndPlayer from '../../hooks/usePlayerMode';
import { t } from 'i18next';

import {
  getParams,
  paramsObjectToNavigationString,
} from '../../utils/function/navigationParams';
import { PhotosDisplayer } from '../../components';
import { waitForDataDrivenConfigurator } from '../../utils/function/dataDrivenFn';

import Configurator from './components/Configurator';
import Modal from './components/Modal';
import {
  getHideRemoteViewer,
  getIsExpireModalShowing,
  getIsSharingModalOpen,
  setIsSharingModalOpen
} from '../../store/liveSessionSlicer';
import ExpirationWarning from './components/ExpirationWarning';
import { setGradientBackgroundToCanvas } from '../../components/PlayersThreekit/Player2D/GradientBackground';

const Home = () => {
  usePageTitle({ page: 'home' });
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoaded = useThreekitInitStatus();
  const hideRemoteViewer = useSelector(getHideRemoteViewer);
  const currentStep = useSelector(getStep);
  const isSharingModalOpen = useSelector(getIsSharingModalOpen);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const form = useSelector(getForm);
  const isChina = useSelector(getIsChina);
  const pageToDisplay = useSelector(getPage) === 'home';
  const isExpireModalShowing = useSelector(getIsExpireModalShowing);
  const isRotatable = useSelector(getIsRotatable);
  const { configId, sku } = globalSettingsParams || { configId: '', sku: '' };
  const [configuratorReady, setIsConfiguratorReady] = useState(false);
  const dataDrivenSku =
    configuratorReady && window.dataDrivenConfiguratorExtension?.getStatus()?.sku?.value;
  const { savedConfigurationData } = useSavedConfiguration(configId!);
  const gradient = useSelector(getGradient)

  useEffect(() => {
    setGradientBackgroundToCanvas({ canvasId: 'canvas', dispatch, currentGradient: gradient });
  }, [pageToDisplay, dispatch, gradient, isLoaded, currentStep, configId, sku]);

  const { playerMode } = useUpdateFormAndPlayer({
    form,
    currentStep
  });

  const handleCloseModal = () => {
    dispatch(setIsSharingModalOpen(false));
  };

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isInitialConfigurationSet, setIsInitialConfigutationSet] = useState(false);

  useEffect(() => {
    if (isChina === undefined) return;
    const params = getParams();
    if (!params.step || params.step < RESET_STEP_CAROUSEL) {
      const initialStep = isMobile ? RESET_STEP_CAROUSEL : RESET_STEP_ACCORDION;
      params.step = initialStep;
      dispatch(setStep(initialStep));
      const finalParams = paramsObjectToNavigationString(params, isChina);
      navigate(`/${finalParams}`);
    } else {
      dispatch(setStep(params.step));
    }
  }, [isChina, dispatch, navigate, isMobile]);

  // set sku according to the data driven sku
  useEffect(() => {
    const newParams = {
      ...globalSettingsParams,
      sku: dataDrivenSku,
    };
    if (dataDrivenSku !== sku) {
      dispatch(setGlobalSettingsParams(newParams));
    }
  }, [dataDrivenSku, dispatch, globalSettingsParams, sku]);

  useEffect(() => {
    waitForDataDrivenConfigurator().then((isReady) => {
      setIsConfiguratorReady(isReady);
    });
  }, []);

  useEffect(() => {
    if (isInitialConfigurationSet || !dataDrivenSku) return;
    const initiateConfiguration = async () => {
      const initialConfig = await window.threekit?.configurator?.getFullConfiguration();
      if (initialConfig) {
        dispatch(setInitialConfiguration(initialConfig));
        setIsInitialConfigutationSet(true);
      }
    };
    initiateConfiguration();
  }, [dataDrivenSku, dispatch, isInitialConfigurationSet]);

  return configuratorReady ? (
    <Container overscroll={pageToDisplay} id="home-container">
      {isExpireModalShowing && !hideRemoteViewer && (
        <Modal
          opacity={isExpireModalShowing ? 0 : 1}
          isOpen={true}
          onClose={() => { }}
          title={t('section.label.remote_configurator', 'Remote Configurator')}
          isMobile={isMobile}
          overlappingModal={true}
          closeButton={false}
        >
          <ExpirationWarning />
        </Modal>
      )}

      <AwaitPlayerLoad>
        {pageToDisplay && isMobile && isLoaded && (
          <ProductInfos globalSettingsParams={globalSettingsParams} isMobile={isMobile} />
        )}
        <Player2D
          homePage
          playerMode={playerMode}
          cssDisplay={pageToDisplay}
          isMobile={isMobile}
          isRotable={isRotatable}
          isLoaded={isLoaded}
        >
          {!isMobile && <PhotosDisplayer />}

          <AnimateItem />
        </Player2D>
        {pageToDisplay && isLoaded && <Configurator />}
        {!hideRemoteViewer &&
          <SharingModal
            isOpen={isSharingModalOpen}
            handleClose={handleCloseModal}
            isMobile={isMobile}
            timeoutRef={timeoutRef}
          />}
      </AwaitPlayerLoad>
      {!pageToDisplay && isLoaded && savedConfigurationData && (
        <SummaryPage data={savedConfigurationData} />
      )}
    </Container>
  ) : null;
};

export default Home;
