import { stateRoot } from 'store';

export const getIsSessionLive = (state: stateRoot) => state.liveSession.isSessionLive;

export const getRoomId = (state: stateRoot) => state.liveSession.roomId;

export const getIsSharingModalOpen = (state: stateRoot) =>
  state.liveSession.isSharingModalOpen;

export const getRoomClosedDueToInactivity = (state: stateRoot) =>
  state.liveSession.roomClosedDueToInactivity;

export const getLastConfig = (state: stateRoot) => state.liveSession.lastConfig;

export const getHideRemoteViewer = (state: stateRoot) => state.liveSession.hideRemoteViewer;

export const getRotationUpdates = (state: stateRoot) => state.liveSession.rotationUpdates;

export const getIsFrozen = (state: stateRoot) => state.liveSession.isFrozen;

export const getIsInvalidCode = (state: stateRoot) => state.liveSession.isInvalidCode;

export const getUrlCopied = (state: stateRoot) => state.liveSession.urlCopied;

export const getUserInputtedCode = (state: stateRoot) => state.liveSession.code;

export const getServerSocket = (state: stateRoot) => state.liveSession.serverSocket;

export const getHubName = (state: stateRoot) => state.liveSession.hubName;

export const getBaseUrlClientRemoteViewer = (state: stateRoot) => state.liveSession.baseUrlClientRemoteViewer;

export const getServerSocketPath = (state: stateRoot) => state.liveSession.serverSocketPath;

export const getIsExpireModalShowing = (state: stateRoot) =>
  state.liveSession.isExpireModalShowing;

export const getClosingDate = (state: stateRoot) => state.liveSession.closingDate;
