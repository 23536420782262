import memoizeOne from 'memoize-one';
import { ThreekitState } from '..';
import { stateRoot } from 'store';

export const getViewUpdate: (
  state: stateRoot
) => ThreekitState['viewUpdate'] = memoizeOne(
  (state) => state?.threekit?.viewUpdate
);

export const getRotationUpdate: (
  state: stateRoot
) => ThreekitState['rotationUpdate'] = memoizeOne(
  (state) => state?.threekit?.rotationUpdate
);
