import { PayloadAction } from '@reduxjs/toolkit';
import { ThreekitState } from '..';

export const currentModeReducers = {
  setCurrentMode(
    state: ThreekitState,
    action: PayloadAction<ThreekitState['currentMode']>
  ) {
    if (state.currentMode !== action.payload) {
      state.currentMode = action.payload;
    }
  },
  setCurrentModelView(
    state: ThreekitState,
    action: PayloadAction<ThreekitState['modelView']>
  ) {
    if (state.modelView !== action.payload) {
      state.modelView = action.payload;
    }
  },
  setCurrentModelViewKey(
    state: ThreekitState,
    action: PayloadAction<ThreekitState['currentModelViewKey']>
  ) {
    if (state.currentModelViewKey !== action.payload) {
      state.currentModelViewKey = action.payload;
    }
  },
  setCurrentModelViewInfos(
    state: ThreekitState,
    action: PayloadAction<ThreekitState['currentModelViewInfos']>
  ) {
    if (state.currentModelViewInfos !== action.payload) {
      state.currentModelViewInfos = action.payload;
    }
  }
};
