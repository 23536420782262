import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { CLOSE_ICON } from '../../../assets';

interface ModalContentProps {
  isMobile: boolean;
  isOpen: boolean;
  opacity: 0 | 1;
  overlappingModal: boolean;
}

interface ModalWrapperProps {
  isMobile: boolean;
  isOpen: boolean;
  overlappingModal: boolean;
}

const ModalWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['overlappingModal', 'isOpen', 'isMobile'].includes(prop),
})<ModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({isOpen}) => isOpen ? '1000' : '-1'};
  transition: opacity 0.3s ease;
  ${({ overlappingModal }) => css`
    background: ${overlappingModal ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  `}

  ${({ isOpen }) => css`
    opacity: ${isOpen ? 1 : 0};
    top: ${isOpen ? 0 : '-100%'};
  `}
`;

const ModalContent = styled.div.withConfig({
  shouldForwardProp: (prop) => !['overlappingModal', 'isOpen', 'isMobile', 'opacity'].includes(prop),
})<ModalContentProps>`
  position: relative;

  background-color: white;
  padding: 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  ${({ opacity, overlappingModal }) => css`
    opacity: ${!overlappingModal && opacity === 0 ? '0 !important' : 1};
  `}

  ${({ isMobile, overlappingModal, isOpen, opacity }) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0px;
      height: auto;
      border-radius: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      max-width: none;
      margin: 0px;
      transition: transform 0.3s ease;
      opacity: ${!overlappingModal && opacity === 0 ? 0 : isOpen ? '1' : '0'};
      transform: translateY(${isOpen ? '0%' : '100%'});
    `}
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  flex: 1;
  text-align: center;
  font-family: LouisVuitton_Medium;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 40px;
`;

const CloseButton = styled.button`
  /* position: relative; */
  position: absolute;
  top: 25px;
  left: 17px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  > img {
    width: 25px;
  }
`;

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isMobile?: boolean;
  overlappingModal?: boolean;
  opacity: 0 | 1;
  closeButton?: boolean;
}

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  opacity = 1,
  isMobile = false,
  overlappingModal = false,
  closeButton = true,
}: ModalProps) => {
  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <ModalWrapper
      onClick={handleWrapperClick}
      isMobile={isMobile}
      isOpen={isOpen}
      overlappingModal={overlappingModal}
    >
      <ModalContent
        isMobile={isMobile}
        isOpen={isOpen}
        opacity={opacity}
        overlappingModal={overlappingModal}
      >
        {closeButton && (
          <CloseButton onClick={onClose}>
            <img alt="" src={CLOSE_ICON} />
          </CloseButton>
        )}
        <ModalHeader>{title && <Title>{title}</Title>}</ModalHeader>
        {children}
      </ModalContent>
    </ModalWrapper>,
    document.body
  );
};

export default Modal;
