import memoizeOne from 'memoize-one';
import { stateRoot } from 'store';
import { SettingsState } from '..';

export const getSettings: (state: stateRoot) => SettingsState = memoizeOne(
  (state) => state?.threekit?.settings
);

export const getConfig: (state: stateRoot) => SettingsState['config'] =
  memoizeOne((state) => state?.threekit?.settings?.config);

export const isLoading: (state: stateRoot) => SettingsState['isLoading'] =
  memoizeOne((state) => state?.threekit?.settings?.isLoading);

export const isClient: (state: stateRoot) => SettingsState['isClient'] =
  memoizeOne((state) => state?.threekit?.settings?.isClient);

export const getIsChina: (state: stateRoot) => SettingsState['isChina'] =
  memoizeOne((state) => state?.threekit?.settings?.isChina);


export const getPlayerSize: (state: stateRoot) => SettingsState['playerSize'] =
  memoizeOne((state) => state?.threekit?.settings?.playerSize);

export const isThreekitLoaded: (
  state: stateRoot
) => SettingsState['isThreekitLoaded'] = memoizeOne(
  (state) => state?.threekit?.settings?.isThreekitLoaded
);

export const isPlayerLoading: (
  state: stateRoot
) => SettingsState['isPlayerLoading'] = memoizeOne(
  (state) => state?.threekit?.settings?.isPlayerLoading
);

export const getLanguage: (state: stateRoot) => SettingsState['language'] =
  memoizeOne((state) => state?.threekit?.settings?.language);

export const getAllowInPlayerReorder: (
  state: stateRoot
) => SettingsState['allowInPlayerReorder'] = memoizeOne(
  (state) => state?.threekit?.settings?.allowInPlayerReorder
);

export const getAllowInPlayerSelect: (
  state: stateRoot
) => SettingsState['allowInPlayerSelect'] = memoizeOne(
  (state) => state?.threekit?.settings?.allowInPlayerSelect
);
