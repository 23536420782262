import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchPrice } from '../../utils/ApiCalls/ApiCalls';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer/selectors';
import { useWindowSize } from '../../utils/threekitHooks';
import { getPriceWithCurrency } from '../../utils/function/apiFn';

const usePrice = () => {
  const { isMobile } = useWindowSize();
  const params = useSelector(getGlobalSettingsParams);
  const { storeCode, sku, country } = params || {};

  const queryKey = useMemo(
    () => ['productPrice', storeCode, sku, country],
    [storeCode, sku, country]
  );

  const {
    data: price = { price_value: null, currency: '' },
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey,
    queryFn: () =>
      fetchPrice({ storeCode, sku, country, isMobile }),
    staleTime: 300000,
    refetchOnWindowFocus: false,
    enabled: !!sku && !!storeCode && !!country,
    retry: false,
  });

  return useMemo(() => {
    if (isLoading || isError) {
      return { isLoading, isError, error };
    }

    const priceValue: number | null = price?.price_value;
    const currency = price?.currency;
    const priceWithCurrency = getPriceWithCurrency(price);
    const priceFormated =
      price?.currency && price?.price_value ? (
        <FormattedNumber
          style="currency"
          currency={price?.currency}
          value={price?.price_value}
        />
      ) : (
        ''
      );

    return {
      price,
      priceValue,
      priceWithCurrency,
      priceFormated,
      currency,
      isLoading,
      isError,
      error,
    };
  }, [price, isLoading, isError, error]);
};

export default usePrice;
