import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { checkTextPurity } from '../../utils/ApiCalls/ApiCalls';
import { useWindowSize } from '../../utils/threekitHooks';

interface WebPurifyHookParams {
    text: string;
    lng: string;
    enabled: boolean;
}

export const useWebPurify = ({ text, lng, enabled=true }: WebPurifyHookParams) => {
    const { isMobile } = useWindowSize();

    const queryKey = useMemo(() => ['checkWebPurify', text, lng], [text, lng]);

    const {
        data = { valid: false },
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey,
        queryFn: () => checkTextPurity({ text, lng, isMobile }),
        staleTime: 300000,
        enabled: !!text && !!lng && enabled,
        retry: false,
    });

    return { data, isLoading, isError, error };
};

export default useWebPurify;
