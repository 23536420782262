export const waitForDataDrivenConfigurator = async (): Promise<boolean> => {
  while (
    typeof window.dataDrivenConfigurator !== 'object' ||
    !window.dataDrivenConfigurator?.isInited ||
    typeof window.dataDrivenConfiguratorExtension !== 'object' ||
    !window.dataDrivenConfiguratorExtension?.isInited ||
    typeof window.dataDrivenConfiguratorExtension?.getStatus !== 'function'
  ) {
    const wait = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await wait(100);
  }
  return true;
};