import memoizeOne from 'memoize-one';
import { stateRoot } from 'store';
import { ProductPageState } from '..';

export const getProductPage: (state: stateRoot) => ProductPageState = memoizeOne(
  (state) => state?.threekit?.productPage
);

// 3D Player
export const getDisplaySize: (state: stateRoot) => ProductPageState['displaySize'] =
  memoizeOne((state) => state?.threekit?.productPage?.displaySize);

export const getPlaceHolderError: (
  state: stateRoot
) => ProductPageState['placeHolderError'] = memoizeOne(
  (state) => state?.threekit?.productPage?.placeHolderError
);

export const getNewSize: (state: stateRoot) => ProductPageState['newSize'] = memoizeOne(
  (state) => state?.threekit?.productPage?.newSize
);

export const getPage: (state: stateRoot) => ProductPageState['page'] = memoizeOne(
  (state) => state?.threekit?.productPage?.page
);

export const getCharactRest: (state: stateRoot) => ProductPageState['charactRest'] =
  memoizeOne((state) => state?.threekit?.productPage?.charactRest);

export const getCurrentEngraving: (
  state: stateRoot
) => ProductPageState['currentEngraving'] = memoizeOne(
  (state) => state?.threekit?.productPage?.currentEngraving
);

export const getPlayer3DImage: (state: stateRoot) => ProductPageState['player3DImage'] =
  memoizeOne((state) => state?.threekit?.productPage?.player3DImage);

export const getIsFullScreen: (state: stateRoot) => ProductPageState['isFullScreen'] =
  memoizeOne((state) => state?.threekit?.productPage?.isFullScreen);

export const getIsRotatable: (state: stateRoot) => ProductPageState['isRotatable'] =
  memoizeOne((state) => state?.threekit?.productPage?.isRotatable);

export const getPlayer3DStep: (state: stateRoot) => ProductPageState['player3DStep'] =
  memoizeOne((state) => state?.threekit?.productPage?.player3DStep);

export const getHasPatch: (state: stateRoot) => ProductPageState['hasPatch'] = memoizeOne(
  (state) => state?.threekit?.productPage?.hasPatch
);

export const getPlayerFullScreen: (
  state: stateRoot
) => ProductPageState['playerFullScreen'] = memoizeOne(
  (state) => state?.threekit?.productPage?.playerFullScreen
);

export const getBaseUrlClient: (state: stateRoot) => ProductPageState['baseUrlClient'] =
  memoizeOne((state) => state?.threekit?.productPage?.baseUrlClient);

export const getCurrentIndex: (state: stateRoot) => ProductPageState['currentIndex'] =
  memoizeOne((state) => state?.threekit?.productPage?.currentIndex);

export const getDisplayAutofocus: (
  state: stateRoot
) => ProductPageState['displayAutofocus'] = memoizeOne(
  (state) => state?.threekit?.productPage?.displayAutofocus
);

export const getCurrentSlide: (
  state: stateRoot
) => ProductPageState['currentSlideActive'] = memoizeOne(
  (state) => state?.threekit?.productPage?.currentSlideActive
);

export const getSizeAutofocus: (state: stateRoot) => ProductPageState['sizeAutofocus'] =
  memoizeOne((state) => state?.threekit?.productPage?.sizeAutofocus);

// 2D Player

export const getToogle: (state: stateRoot) => ProductPageState['toogle'][number] =
  memoizeOne((state) => state?.threekit?.productPage?.toogle[1]);

export const getToogle2: (state: stateRoot) => ProductPageState['toogle'][number] =
  memoizeOne((state) => state?.threekit?.productPage?.toogle[2]);

export const getIsOpen: (state: stateRoot) => ProductPageState['isOpen'] = memoizeOne(
  (state) => state?.threekit?.productPage?.isOpen
);

export const getPreviousCamera: (state: stateRoot) => ProductPageState['previousCamera'] =
  memoizeOne((state) => state?.threekit?.productPage?.previousCamera);

export const getLastAngle: (state: stateRoot) => ProductPageState['lastAngle'] =
  memoizeOne((state) => state?.threekit?.productPage?.lastAngle);

export const getTotalEngraving: (state: stateRoot) => ProductPageState['totalEngraving'] =
  memoizeOne((state) => state?.threekit?.productPage?.totalEngraving);

export const getToggleSlider: (state: stateRoot) => ProductPageState['toggleSlider'] =
  memoizeOne((state) => state?.threekit?.productPage?.toggleSlider);
