import { styled } from 'styled-components';

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['aboveFormHeight'].includes(prop),
})<{ aboveFormHeight?: number }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    aboveFormHeight,
  }) => {
    return `
    
        display: flex;
        flex-direction: column;
        width: 100vw;
        border-radius: 20px 20px 0px 0px;
             
         ${
           isMobile
             ? `box-shadow: 0px -5px 50px 0px rgba(0, 0, 0, 0.1);  
        border-radius: 20px 20px 0px 0px;
        overflow: auto;
        z-index: 10;
        flex: 1 0;
        `
             : isIpad
               ? `
             height: calc(100% - ${aboveFormHeight}px);
             width: 100%; 
             border-radius: 0px;
             
             `
               : isDesktop
                 ? `
              height: calc(100% - ${aboveFormHeight}px);
              width: 100%;
              border-radius: 0px;
             `
                 : ''
         }
    `;
  }
);

export const AboveFormContainer = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => {
    return `
 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 10px 17px;
    `;
  }
);

export const ProductAndAboveContainer = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => {
    return `
 
        display: flex;
        flex-direction: column;
        padding: 20px 15px 20px;
        gap: 5px;
    `;
  }
);
