// import { OptionsButton } from 'components';
import { KEEP_SHARING_BUTTON_LABEL, STOP_SHARING_BUTTON_LABEL } from '../../../../utils/constants';
import OptionsButton from '../../../../components/Form/OptionsButton';
import { Buttons, Container, Content } from './styles';
import { useWindowSize } from '../../../../utils/threekitHooks';
import { t } from 'i18next';

interface StopSharingProps {
  handleCloseModal: () => void;
  handleStopSharing: () => void;
  showCancelButton?: boolean;
}

function StopSharing({ handleCloseModal, handleStopSharing, showCancelButton = false }: StopSharingProps) {
  const { isMobile } = useWindowSize();

  return (
    <Container>
      {!isMobile && (
        <Content>
          {t('info.stop_sharing_validation', 'Do you wish to stop sharing this configuration with your client ?')}
        </Content>
      )}
      <Buttons>
        <OptionsButton
          buttonName={STOP_SHARING_BUTTON_LABEL}
          fnButton={() => {
            handleCloseModal();
            handleStopSharing();
          }}
        />

        {showCancelButton && (
          <OptionsButton
            buttonName={KEEP_SHARING_BUTTON_LABEL}
            fnButton={() => {
              handleCloseModal();
            }}
          />
        )}
      </Buttons>
    </Container>
  );
}

export default StopSharing;
