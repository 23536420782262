import { createGlobalStyle, styled } from 'styled-components';
import '../font.scss';
import { FC } from 'react';
import { LouisVuittonDemi, LouisVuittonMedium, LouisVuittonRegular } from '../../assets';

export const BasicLayout = createGlobalStyle(
  () => `

  @font-face {
    font-family: 'LouisVuitton_Demi';
    src: url(${LouisVuittonDemi}) format('truetype');
  }
  @font-face {
    font-family: 'LouisVuitton_Medium';
    src: url(${LouisVuittonMedium}) format('truetype');
  }
  @font-face {
    font-family: 'LouisVuitton_Regular';
    src: url(${LouisVuittonRegular}) format('truetype');
  }
  @font-face {
    font-family: 'LouisVuitton_Bold';
    src: url('https://tp.louisvuitton.com/fonts/bin/LouisVuitton-Demi_logo.woff') format('woff');
  }

  html, body {
    padding: 0;
    margin: 0;
    font-family: 'LouisVuitton_Regular', 'Roboto', sans-serif;
    background-color: #fff;
  }

  * {
    touch-action: pan-x pan-y;
  }

  input {
    direction: ltr;
  }

    ::-webkit-scrollbar {
      display: none;
    }

  #deviceToken {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;

  }

  @media only screen and (max-device-width: 1024px) {
    * {
        user-select: none;
        -webkit-user-drag: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
    }
}
.notistack-SnackbarContainer {
  align-items: flex-start !important;
  z-index: 111111111;
}

}
`
);

export const Version = styled.div`
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 10px;
  color: #fff;
  background-color: #000;
`;

const GlobalStyle: FC<any> = ({ children }) => {
  return (
    <>
      <BasicLayout />
      {children}
    </>
  );
};

export default GlobalStyle;
