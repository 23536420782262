import React from 'react';
import { ProductInfosContainer, ProductName, Reset } from '../Home.styles';
import ProductPrice from '../../../components/ProductPrice';
import { useProductName } from '../../../hooks';
import AutoDisappearElement from './AutoDisappearElement';
import OptionsButton from '../../../components/Form/OptionsButton';
import { RESET_BUTTON_LABEL } from '../../../utils/constants';

interface ProductInfosProps {
  isRegular?: boolean;
  isMobile?: boolean;
  globalSettingsParams: any;
}

export const ProductInfos: React.FC<ProductInfosProps> = ({
  isRegular = false,
  isMobile = false,
}) => {
  const productName = useProductName();
  return (
    <>
      <ProductInfosContainer>
        {!isRegular ? (
          <AutoDisappearElement>
            <ProductName>{productName}</ProductName>
          </AutoDisappearElement>
        ) : null}
        <ProductPrice />
      </ProductInfosContainer>
      {isMobile && (
        <Reset>
          <OptionsButton buttonName={RESET_BUTTON_LABEL} />
        </Reset>
      )}
    </>
  );
};

export default ProductInfos;
