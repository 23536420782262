import { t } from 'i18next';

export const mappingLanguage = (lng: string | undefined): string => {
  switch (lng) {
    // German
    case 'de':
    case 'de-DE':
    case 'deu':
    case 'de_DE':
      return 'de_DE';

    // English
    case 'en':
    case 'en-US':
    case 'en-GB':
    case 'ENG':
    case 'eng':
    case 'en_E1':
      return 'en_E1';

    // Spanish
    case 'es':
    case 'es-ES':
    case 'es-MX':
    case 'esp':
    case 'es_ES':
      return 'es_ES';

    // French
    case 'fr':
    case 'fr-FR':
    case 'fr-CA':
    case 'fra':
    case 'fr_FR':
      return 'fr_FR';

    // Italian
    case 'it':
    case 'it-IT':
    case 'ita':
    case 'it_IT':
      return 'it_IT';

    // Japanese
    case 'ja':
    case 'ja-JP':
    case 'jpn':
    case 'ja_JP':
      return 'ja_JP';

    // Korean
    case 'ko':
    case 'ko-KR':
    case 'kor':
    case 'ko_KR':
      return 'ko_KR';

    // Portuguese
    case 'pt':
    case 'pt-BR':
    case 'por':
    case 'pt_BR':
      return 'pt_BR';

    // Russian
    case 'ru':
    case 'ru-RU':
    case 'rus':
    case 'ru_RU':
      return 'ru_RU';

    // Simplified Chinese
    case 'zh':
    case 'zh-CN':
    case 'zh-Hans-CN':
    case 'zhs':
    case 'zh_CN':
      return 'zh_CN';

    // Traditional Chinese (Hong Kong)
    case 'zh-Hant-HK':
    case 'zh-HK':
    case 'zh_HK':
      return 'zh_HK';

    // Traditional Chinese (Taiwan)
    case 'zh-Hant-TW':
    case 'zh-TW':
    case 'zht':
    case 'zh_TW':
      return 'zh_TW';

    // Default to English
    default:
      return 'en_E1';
  }
};

export const webPurifyLanguageMappings = (lng: string): string => {
  switch (lng) {
    case 'de_DE':
      return 'de';

    case 'deu':
      return 'de';

    case 'en_E1':
      return 'en';

    case 'ENG':
      return 'en';

    case 'eng':
      return 'en';

    case 'es_ES':
      return 'es';

    case 'esp':
      return 'es';

    case 'fr_FR':
      return 'fr';

    case 'fra':
      return 'fr';

    case 'it_IT':
      return 'it';

    case 'ita':
      return 'it';

    case 'ja_JP':
      return 'ja';

    case 'jpn':
      return 'ja';

    case 'ko_KR':
      return 'ko';

    case 'kor':
      return 'ko';

    case 'pt_BR':
      return 'pt';

    case 'por':
      return 'pt';

    case 'ru_RU':
      return 'ru';

    case 'rus':
      return 'ru';

    case 'zh_CN':
      return 'zh';

    case 'zhs':
      return 'zh';

    case 'zh_HK':
      return 'zh';

    case 'zht':
      return 'zh';

    case 'ara':
      return 'ar_AE';

    case 'ar_AE':
      return 'ar_AE';

    default:
      return 'en';
  }
};

export function translateLeadTime(leadTime: string, leadTimeUnit: string) {
  let translationKey: string;

  switch (leadTimeUnit) {
    case 'day_one':
      translationKey = 'stock.label.date.day_one';
      break;
    case 'days':
      translationKey = 'stock.label.date.day_other';
      break;
    case 'day_zero':
      translationKey = 'stock.label.date.day_zero';
      break;
    case 'month_one':
      translationKey = 'stock.label.date.month_one';
      break;
    case 'month_other':
      translationKey = 'stock.label.date.month_other';
      break;
    case 'week_one':
      translationKey = 'stock.label.date.week_one';
      break;
    case 'weeks':
      translationKey = 'stock.label.date.week_other';
      break;
    default:
      translationKey = 'stock.label.date.day_other';
  }

  return t(translationKey, { leadTime, interpolation: { escapeValue: false } });
}

export interface ILeadTimeAndPrice {
  maxLeadTime?: number;
  minLeadTime?: number;
  price?: number | null;
  currency?: string;
}

interface IInputJson {
  metadata?: {
    readableConfiguration?: Record<
      string,
      {
        index?: number;
        value: any;
        thumbnailPath?: string;
        thumbnail?: string;
        thumbnailColor?: string;
      }
    >;
  };
  createdAt?: string;
  shortId?: string;
  attachments?: Record<string, any>;
  clientUrl?: string;
  editUrl?: string;
  productName?: string;
  inStore?: boolean;
  sku?: string;
}
interface IConfigItem {
  label: string;
  index: number;
  value: any;
  thumbnailPath?: string;
  thumbnailColor?: string;
}
interface IOutputJson {
  readableConfiguration: IConfigItem[];
  createdAt: string;
  shortId: string;
  attachments?: Record<string, any>;
  clientUrl: string;
  editUrl: string;
  productName: string;
  inStore: boolean;
  leadTime: number;
  price: number;
  currency: string;
  sku: string;
}

export const parseRecipe = (
  inputJson: IInputJson,
  leadTimeAndPrice: ILeadTimeAndPrice
): IOutputJson => {
  let readableConfig: IConfigItem[] = [];
  let index = 0;
  for (const [key, value] of Object.entries(
    inputJson?.metadata?.readableConfiguration || {}
  )) {
    const configItem: IConfigItem = {
      label: key,
      index: value.index || index || 0,
      value: value.value,
      thumbnailPath: value?.thumbnailPath || value?.thumbnail,
      thumbnailColor: value?.thumbnailColor,
    };
    readableConfig.push(configItem);
    index++;
  }
  const outputJson: IOutputJson = {
    readableConfiguration: readableConfig,
    createdAt: inputJson.createdAt || '',
    shortId: inputJson.shortId || '',
    attachments: Object.keys(inputJson.attachments || {}).length
      ? inputJson.attachments
      : undefined,
    clientUrl: inputJson.clientUrl || '',
    editUrl: inputJson.editUrl || '',
    productName: inputJson.productName || '',
    inStore: inputJson.inStore || false,
    leadTime: leadTimeAndPrice.maxLeadTime || leadTimeAndPrice.minLeadTime || 0,
    price: leadTimeAndPrice.price || 0,
    currency: leadTimeAndPrice.currency || '',
    sku: inputJson.sku || '',
  };

  return outputJson;
};
