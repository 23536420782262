import React, { useCallback, useEffect, useRef, ReactNode, useState } from 'react';
import Controller from '../../../controller';
import { Wrapper } from './Player2D.styles';
import add2DSpinTool from '../../Tools/add2DSpin';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { useAttribute, useThreekitInitStatus } from '../../../utils/threekitHooks';
import {
  CLASS_NAME_PREFIX,
  DEFAULT_CLASS_NAME,
  INFO_ZOOM_BUTTON_LABEL,
  patchCameraId,
  TK_PLAYER_ATTRIBUTE_ID,
  TK_PLAYER_DIV_ID_2D,
  TK_PLAYER_DIV_ID_3D,
  ZOOM_BUTTON_LABEL,
} from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentMode,
  getIsFullScreen,
  setViewUpdate,
  setIsFullScreen,
  setPlayerLoading,
  setPlayerSize,
  setConfiguration,
} from '../../../store/threekitSlicer';
import { getGradient, getStep } from '../../../store/globalSettingsSlicer/selectors';
import { OptionsButton } from '../..';
import { addOpacityAnimation, getEventPosition, isWithinTapArea } from '../../../utils/function/functions';
import { useMutationObserver, useSocket } from '../../../hooks';
import Frozen from '../../../pages/HomePage/components/Frozen/Frozen';
import RelativeModal from '../../../pages/HomePage/components/RelativeModal';
import { getIsExpireModalShowing, getIsFrozen, getRoomId } from '../../../store/liveSessionSlicer';
import { sendEventToDynatrace } from '../../../utils/dyntatraceUtils';

type Config = { [key: string]: string | number | boolean };

interface Player2DProps {
  height?: string;
  width?: string;
  border?: string;
  cssDisplay?: boolean;
  className?: string;
  playerMode?: string;
  homePage?: boolean;
  children?: ReactNode;
  clientPage?: boolean;
  isMobile?: boolean;
  isLoaded: boolean;
  isRotable: boolean;
}

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const Player2D: React.FC<Player2DProps> = ({
  height = '50vh',
  width = '100%',
  border = 'none',
  cssDisplay,
  className,
  playerMode,
  homePage,
  children,
  isMobile,
  isLoaded,
  isRotable,
}) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [_, setRotateModel] = useAttribute('Rotate Model');
  const configurationToClone = async () => await window.configurator?.getFullConfiguration();
  const [toggleButton, setToggleButton] = useState(true);
  const isFullScreen = useSelector(getIsFullScreen);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [lastTapPosition, setLastTapPosition] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch<AppThunkDispatch>();
  const step = useSelector(getStep);
  const currentMode = useSelector(getCurrentMode);
  const playerReady = useThreekitInitStatus();
  const isFrozen = useSelector(getIsFrozen);
  const isExpireModalShowing = useSelector(getIsExpireModalShowing);
  const { socket, emit } = useSocket();
  const roomId = useSelector(getRoomId);
  const gradient = useSelector(getGradient)

  const [startX, setStartX] = useState(0);

  const getClientX = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if ('touches' in e) {
      return e?.touches[0]?.clientX;
    }
    return e?.clientX;
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (e.type === 'mousedown') {
      e.preventDefault();
    }

    setStartX(getClientX(e));
  };

  function handleTouchEnd(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) {
    if (e.type === 'mouseup') {
      e.preventDefault();
    }

    const endX = getClientX(e);

    const deltaX = Math.abs(endX - startX);

    if (deltaX > 10) {
      sendEventToDynatrace('startRotation');
    }
  }

  const canvas_3D: HTMLCanvasElement | null | undefined = document.getElementById('player-3d')?.querySelector('canvas');
  useMutationObserver({
    element: canvas_3D,
    callback: () => {
      return dispatch(setPlayerLoading(false));
    },
  });

  const handleLastAngle = useCallback(
    (config: Config) => {
      dispatch(setConfiguration(config));
    },
    [dispatch]
  );

  useEffect(() => {
    if (playerReady) {
      Controller.attachPlayerToComponent(TK_PLAYER_DIV_ID_2D);
    }
  }, [playerReady]);

  useEffect(() => {
    if (playerReady) {
      const { clientWidth = 0, clientHeight = 0 } = playerRef.current || {};
      dispatch(setPlayerSize({ width: clientWidth, height: clientHeight }));
    }
  }, [playerReady, currentMode]);

  useEffect(() => {
    if (!isRotable)  {
      setRotateModel('0');
    }

    if (playerReady) {
      add2DSpinTool(
        { attributeId: TK_PLAYER_ATTRIBUTE_ID },
        handleLastAngle,
        isRotable && !isFrozen,
        isFullScreen,
        dispatch,
        gradient,
        setToggleButton,
      );
    }
  }, [playerReady, isRotable, isFullScreen, isFrozen]);

  const handleDoubleTap = (event: any) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapTime;
    const currentPosition = getEventPosition(event);
    if (tapLength < 500 && tapLength > 0 && isWithinTapArea(lastTapPosition, currentPosition, 15)) {
      changeZoom();
    }
    setLastTapTime(currentTime);
    setLastTapPosition({
      ...lastTapPosition,
      ...currentPosition,
    });
  };

  const handleFullScreen = () => {
    changeZoom();
  };

  const changeZoom = () => {
    dispatch(setViewUpdate(true));

    if (isFullScreen && currentMode === '2D') {
      while (document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.pointerEvents !== 'none') {
        document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.setProperty('pointer-events', 'none');
      }
      dispatch(setPlayerLoading(true));

      setTimeout(() => {
        if (document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.pointerEvents === 'none') {
          addOpacityAnimation(TK_PLAYER_DIV_ID_2D, 100);
          document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.removeProperty('pointer-events');
          dispatch(setIsFullScreen(!isFullScreen));
          dispatch(setPlayerLoading(false));
        }
      }, 50);

      setTimeout(() => {
        setTimeout(() => {
          dispatch(setViewUpdate(false));
        }, 1000);
      }, 1000);

      return;
    }

    dispatch(setIsFullScreen(!isFullScreen));

    setTimeout(() => {
      setTimeout(() => {
        dispatch(setViewUpdate(false));
      }, 1000);
    }, 1000);
  };
  const newClassName = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-player ${className}`;

  const switchTo3D = useCallback(async () => {
    const playerMonogramDiv_2D = document.getElementById(TK_PLAYER_DIV_ID_2D);
    const playerMonogramDiv_3D = document.getElementById(TK_PLAYER_DIV_ID_3D);
    const playerMonogramDiv2_3D = document.getElementById('player-3d');

    if (playerMonogramDiv2_3D && playerMonogramDiv_3D && playerMonogramDiv_2D) {
      const canvas_3D = playerMonogramDiv2_3D.querySelector('canvas');
      playerMonogramDiv_2D.style.display = 'none';
      playerMonogramDiv2_3D.style.display = 'block';
      playerMonogramDiv2_3D.style.height = '100%';
      playerMonogramDiv_3D.style.display = 'block';
      if (playerMonogramDiv2_3D?.querySelector('canvas')?.style.width === '1px') {
        dispatch(setPlayerLoading(true));
      }
      await window.threekit.player3DPromise;
      const configuration = window.configurator?.getFullConfiguration();
      const monogramConfiguration = structuredClone(configuration);
      monogramConfiguration.Camera = {
        assetId: patchCameraId,
        type: 'item',
      };
      monogramConfiguration['Model Presentation'] = 'Open';
      monogramConfiguration['Rotate Model'] = 0;
      window.configuratorMonogram?.setFullConfiguration(monogramConfiguration);

      // setTimeout(() => dispatch(setPlayerLoading(false)));
    }
  }, [dispatch]);

  const update3DState = useCallback(async () => {
    const configuration = window.configurator?.getFullConfiguration();
    const monogramConfiguration = structuredClone(configuration);
    monogramConfiguration.Camera = {
      assetId: patchCameraId,
      type: 'item',
    };
    monogramConfiguration['Model Presentation'] = 'Open';
    monogramConfiguration['Rotate Model'] = 0;
    window.configuratorMonogram?.setFullConfiguration(monogramConfiguration);
    if (socket) {
      emit({
        action: 'updateProductData',
        data: JSON.stringify([{ monogramConfiguration }]),
      });
    }
  }, [roomId, socket]);

  useEffect(() => {
    if (currentMode === '3D' && homePage) {
      switchTo3D();
      update3DState();
    } else {
      const playerMonogramDiv_2D = document.getElementById(TK_PLAYER_DIV_ID_2D);
      const playerMonogramDiv_3D = document.getElementById(TK_PLAYER_DIV_ID_3D);
      const playerMonogramDiv2_3D = document.getElementById('player-3d');

      if (playerMonogramDiv2_3D && playerMonogramDiv_3D && playerMonogramDiv_2D) {
        dispatch(setPlayerLoading(false));
        playerMonogramDiv_3D.style.display = 'none';
        playerMonogramDiv2_3D.style.display = 'none';
        playerMonogramDiv_2D.style.display = 'block';
      }
    }
  }, [configurationToClone, currentMode, playerMode, step, homePage, switchTo3D, dispatch, update3DState]);

  return (
    <Wrapper
      className={newClassName}
      height={height}
      width={width}
      border={border}
      isRotable={isRotable}
      conditionalCSS={cssDisplay}
      ref={playerRef}
      fullScreen={isFullScreen}
    >
      <div
        id={TK_PLAYER_DIV_ID_2D}
        onDoubleClick={handleFullScreen}
        onMouseDown={(e) => handleTouchStart(e)}
        onMouseUp={(e) => handleTouchEnd(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={(e) => {
          handleTouchEnd(e);
          handleDoubleTap(e);
        }}
      />
      <div
        id={TK_PLAYER_DIV_ID_3D}
        onDoubleClick={handleFullScreen}
        onMouseDown={(e) => handleTouchStart(e)}
        onMouseUp={(e) => handleTouchEnd(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={(e) => {
          handleTouchEnd(e);
          handleDoubleTap(e);
        }}
      />
      {children}

      {!isMobile && isLoaded && (
        <OptionsButton buttonName={ZOOM_BUTTON_LABEL} fnButton={handleFullScreen} isFullScreen={isFullScreen} />
      )}
      {isFullScreen && toggleButton && <OptionsButton buttonName={INFO_ZOOM_BUTTON_LABEL} />}
      <RelativeModal
        id="frozen"
        opacity={isFrozen && !isExpireModalShowing ? 1 : 0}
        isOpen={isFrozen && !isExpireModalShowing}
        onClose={() => {}}
        closeButton={false}
      >
        <Frozen />
      </RelativeModal>
    </Wrapper>
  );
};

export default Player2D;
