import { FC, useEffect, useState } from 'react';
import Router from './router/Router';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import useWindowSize from './utils/threekitHooks/useWindowSize';
import { launch, setIsChina } from './store/threekitSlicer';
import useParams from './hooks/useParams';
import { AwaitPlayerLoad } from './components';
import { setGlobalSettingsParams } from './store/globalSettingsSlicer';
import { getParams } from './utils/function/navigationParams';
import { isRightToLeft } from './utils/function/functions';
import { waitForDataDrivenConfigurator } from './utils/function/dataDrivenFn';
import { setServerSocket, setServerSocketPath, setBaseUrlClientRemoteViewer, setHideRemoteViewer, setHubName } from './store/liveSessionSlicer';

const App: FC<any> = () => {
  const { isIpad, isDesktop, isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const params = getParams();
  const { sku, lng, appName = 'catalogios' } = params || {};
  const [isDatadrivenReady, setDatadrivenReady] = useState(false);
  const { newParams: config, isChina, isLoading, error } = useParams();

  useEffect(() => {
    if (isLoading || error) return;
    dispatch(setHideRemoteViewer(config.hideRemoteViewer));
    dispatch(setIsChina(isChina));
    dispatch(setGlobalSettingsParams(params));
    dispatch(setBaseUrlClientRemoteViewer(config?.baseUrlClientRemoteViewer));
    dispatch(setHubName(config.hubName));
    dispatch(setServerSocket(config.defaultServerSocket));
    dispatch(setServerSocketPath(config.defaultServerSocketPath));
    dispatch<any>(launch(config));
  }, [dispatch, config, isChina, isLoading, error]);

  useEffect(() => {
    const isRtl = isRightToLeft();
    const dir = isRtl ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
  }, [lng]);

  useEffect(() => {
    const dataDrivenLaunched = async () => {
      window.dataDrivenConfiguratorInitialSku = sku;
      await waitForDataDrivenConfigurator();
      setDatadrivenReady(true);
    };
    dataDrivenLaunched();
  }, [sku]);

  const theme = {
    device: { isIpad, isDesktop, isMobile },
    appName: appName?.toLowerCase() || 'catalogios',
  };

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ margin: '20px' }}>{error}</div>
      </ThemeProvider>
    );
  }

  if (isLoading || !isDatadrivenReady) {
    return (
      <ThemeProvider theme={theme}>
        <AwaitPlayerLoad />
      </ThemeProvider>
    );
  }

  return isDatadrivenReady ? (
    <ThemeProvider theme={theme}>
      <AwaitPlayerLoad>
        <Router />
      </AwaitPlayerLoad>
    </ThemeProvider>
  ) : null;
};

export default App;
