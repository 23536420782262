import { createSlice } from '@reduxjs/toolkit';
import * as configurationReducers from './reducers';

interface PlayerSize {
  width: number;
  height: number;
}

interface DataDrivenConfiguratorExtensionStatus {
  validAttributes: string[];
  sku: string;
}

export interface SettingsState {
  config?: Record<string, any>;
  isLoading: boolean;
  isThreekitLoaded: boolean;
  isPlayerLoading: boolean;
  language?: string;
  allowInPlayerReorder: boolean;
  allowInPlayerSelect: boolean;
  isClient: boolean;
  isChina?: boolean;
  playerSize: PlayerSize;
}
export interface IAttribute {
  name: string;
  [key: string]: any;
}
export interface IForm {
  [key: string]: {
    [key: string]: IAttribute;
  };
}

export interface ConfigurationState {
  product?: Record<string, any>;
  initialConfiguration?: Record<string, any>;
  form?: IForm;
  attributes?: Record<string, any>;
  dataDrivenConfiguratorExtensionStatus: DataDrivenConfiguratorExtensionStatus;
  formValidAttributes: Record<string, any>;
  price?: any;
  metadata?: Record<string, any>;
  name?: string;
  activeAttribute?: any;
  dependencies?: any;
}

type Page = 'home' | 'summary';

export interface ProductPageState {
  displaySize: boolean;
  placeHolderError: boolean;
  newSize: string;
  page: Page;
  charactRest: string;
  currentEngraving: string;
  playerFullScreen: boolean;
  baseUrlClient: string;
  currentIndex: string;
  displayAutofocus: boolean;
  player3DStep: number;
  hasPatch: boolean;
  isFullScreen: boolean;
  isRotatable: boolean;
  player3DImage: string;
  currentSlideActive: boolean;
  sizeAutofocus: boolean;
  toogle: Record<number, boolean>;
  isOpen: boolean;
  previousCamera: string;
  lastAngle: number;
  totalEngraving: string;
  toggleSlider: boolean;
}

export interface SavedConfigurationData {
  id: string;
  productId: string;
  productVersion: string;
  variant: Record<string, any>;
  metadata: Record<string, any>;
  createdAt: string;
  shortId: string;
  orgId: string;
  thumbnail: string;
  customerId: string;
  scope: string;
  identifier: string;
  attachments: Record<string, any>;
  sceneGraphState: Record<string, any>;
  resumableUrl: string;
  thumbnailUrls: string[];
  inStore?: boolean;
}

export interface ThreekitState {
  settings: SettingsState;
  configuration: ConfigurationState;
  currentMode: '2D' | '3D';
  viewUpdate: boolean;
  rotationUpdate: boolean;

  productPage: ProductPageState;
  savedConfigurationData: SavedConfigurationData;
  player?: any;
  modelView: string;
  currentModelViewKey: number;
  currentModelViewInfos: Record<string, any>;
}

const initialState: ThreekitState = {
  settings: {
    config: undefined,
    isLoading: false,
    isThreekitLoaded: false,
    isPlayerLoading: false,
    language: undefined,
    allowInPlayerReorder: true,
    allowInPlayerSelect: true,
    isClient: false,
    isChina: undefined,
    playerSize: { width: 0, height: 0 },
  },
  configuration: {
    product: undefined,
    initialConfiguration: undefined,
    form: undefined,
    attributes: undefined,
    dataDrivenConfiguratorExtensionStatus: { validAttributes: [], sku: '' },
    formValidAttributes: {},
    price: undefined,
    metadata: undefined,
    name: undefined,
    activeAttribute: undefined,
    dependencies: undefined,
  },
  currentMode: '2D',
  viewUpdate: false,
  rotationUpdate: false,
  modelView: 'Closed',
  currentModelViewInfos: {},
  currentModelViewKey: 0,
  productPage: {
    displaySize: false,
    placeHolderError: false,
    newSize: '',
    page: 'home',
    charactRest: '',
    currentEngraving: '',
    playerFullScreen: false,
    baseUrlClient: '',
    currentIndex: '',
    displayAutofocus: false,
    currentSlideActive: false,
    sizeAutofocus: true,
    toogle: { 1: false, 2: false },
    isOpen: false,
    previousCamera: '',
    lastAngle: 0,
    totalEngraving: '',
    toggleSlider: false,
    player3DImage: '',
    player3DStep: 0,
    hasPatch: false,
    isFullScreen: false,
    isRotatable: false,
  },
  savedConfigurationData: {
    id: '',
    productId: '',
    productVersion: '',
    variant: {},
    metadata: {},
    createdAt: '',
    shortId: '',
    orgId: '',
    thumbnail: '',
    customerId: '',
    scope: '',
    identifier: '',
    attachments: {},
    sceneGraphState: {},
    resumableUrl: '',
    thumbnailUrls: [],
  },
  player: undefined,
};

export const threekitSlice = createSlice({
  name: 'threekit',
  initialState,
  reducers: {
    ...configurationReducers.configurationReducers,
    ...configurationReducers.currentModeReducers,
    ...configurationReducers.playerReducers,
    ...configurationReducers.productPage2DReducers,
    ...configurationReducers.productPage3DReducers,
    ...configurationReducers.settingsReducers,
    ...configurationReducers.savedConfigurationDataReducers,
    ...configurationReducers.viewUpdateReducers,
  },
});

export const {
  setConfig,
  setInitialConfiguration,
  setLoading,
  setThreekitLoaded,
  setPlayerLoading,
  setIsClient,
  setIsChina,
  setLanguageState,
  setAllowInPlayerReorder,
  setAllowInPlayerSelect,

  setProduct,
  setForm,
  setAttributes,
  setValidAttributes,
  setSku,
  setFormValidAttributes,
  setMetadata,
  setPrice,
  setName,
  setActiveAttribute,
  setDependencies,
  setPhase,
  setPlayerConfiguration,
  setTools,
  setPlayerSettings,
  setPlayerSize,
  setCurrentMode,
  setCurrentModelView,
  setCurrentModelViewKey,
  setCurrentModelViewInfos,
  setPlayer3DImage,
  setPlayer3DStep,
  setHasPatch,
  setIsFullScreen,
  setIsRotatable,
  setViewUpdate,
  setRotationUpdate,

  // Player 3D
  setDisplaySize,
  setPlaceHolderError,
  setNewSize,
  setPage,
  setCharactRest,
  setCurrentEngraving,
  setPlayerFullScreen,
  setBaseUrlClient,
  setCurrentIndex,
  setDisplayAutofocus,
  setCurrentSlide,
  setSizeAutofocus,

  // Player 2D
  setToogle,
  setToogle2,
  setIsOpen,
  setPreviousCamera,
  setLastAngle,
  setTotalEngraving,
  setToggleSlider,

  // Saved Configuration Data
  setSavedConfigurationData,
} = threekitSlice.actions;

export default threekitSlice.reducer;

export * from './reducers';
export * from './selectors';
