import styled from 'styled-components';
import { COLORS } from '../../../components/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px, 50px, 10px, 50px;
  align-items: center;
`;

export const Message = styled.div`
  text-align: center;
  color: ${COLORS.mediumGray};
  font-family: LouisVuitton_Regular;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;
