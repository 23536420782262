import { useEffect, useMemo, useState } from 'react';
import useLeadTime from '../useLeadTime';
import usePrice from '../usePrice';
import useStock from '../useStock';
import { PopUpType, showPopUp } from '../../components/PopUp/ShowPopUp';
import { popUpTypes } from '../../components/PopUp/PopUpType';

export const useNotOrderable = (): {
  isNotOrderable: boolean;
  notOrderableError: string;
} => {
  const [isTesting, setIsTesting] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const input = event.key.toUpperCase();
      if (input === 'T') {
        let sequence = 'T';

        const keyListener = (e: KeyboardEvent) => {
          sequence += e.key.toUpperCase();
          if (sequence === 'TESTING') {
            setIsTesting(true);
            showPopUp({ popUpType: popUpTypes.TestingModeUnlocked as PopUpType });
            window.removeEventListener('keypress', keyListener);
          } else if (!'TESTING'.startsWith(sequence)) {
            sequence = ''; 
          }
        };

        window.addEventListener('keypress', keyListener);
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const {
    priceValue,
    isLoading: priceIsLoading,
    isError: priceIsError,
    error: priceError,
  } = usePrice();
  const {
    leadTime,
    isLoading: leadTimeIsLoading,
    isError: leadTimeIsError,
    error: leadTimeError,
  } = useLeadTime();
  const {
    data: stockQuantity,
    isLoading: stockIsLoading,
    isError: stockIsError,
    error: stockError,
  } = useStock();
  const minLeadTime = leadTime?.min;
  const maxLeadTime = leadTime?.max;

  const isNotOrderable = useMemo(() => {
    if (isTesting) {
      return false;
    }

    return (
      ((stockQuantity === undefined ||
        typeof stockQuantity !== 'number' ||
        stockQuantity < 1) &&
        (minLeadTime === undefined || minLeadTime === null) &&
        (maxLeadTime === undefined || maxLeadTime === null)) ||
      !priceValue ||
      priceIsError ||
      (leadTimeIsError && stockIsError) ||
      priceIsLoading ||
      leadTimeIsLoading ||
      stockIsLoading
    );
  }, [
    stockQuantity,
    priceValue,
    priceIsError,
    leadTimeIsError,
    stockIsError,
    priceIsLoading,
    leadTimeIsLoading,
    stockIsLoading,
    minLeadTime,
    maxLeadTime,
    isTesting
  ]);

  const notOrderableError = `${priceError ? priceError + ' ' : ''}${
    leadTimeError ? leadTimeError + ' ' : ''
  }${stockError ? stockError : ''}`.trim();

  return { isNotOrderable, notOrderableError };
};

export default useNotOrderable;
