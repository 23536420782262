import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as liveSessionReducers from './reducers';

export interface LiveSessionState {
  isSessionLive: boolean;
  hideRemoteViewer: boolean;
  serverSocket: string;
  serverSocketPath: string;
  hubName: string;
  baseUrlClientRemoteViewer: string;
  roomId: string;
  lastConfig: Record<string, any>;
  rotationUpdates: Record<string, any>[];
  isFrozen: boolean;
  isInvalidCode: boolean;
  closingDate: Date | undefined;
  urlCopied: boolean;
  code: string;
  isExpireModalShowing: boolean;
  roomClosedDueToInactivity: boolean;
  isSharingModalOpen: boolean;
}

const initialState: LiveSessionState = {
  isSessionLive: false,
  hideRemoteViewer: false,
  serverSocket: '',
  serverSocketPath: '',
  hubName: '',
  baseUrlClientRemoteViewer: '',
  roomId: '',
  lastConfig: {},
  rotationUpdates: [],
  isFrozen: false,
  isInvalidCode: false,
  urlCopied: false,
  closingDate: undefined,
  code: '',
  isExpireModalShowing: false,
  roomClosedDueToInactivity: false,
  isSharingModalOpen: false,
};

type LiveSessionSlice = {
  [K in keyof typeof liveSessionReducers]: (
    state: LiveSessionState,
    action: PayloadAction<any>
  ) => void;
};

const liveSessionSlice = createSlice({
  name: 'liveSessionSlice',
  initialState,
  reducers: liveSessionReducers as LiveSessionSlice,
});

export const {
  setServerSocket,
  setServerSocketPath,
  setIsSessionLive,
  setRoomId,
  setIsSharingModalOpen,
  setBaseUrlClientRemoteViewer,
  setRoomClosedDueToInactivity,
  setIsExpireModalShowing,
  setClosingDate,
  setHubName,
  setLastConfig,
  setRotationUpdates,
  setIsFrozen,
  setHideRemoteViewer,
  setIsInvalidCode,
  setUrlCopied,
  resetLiveSession,
  addRotateModelUpdate,
  emptyRotateModelUpdates,
} = liveSessionSlice.actions;

export default liveSessionSlice.reducer;

export * from './reducers';
export * from './selectors';
