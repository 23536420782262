import { useWindowSize } from '../../../utils/threekitHooks';
import MobileForm from './MobileForm';
import RegularForm from './RegularForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRoomId,
  setIsSharingModalOpen,
  setRoomId,
} from '../../../store/liveSessionSlicer';
import { randomCode } from '../../../utils/RandomCode';

function Configurator() {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const roomId = useSelector(getRoomId);

  const handleShareScreenClick = () => {
    dispatch(setIsSharingModalOpen(true));
    if (!roomId) {
      const randomRoomId = randomCode();
      dispatch(setRoomId(randomRoomId));
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileForm handleShareScreenClick={handleShareScreenClick} />
      ) : (
        <RegularForm handleShareScreenClick={handleShareScreenClick} />
      )}
    </>
  );
}

export default Configurator;
