import styled, { css, keyframes } from 'styled-components';
import {
  ADDTOCART_BUTTON_LABEL,
  CATALOGDESKTOP_APPNAME,
  CATALOGWECOM_APPNAME,
  EDIT_BUTTON_LABEL,
  FINISH_BUTTON_LABEL,
  INFO_ZOOM_BUTTON_LABEL,
  INSTOCK_BUTTON_LABEL,
  LEADTIME_BUTTON_LABEL,
  OOB_APPNAME,
  QRCODE_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  SHARE_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING,
  START_SHARING_BUTTON_LABEL,
  STOP_SHARING_BUTTON_LABEL,
  ZOOM_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL_DISABLED,
  CONTINUE_BUTTON_LABEL,
  SHARE_PRODUCT_LABEL,
  KEEP_SHARING_BUTTON_LABEL,
} from '../../../utils/constants';
import { COLORS } from '../../../pages/components/Colors';

interface IDevice {
  isIpad?: boolean;
  isDesktop?: boolean;
  isMobile?: boolean;
  appName?: string;
  isFullScreen?: boolean;
}

const FONT_SIZES = {
  small: '14px',
  medium: '17px',
};

const baseButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  color: ${COLORS.black};
  font-size: ${FONT_SIZES.small};
  font-weight: 400;
  background-color: ${COLORS.white};
  padding: 10px 13px;
  font-family: LouisVuitton_Regular;
`;

const buttonInteractionStyles = css`
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:active {
    transform: scale(0.99);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
  }
`;

const disabledStyle = css`
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
`;

const responsivePaddingStyle = ({ isIpad, isDesktop }: IDevice) => css`
  ${isIpad || isDesktop ? 'padding: 10px 20px;' : ''}
`;

const addtoCartButtonStyle = ({ isMobile, isIpad, isDesktop, appName }: IDevice) => css`
  background-color: ${COLORS.darkGray};
  border: none;
  color: ${COLORS.white};
  flex: 1;
  height: 60px;
  padding: 16px 24px;
  border-radius: 15px;
  font-size: ${FONT_SIZES.medium};
  line-height: 1.4rem;

  > img {
    width: 20px;
  }

  ${isMobile ? 'box-shadow: 0 0 60px 0 #0000004D; border: none;' : ''}
  ${isIpad || isDesktop ? 'border-radius: 30px;' : ''}
  ${appName === CATALOGWECOM_APPNAME ? 'border-radius: 8px; box-shadow: 0px 0px 26px 0px #00000033;' : ''}
  ${appName === OOB_APPNAME ? 'background-color: #18110C;' : ''}
`;

const shareButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border: 1px solid ${COLORS.lightGray};
  border-radius: 100px;
  padding: 10px 20px;
  margin-left: 10px;
  > img {
    width: 25px;
  }

  ${!isMobile
    ? `
    > img {
      width: 20px;
    }
  `
    : ''}

  ${appName === OOB_APPNAME && isDesktop ? 'border: none; padding: 0;' : ''}
`;

const finishButtonStyle = ({ isMobile }: IDevice) => css`
  justify-content: center;
  font-size: 13px;
  border: none;
  width: 50%;
  height: 100%;
  text-transform: uppercase;

  ${!isMobile &&
  css`
    background-color: ${COLORS.darkBrown};
    color: ${COLORS.white};
    border-radius: 70px;
    width: 100%;
    font-size: ${FONT_SIZES.small};
    padding: 15px 40px;
    text-transform: none;
  `}
`;

const inStockButtonStyle = ({ isMobile }: IDevice) => css`
  > img {
    width: 20px;
  }
  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  padding: 10px;
  cursor: initial;
  width: max-content;

  ${!isMobile ? 'padding: 10px 20px;' : ''}
`;

interface IDeviceWithSummaryPage extends IDevice {
  summaryPage: boolean;
}

const leadTimeButtonStyle = ({ isMobile, summaryPage }: IDeviceWithSummaryPage) => css`
  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  padding: 10px;
  cursor: initial;
  width: max-content;
  max-width: 40vw;
  margin-right: 10px;

  ${!isMobile ? 'padding: 10px 20px; margin-right: 0px;' : ''}

  ${summaryPage
    ? css`
        border: none;
        background-color: ${COLORS.ivory};
        border-radius: 35px;
        padding: 6px 13px;

        ${!isMobile ? 'padding: 10px 20px; border-radius: 100px;' : ''}
      `
    : ''}
`;

const continueButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  width: 100%;

  height: 58px;
  margin-top: 20px;
  padding: 20;
  background-color: ${COLORS.black};
  border: 1px solid black;
  color: ${COLORS.white};
  border-radius: 100px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:active {
    transform: scale(0.98);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    background-color: ${COLORS.darkGray};
  }
`;

const resetButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  height: 40px;
  min-width: 40px;
  margin-left: 10px;
  > img {
    width: 15px;
  }
  ${isMobile &&
  css`
    padding: 8px;
    > img {
      width: 18px;
    }
  `}

  ${isMobile
    ? 'position: absolute; top: 2vmin; [dir = "ltr"] &{right: 10px;} [dir = "rtl"] & {left: 2vmin;}'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : 'border: 1px solid ${COLORS.lightGray};'}
`;

const shareScreenButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  height: 40px;
  margin-left: 5px;

  > img {
    width: 15px;
  }
  ${isMobile &&
  css`
    padding: 8px;
    > img {
      width: 18px;
    }
  `}

  ${isMobile
    ? 'position: absolute; top: 2vmin; [dir = "ltr"] &{right: 10px;} [dir = "rtl"] & {left: 2vmin;}'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : 'border: 1px solid ${COLORS.lightGray};'}
`;

const linkCopiedButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border-radius: 100px;
  background-color: ${COLORS.ivory};
  color: black;
  border: 1px solid ${COLORS.lightGray};
  > img {
    width: 15px;
  }
  ${isMobile &&
  css`
    padding: 8px;
    > img {
      width: 18px;
    }
  `}

  ${isMobile
    ? 'position: absolute; top: 2vmin; [dir = "ltr"] &{right: 10px;} [dir = "rtl"] & {left: 2vmin;}'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : 'border: 1px solid ${COLORS.lightGray};'}
`;

const keepSharingButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border-radius: 100px;
  width: 75%;
  background-color: ${COLORS.white};
  font-family: LouisVuitton_Medium;
  color: ${COLORS.black};
  border: 1px solid ${COLORS.black};
  padding: 20px;
  height: 60px;

  ${isMobile &&
  css`
    padding: 8px;
  `}
  ${isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : `border: 1px solid ${COLORS.lightGray};`}
`;

const StopSharingButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border-radius: 100px;
  width: 75%;
  background-color: ${COLORS.white};
  font-family: LouisVuitton_Medium;
  color: ${COLORS.scarlet};
  border: 1px solid ${COLORS.scarlet};
  padding: 20px;
  height: 60px;
  > img {
    width: 18px;
  }
  ${isMobile &&
  css`
    padding: 8px;
    > img {
      width: 18px;
    }
  `}

  ${buttonInteractionStyles} 

  ${isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : `border: 1px solid ${COLORS.lightGray};`}
`;



const sharingButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border-radius: 100px;
  background-color: ${COLORS.white};
  color: black;
  border: 1px solid ${COLORS.scarlet};
  > img {
    width: 10px;
    animation: fadeOpacity 2s infinite;
  }
  ${isMobile &&
  css`
    padding: 8px;
    > img {
      width: 18px;
    }
  `}

  ${isMobile
    ? 'position: absolute; top: 2vmin; [dir = "ltr"] &{right: 10px;} [dir = "rtl"] & {left: 2vmin;}'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : 'border: 1px solid ${COLORS.lightGray};'}

@keyframes fadeOpacity {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.05;
    }
  }
`;

const zoomButtonStyle = ({ isDesktop, appName, isMobile, isFullScreen }: IDevice) => css`
  position: ${isMobile && !isFullScreen ? '' : 'absolute'};
  bottom: ${isFullScreen && isMobile ? '' : isFullScreen && !isMobile ? '3vmin' : '18vmin'};
  top: ${isFullScreen && isMobile ? '3vmin' : ''};
  z-index: ${isFullScreen ? 99999999 : ''};
  right: 3vmin;
  padding: 7px;

  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  > img {
    width: 20px;
  }

  ${isMobile
    ? 'margin-left: 10px; width: 44px;'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : `border: 1px solid ${COLORS.lightGray};`}
`;

const mobileSharingButtonStyle = ({ isDesktop, appName, isMobile, isFullScreen }: IDevice) => css`
  position: ${isMobile && !isFullScreen ? '' : 'absolute'};
  bottom: ${isFullScreen && isMobile ? '' : isFullScreen && !isMobile ? '3vmin' : '18vmin'};
  top: ${isFullScreen && isMobile ? '3vmin' : ''};
  z-index: ${isFullScreen ? 99999999 : ''};
  right: 3vmin;
  padding: 12px;

  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  > img {
    width: 20px;
  }

  ${isMobile
    ? 'margin-left: 5px;  width: max-content;'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : `border: 1px solid ${COLORS.lightGray};`}
`;

const startSharingButtonStyle = ({}: IDevice) => css`
  padding: 7px;
  width: calc(100% - 40px);
  margin: 0 20px;
  border-radius: 50px;
  font-size: 15px;
  height: 60px;
  background-color: ${COLORS.darkGray};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
  display: flex; /* Added to display images side by side */
  align-items: center;

  ${buttonInteractionStyles} 
`;

const shareUrlModalButton = ({}: IDevice) => css`
  padding: 7px;
  width: calc(100% - 40px);
  margin: 10px 20px;
  border-radius: 50px;
  font-size: 15px;
  height: 60px;
  background-color: ${COLORS.darkGray};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
  display: flex;
  align-items: center;
  ${buttonInteractionStyles} 

`;

const shareUrlModalButtonDisabled = ({}: IDevice) => css`
  padding: 7px;
  width: calc(100% - 40px);
  margin: 10px 20px;
  border-radius: 50px;
  font-size: 15px;
  height: 60px;
  background-color: ${COLORS.ivory};
  color: ${COLORS.black};
  border: 1px solid ${COLORS.lightGray};
  display: flex;
  align-items: center;
`;

const qrCodeButtonStyle = ({ isDesktop }: IDevice) => css`
  padding: 7px;
  width: 70px;
  padding: 10px;
  border-radius: 50px;
  margin-right: 20px;
  height: 60px;
  /* background-color: ${COLORS.darkGray}; */
  /* color: ${COLORS.white}; */
  border: 1px solid ${COLORS.lightGray};
  display: flex; /* Added to display images side by side */
  align-items: center;
  justify-content: center;

  ${buttonInteractionStyles} 

  > img {
    width: 100%;
    height: 100%;
  }
`;

const mobileSharingButtonStyleWide = ({ isDesktop, appName, isMobile, isFullScreen }: IDevice) => css`
  position: ${isMobile && !isFullScreen ? '' : 'absolute'};
  bottom: ${isFullScreen && isMobile ? '' : isFullScreen && !isMobile ? '3vmin' : '18vmin'};
  top: ${isFullScreen && isMobile ? '3vmin' : ''};
  z-index: ${isFullScreen ? 99999999 : ''};
  right: 3vmin;
  padding: 7px;

  border-radius: 50px;
  border: 1px solid ${COLORS.lightGray};
  display: flex; /* Added to display images side by side */
  align-items: center;
  gap: 2px; /* Added to create space between the images */

  > img:first-of-type {
    width: 10px;
    animation: fadeOpacity 2s infinite;
  }

  > img:last-of-type {
    width: 20px;
  }

  @keyframes fadeOpacity {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.05;
    }
  }

  ${isMobile
    ? 'margin-left: 5px;  width: max-content;'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : `border: 1px solid ${COLORS.lightGray};`}
`;

const infoZoomButtonStyle = ({ isDesktop, appName, isMobile }: IDevice) => css`
  position: absolute;
  bottom: 10%;
  left: 50%;
  cursor: initial;
  z-index: 99999999;
  padding: 20px;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  transform: translate(-50%, -50%);
  border-radius: 100px;
  border: 1px dashed ${COLORS.lightGray};
  > img {
    width: 20px;
  }

  ${isMobile
    ? 'margin-left: auto;'
    : isDesktop && appName === 'OOB_APPNAME'
    ? 'border: none; flex-direction: row-reverse;'
    : `border: 1px solid ${COLORS.lightGray};`}
`;

const editButtonStyle = ({ isMobile, isIpad, isDesktop, appName }: IDevice) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
  height: 60px;
  padding: 16px 24px;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  font-family: LouisVuitton_Regular;
  font-size: 17px; // Overriding the base font size
  font-weight: 400;
  font-feature-settings: 'case' on;
  line-height: 1.4rem;

  > img {
    width: 20px;
  }
  > p {
    font-size: 17px;
    font-weight: 400;
    font-feature-settings: 'case' on;
    line-height: 1.4rem;
  }

  ${isMobile ? 'box-shadow: 0 0 60px 0 #0000004D; border: none;' : ''}
  ${isIpad || isDesktop ? 'border-radius: 30px;' : ''}
  ${appName === CATALOGWECOM_APPNAME ? 'border-radius: 8px; box-shadow: 0px 0px 26px 0px #00000033;' : ''}
  ${appName === CATALOGDESKTOP_APPNAME && !isMobile && (isIpad ? 'width: 90%; margin: auto;' : 'width: 100%;')}
  ${!isMobile && (isIpad || isDesktop) ? 'border: 1px solid #E6E6E6;' : ''}
`;

const noButtonStyle = () => css`
  padding: 7px;
  width: 45%;
  border-radius: 50px;
  font-size: 15px;
  height: 60px;
  background-color: ${COLORS.darkGray};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
`;

const yesButtonStyle = () => css`
  padding: 7px;
  width: 45%;
  border-radius: 50px;
  font-size: 15px;
  height: 60px;
  color: ${COLORS.darkGray};
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
`;

interface ButtonContainerProps {
  theme?: {
    device: { isMobile: boolean; isIpad: boolean; isDesktop: boolean };
    appName: string;
  };
  buttonName?: string;
  summaryPage?: boolean;
  copied?: boolean | undefined;
  sharing?: boolean;
  clientPage?: boolean;
  disabled?: boolean;
  isFullScreen?: boolean;
}

export const ButtonContainer = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['buttonName', 'summaryPage', 'clientPage', 'disabled', 'isFullScreen', 'copied', 'sharing'].includes(prop),
})<ButtonContainerProps>(
  ({
    theme,
    buttonName,
    summaryPage,
    copied = false,
    sharing = false,
    disabled,
    isFullScreen,
  }: ButtonContainerProps) => css`
    ${baseButtonStyle}
    ${disabled && disabledStyle}
    ${responsivePaddingStyle(theme?.device as IDevice)}
    ${buttonName === ADDTOCART_BUTTON_LABEL && addtoCartButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === SHARE_BUTTON_LABEL && shareButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === SHARE_PRODUCT_LABEL && shareButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === FINISH_BUTTON_LABEL && finishButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === INSTOCK_BUTTON_LABEL && inStockButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === LEADTIME_BUTTON_LABEL &&
    leadTimeButtonStyle({
      ...theme?.device,
      appName: theme?.appName,
      summaryPage: Boolean(summaryPage),
    })}
    ${buttonName === RESET_BUTTON_LABEL && resetButtonStyle({ ...theme?.device, appName: theme?.appName })}
     ${buttonName === SHARE_SCREEN_BUTTON_LABEL &&
    shareScreenButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === SHARE_SCREEN_BUTTON_LABEL &&
    copied &&
    linkCopiedButtonStyle({ ...theme?.device, appName: theme?.appName })}

${buttonName === CONTINUE_BUTTON_LABEL && continueButtonStyle({ ...theme?.device, appName: theme?.appName })}

  ${buttonName === STOP_SHARING_BUTTON_LABEL && StopSharingButtonStyle({ ...theme?.device, appName: theme?.appName })}
${buttonName === KEEP_SHARING_BUTTON_LABEL && keepSharingButtonStyle({ ...theme?.device, appName: theme?.appName })}

     ${buttonName === SHARE_SCREEN_BUTTON_LABEL &&
    sharing &&
    sharingButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === ZOOM_BUTTON_LABEL &&
    zoomButtonStyle({
      ...theme?.device,
      appName: theme?.appName,
      isFullScreen,
    })}
     ${buttonName === SHARE_SCREEN_BUTTON_LABEL_MOBILE &&
    mobileSharingButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING &&
    mobileSharingButtonStyleWide({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === START_SHARING_BUTTON_LABEL &&
    startSharingButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === COPIED_URL_MODAL_BUTTON_LABEL &&
    shareUrlModalButton({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === COPIED_URL_MODAL_BUTTON_LABEL_DISABLED &&
    shareUrlModalButtonDisabled({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === INFO_ZOOM_BUTTON_LABEL && infoZoomButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === EDIT_BUTTON_LABEL && editButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === QRCODE_BUTTON_LABEL && qrCodeButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === 'yes' && yesButtonStyle()}
    ${buttonName === 'no' && noButtonStyle()}
  `
);

export const LabelBold = styled.span(() => {
  return `
  font-weight: 600;
`;
});

export const ButtonContainerShimmer = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => {
    return `
    max-width: 33vw;
  ${
    isMobile
      ? `
  border-radius: 30px;
  padding: 18px 36px;
`
      : `
  border-radius: 100px;
  padding: 17px 45px;
`
  }

  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  animation: shimmer 20s infinite linear;

  @keyframes shimmer {
    0% {
      background-position: -80vw 0;
    }
    100% {
      background-position: 80vw 0;
    }
  }

`;
  }
);

export const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
`;

interface IDot {
  delay: string;
}

export const Dot = styled.div<IDot>(
  ({
    theme: {
      device: { isMobile },
    },
    delay,
  }) => css`
    height: 2px;
    width: 2px;
    margin: 4px 5px;
    background-color: ${isMobile ? COLORS.black : COLORS.white};
    border-radius: 50%;
    animation: ${bounce} 1.5s infinite ease-in-out;
    animation-delay: ${delay};
  `
);

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: max-content;
  > img {
    width: 20px;
  }
  > p {
    width: max-content;
  }
`;

export const LoadingText = styled.span(
  ({
    theme: {
      device: { isMobile },
    },
  }) => css`
    margin-left: 10px;
    font-family: LouisVuitton_Regular;
    align-items: flex-end;
    color: ${isMobile ? COLORS.black : COLORS.white};
  `
);
