import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: LouisVuitton_Regular;
`;

export const DescriptionIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
