import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { useWindowSize } from '../../utils/threekitHooks';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer/selectors';
import OptionsButton from '../Form/OptionsButton';
import StockStatus from '../Form/StockStatus/StockStatus';
import {
  Container,
  ProductSKU,
  InnerContainer,
  ProductName,
  ShareAndLeadtime,
  Wrapper,
  ShareAndShareScreen,
} from './TopSection.styles';
import {
  CATALOGDESKTOP_APPNAME,
  SHARE_PRODUCT_LABEL,
  SHARE_SCREEN_BUTTON_LABEL,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE,
  SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING,
} from '../../utils/constants';
import ProductPrice from '../ProductPrice';
import { useProductName } from '../../hooks';
import {
  getHideRemoteViewer,
  getIsSessionLive,
  getRoomId,
  setIsSharingModalOpen,
  setRoomId,
} from '../../store/liveSessionSlicer';
import { randomCode } from '../../utils/RandomCode';
import { ShareScreen } from '../../pages/HomePage/Home.styles';
import { getIsChina } from '../../store/threekitSlicer';

const TopSection = ({ summaryPage }: any) => {
  const { isMobile } = useWindowSize();
  const isChina = useSelector(getIsChina);
  const hideRemoteViewer = useSelector(getHideRemoteViewer);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName, sku, configId } = globalSettingsParams || {};
  const productNameThreekit = useProductName();
  const isSessionLive = useSelector(getIsSessionLive);

  const dispatch = useDispatch();
  // const { isMobile } = useWindowSize();
  const roomId = useSelector(getRoomId);

  const renderSku = () => (
    <>
      <ProductSKU>{sku}</ProductSKU>
      {appName === CATALOGDESKTOP_APPNAME && sku?.startsWith('P') && (
        <ProductSKU>{configId}</ProductSKU>
      )}
    </>
  );

  const handleShareScreenClick = () => {
    dispatch(setIsSharingModalOpen(true));
    if (!roomId) {
      const randomRoomId = randomCode();
      dispatch(setRoomId(randomRoomId));
    }
  };

  const renderDesktopView = () => (
    <>
      <InnerContainer>
        <Wrapper>{renderSku()}</Wrapper>
        <ProductName>{t(productNameThreekit, productNameThreekit)}</ProductName>
        <ProductPrice />
      </InnerContainer>
      <ShareAndLeadtime>
        <ShareAndShareScreen>
          <div onClick={handleShareScreenClick}>
            {!hideRemoteViewer && (<OptionsButton
              buttonName={SHARE_SCREEN_BUTTON_LABEL}
              summaryPage={summaryPage}
            />)}
          </div>
          <OptionsButton buttonName={SHARE_PRODUCT_LABEL} summaryPage={summaryPage} />
        </ShareAndShareScreen>
        <StockStatus summaryPage />
      </ShareAndLeadtime>
    </>
  );

  const renderMobileView = () => (
    <>
      <ProductSKU>{sku}</ProductSKU>
      <ShareScreen onClick={handleShareScreenClick}>
        {!hideRemoteViewer && (<OptionsButton
          buttonName={
            isSessionLive
              ? SHARE_SCREEN_BUTTON_LABEL_MOBILE_SHARING
              : SHARE_SCREEN_BUTTON_LABEL_MOBILE
          }
          summaryPage={summaryPage}
        />)}
        <OptionsButton buttonName={SHARE_PRODUCT_LABEL} summaryPage={summaryPage} />
      </ShareScreen>

      <ProductName>{t(productNameThreekit, productNameThreekit)}</ProductName>
      <ProductPrice />
      <StockStatus summaryPage />
    </>
  );

  return <Container>{isMobile ? renderMobileView() : renderDesktopView()}</Container>;
};

export default TopSection;
