import { t } from 'i18next';
import { DESCRIPTION_ICON } from '../../../../assets';
import { Container, DescriptionIcon } from './styles';

function Frozen() {
  const frozenText = t('info.client_is_interacting', 'Another user is currently interacting with the product, please wait');
  return (
    <Container>
      <DescriptionIcon
        src={DESCRIPTION_ICON}
        alt={frozenText}
      />
      {frozenText}
    </Container>
  );
}

export default Frozen;
