import { Dispatch } from 'react';

import {
  setAttributes,
  setConfig,
  setForm,
  setLanguageState,
  setMetadata,
  setName,
  setPrice,
  setProduct,
  setThreekitLoaded,
} from '..';
import { setConfiguration } from './configurationReducers';
import { PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import Controller from '../../../controller';
import { DEFAULT_PLAYER_CONFIG } from '../../../utils/constants';
import { AnyAction } from '@reduxjs/toolkit';
import { ThreekitState } from '..';
import { RootState } from 'store';
import { useDispatch } from 'react-redux';

interface LaunchConfig {
  orgId: string;
  threekitEnv: string;
  serverUrl: string;
  language?: string;
  additionalTools?: any;
  threekitProductEnv: string;
  authProductToken: string;
  isChina?: boolean;
  compression?: boolean;
  isLocalHost?: boolean;
  productInfos: any;
  [key: string]: any;
}
interface ProductPayload {
  /* Structure de votre payload */
}

export const playerReducers = {
  setPhase(state: ThreekitState, action: PayloadAction<AnyAction>) {
    if (state.player.phase !== action.payload) {
      state.player.phase = action.payload;
    }
  },

  setPlayerConfiguration(
    state: ThreekitState,
    action: PayloadAction<AnyAction>
  ) {
    state.player.configuration = action.payload;
  },

  setTools(state: ThreekitState, action: PayloadAction<ProductPayload>) {
    state.player.tools = action.payload;
  },

  setPlayerSettings(state: ThreekitState, action: PayloadAction<AnyAction>) {
    state.player.params = action.payload;
  },
};

const LunchWindoConfig = () => {
  type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

  const dispatch = useDispatch();
  const useAppDispatch = useDispatch<AppThunkDispatch>();
  useAppDispatch(
    setConfiguration(window.threekit.configurator.getConfiguration())
  );
};

export const launch =
  (config: LaunchConfig) => async (dispatch: Dispatch<any>) => {
    const launchConfig = Object.assign(
      {},
      DEFAULT_PLAYER_CONFIG,
      Object.keys(DEFAULT_PLAYER_CONFIG).reduce((output, key) => {
        if (config[key] === undefined) return output;
        return Object.assign(output, { [key]: config[key] });
      }, {}),
      {
        orgId: config.orgId,
        threekitEnv: config.threekitEnv,
        serverUrl: config.serverUrl,
        language: config.language,
        additionalTools: config.additionalTools,
        threekitProductEnv: config.threekitProductEnv,
        authProductToken: config.authProductToken,
        isChina: config.isChina,
        compression: config.compression,
        productInfos: config.productInfos,
      }
    );
    await Controller.launch(launchConfig);

    // const imageSlider = new Promise(async (resolve) => {
    //     const main = await window?.threekit?.controller?.snapshot();
    //     const attachments = {main};
    //     let configuration = await window?.threekit?.configurator?.getConfiguration();
    //     const [response, error] = await threekitAPI?.configurations?.save({
    //         assetId: window?.threekit?.player?.assetId,
    //         configuration,
    //         attachments,
    //     });

    //     resolve(response?.attachments);
    // });

    // const productImage = await imageSlider;
    dispatch(setThreekitLoaded(true));
    dispatch(setConfig(window.threekit.controller.getConfig()));
    dispatch(setProduct(window.threekit.controller.getProduct()));
    dispatch(setForm(window.threekit.controller.getForm(config)));
    dispatch(setName(window.threekit.controller.getName()));
    dispatch(setMetadata(window.threekit.configurator.getMetadata()));
    dispatch(setPrice(window.threekit.controller.getPrice()));

    if (config.language) {
      return dispatch(setLanguageState(config.language));
    }

    dispatch(setAttributes(window.threekit.controller.getAttributes()));
  };
