import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer/selectors';
import { fetchStockQuantity } from '../../utils/ApiCalls/ApiCalls';
import { useWindowSize } from '../../utils/threekitHooks';

export const useStock = () => {
  const { isMobile } = useWindowSize();
  const { storeCode, sku } =
    useSelector(getGlobalSettingsParams) || {};

  const queryKey = useMemo(
    () => ['productStock', storeCode, sku],
    [storeCode, sku]
  );

  const {
    data = { totalQuantity: 0 },
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey,
    queryFn: () =>
      fetchStockQuantity({
        storeCode: String(storeCode),
        sku: String(sku),
        isMobile: Boolean(isMobile)
      }),
    staleTime: 300000,
    enabled: !!sku,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return { data, isLoading, isError, error };
};

export default useStock;
