import React, { useState } from 'react';
import { Gradient, ProductImg, ProductImgLoader } from '../SummaryPage.styles';
import { constructImageUrl, MAIN_ATTACHMENT_KEY, PICTURE_FORMAT } from '../../../utils/constants';
import { PICTURE_PLACEHOLDER } from '../../../assets';
import { LVLoader } from '../../../components';
import { useSelector } from 'react-redux';
import { getGradient } from '../../../store/globalSettingsSlicer';
export const ProductImage = ({
  productImages,
  isLoading,
}: {
  productImages: Record<string, any>;
  isLoading: boolean;
}) => {
  const [imageLoaded, setImageLoaded] = useState({
    loaded: false,
    error: false,
  });

  const gradient = useSelector(getGradient);

  const productImg = imageLoaded.error
    ? PICTURE_PLACEHOLDER
    : constructImageUrl(productImages?.[MAIN_ATTACHMENT_KEY], PICTURE_FORMAT)
      ? constructImageUrl(productImages?.[MAIN_ATTACHMENT_KEY], PICTURE_FORMAT)
      : Object.values(productImages)?.[0];

  const loading = isLoading || imageLoaded.loaded;

  return (
    <>
      <Gradient id="gradient" gradient={gradient}>
        <ProductImgLoader id="productImgLoader">
          {loading && <LVLoader />}
          <ProductImg
            imageToDisplay={productImg}
            onAbort={() => setImageLoaded({ ...imageLoaded, error: true })}
            onError={() => setImageLoaded({ ...imageLoaded, error: true })}
            onLoad={() => setImageLoaded({ ...imageLoaded, loaded: true })}
          />
        </ProductImgLoader>
      </Gradient>
    </>
  );
};

export default ProductImage;
