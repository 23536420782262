import { t } from 'i18next';
import { OptionsButton } from '../../../../components';
import { CONTINUE_BUTTON_LABEL } from '../../../../utils/constants';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClosingDate,
  getRoomClosedDueToInactivity,
  getRoomId,
  setIsExpireModalShowing,
  setIsSessionLive,
} from '../../../../store/liveSessionSlicer';
import { useSocket } from '../../../../hooks';
import { Container, Message } from './styles';

function ExpirationWarning() {
  const closingDate = useSelector(getClosingDate);
  const dispatch = useDispatch();
  const { socket } = useSocket();
  const [timeLeft, setTimeLeft] = useState(60);
  const roomClosedDueToInactivity = useSelector(getRoomClosedDueToInactivity);
  const { emit } = useSocket();

  const keepSessionGoing = useCallback(() => {
    const now = new Date();
    if (roomClosedDueToInactivity || (closingDate && closingDate < now)) {
      dispatch(setIsExpireModalShowing(false));
      dispatch(setIsSessionLive(false));
    }

    if (socket) {
      emit({
        action: 'keepRoomOpen',
        data: '',
      });
    }
  }, [socket, emit, roomClosedDueToInactivity, dispatch, closingDate]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    timer = setInterval(() => {
      const currentDate = new Date();
      if (closingDate) {
        const timeDifference = Math.round(
          (closingDate.getTime() - currentDate.getTime()) / 1000
        );

        if (timeDifference > 0) setTimeLeft(timeDifference);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [dispatch, closingDate]);

  const message = roomClosedDueToInactivity ? (
    <Message>
      {t(
        'info.room_closed_inactivity',
        'Your room has been closed due to inactivity, please start another live sharing event.'
      )}
    </Message>
  ) : (
    <>
      <Message>
        {t('info.session_about_to_stop', {
          defaultValue: `The session is about to stop in ${timeLeft} seconds.`,
          timeLeft,
        })}
      </Message>
      <Message>
        {t(
          'info.click_button_to_continue',
          'Please click the button below if you want it to continue'
        )}
      </Message>
    </>
  );

  return (
    <Container>
      {message}
      <OptionsButton
        buttonName={CONTINUE_BUTTON_LABEL}
        fnButton={keepSessionGoing}
        buttonText={CONTINUE_BUTTON_LABEL}
      />
    </Container>
  );
}

export default ExpirationWarning;
