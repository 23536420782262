import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  font-family: LouisVuitton_Regular;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 0px 40px 20px;
  text-align: center;
  width: 60%;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;
