import { Dispatch, SetStateAction } from 'react';
import { IAttribute, setRotationUpdate } from '../../../store/threekitSlicer';
import { setGradientBackgroundToCanvas } from '../../PlayersThreekit/Player2D/GradientBackground';
import { debounce } from '../../../utils/function/debounce';

type Config = { [key: string]: string | number | boolean; };

const generateNumberAttributeValues = (attribute: IAttribute) => {
  const length = (attribute.max + attribute.step) / attribute.step;
  return Array.from({ length }, (_, index) => index * attribute.step);
};

const add2DSpinTool = (
  {
    attributeId,
    direction = 1,
    maxWidth = 0
  }: { attributeId: string; direction?: number; maxWidth?: number; },
  handleLastAngle: (config: Config) => void,
  isRotable: boolean,
  isFullScreen: boolean,
  dispatch: Dispatch<any>,
  gradient: string,
  setToggleButton: Dispatch<SetStateAction<boolean>>,
) => {
  const player = window.threekit.player;
  const configurator = window.threekit.configurator;
  const attribute = configurator
    .getDisplayAttributes()
    .find(({ id }: { id: string; }) => id === attributeId);
  if (!attribute) {
    return;
  }
  const attrName = attribute.name;
  const attributeValues = generateNumberAttributeValues(attribute);
  const configuration = configurator.getConfiguration();
  let curPct = configuration[attrName];
  const attrCount = attributeValues.length;
  const threshold = 1 / attrCount;

  const setGradientDebounced = debounce(() => {
    setGradientBackgroundToCanvas({ canvasId: 'canvas', dispatch, currentGradient: gradient, rotationUpdate: true });
  }, 300);

  setTimeout(() => {
    setGradientBackgroundToCanvas({ canvasId: 'canvas', dispatch, currentGradient: gradient });
  }, 1);

  player.tools.addTool({
    key: 'zoom',
    active: false,
    enabled: false,
  });

  !isFullScreen &&
    player.tools.addTool({
      key: '2Dspin',
      active: isRotable,
      enabled: isRotable,
      handlers: {
        drag: () => ({
          handle: async (event: any) => {
            dispatch(setRotationUpdate(true));
            setGradientDebounced();
            const configuration = configurator.getConfiguration();
            const deltaT = event.deltaX / Math.max(event.rect.width, maxWidth);
            const newPct = curPct + deltaT;
            if (Math.abs(newPct) < 0.05 && Math.abs(newPct) > threshold) {
              const currentValueIndex = attributeValues.findIndex(
                (attributeValue) => attributeValue === configuration[attrName]
              );
              const increment = (newPct > 0 ? 1 : -1) * (direction < 0 ? -1 : 1);
              const newIndex = (currentValueIndex + increment) % attrCount;
              const attributeValue: string | number | boolean =
                attributeValues[newIndex < 0 ? attrCount + newIndex : newIndex];
              const updatedConfig: Config = {
                [attrName]: attributeValue,
              };
              handleLastAngle(updatedConfig);
            }
            curPct = newPct % threshold;
            setTimeout(() => {
              dispatch(setRotationUpdate(false));
            }, 50);
          },
          momentum: true,
        }),
      },
    });

  const putZoom = () => {
    removeIcon();
    player.tools.addTool('zoom');
    player.tools.removeTool('2Dspin');
  };

  const removeIcon = () => {
    setTimeout(() => {
      setToggleButton(false);
    }, 3000);
  };

  const removeZoom = () => {
    player.tools.removeTool('zoom');
    player.tools.addTool({
      key: '2Dspin',
      active: isRotable,
      enabled: isRotable,
      handlers: {
        drag: () => ({
          handle: async (event: any) => {
            dispatch(setRotationUpdate(true));
            setGradientDebounced();
            const configuration = configurator.getConfiguration();
            const deltaT = event.deltaX / Math.max(event.rect.width, maxWidth);
            const newPct = curPct + deltaT;
            if (Math.abs(newPct) < 0.05 && Math.abs(newPct) > threshold) {
              const currentValueIndex = attributeValues.findIndex(
                (attributeValue) => attributeValue === configuration[attrName]
              );
              const increment = (newPct > 0 ? 1 : -1) * (direction < 0 ? -1 : 1);
              const newIndex = (currentValueIndex + increment) % attrCount;
              const attributeValue: string | number | boolean =
                attributeValues[newIndex < 0 ? attrCount + newIndex : newIndex];
              const updatedConfig: Config = {
                [attrName]: attributeValue,
              };
              handleLastAngle(updatedConfig);
            }
            curPct = newPct % threshold;
            setTimeout(() => {
              dispatch(setRotationUpdate(false));
            }, 50);
          },
          momentum: true,
        }),
      },
    });
  };

  isFullScreen ? putZoom() : removeZoom();
};

export default add2DSpinTool;