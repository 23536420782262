import { PayloadAction } from '@reduxjs/toolkit';
import { LiveSessionState } from '..';
import { LOCAL_STORAGE_ROOM_ID_KEY } from '../../../utils/constants';

export const setServerSocket = (
  state: LiveSessionState,
  action: PayloadAction<string>
) => {
  state.serverSocket = action.payload;
};

export const setHubName = (
  state: LiveSessionState,
  action: PayloadAction<string>
) => {
  state.hubName = action.payload;
};

export const setHideRemoteViewer = (
  state: LiveSessionState,
  action: PayloadAction<boolean>
) => {
  state.hideRemoteViewer = action.payload;
};

export const setServerSocketPath = (
  state: LiveSessionState,
  action: PayloadAction<string>
) => {
  state.serverSocketPath = action.payload;
};

export const setIsSessionLive = (
  state: LiveSessionState,
  action: PayloadAction<boolean>
) => {
  state.isSessionLive = action.payload;
};

export const setIsExpireModalShowing = (
  state: LiveSessionState,
  action: PayloadAction<boolean>
) => {
  state.isExpireModalShowing = action.payload;
};

export const setClosingDate = (
  state: LiveSessionState,
  action: PayloadAction<Date | undefined>
) => {
  state.closingDate = action.payload;
};

export const setRoomClosedDueToInactivity = (
  state: LiveSessionState,
  action: PayloadAction<boolean>
) => {
  state.roomClosedDueToInactivity = action.payload;
};

export const setBaseUrlClientRemoteViewer = (
  state: LiveSessionState,
  action: PayloadAction<string>
) => {
  state.baseUrlClientRemoteViewer = action.payload;
};

export const setRoomId = (state: LiveSessionState, action: PayloadAction<string>) => {
  state.roomId = action.payload;
};

export const setIsSharingModalOpen = (
  state: LiveSessionState,
  action: PayloadAction<boolean>
) => {
  state.isSharingModalOpen = action.payload;
};

export const setLastConfig = (
  state: LiveSessionState,
  action: PayloadAction<LiveSessionState['lastConfig']>
) => {
  state.lastConfig = action.payload;
};

export const setRotationUpdates = (
  state: LiveSessionState,
  action: PayloadAction<LiveSessionState['rotationUpdates']>
) => {
  state.rotationUpdates = action.payload;
};

export const addRotateModelUpdate = (
  state: LiveSessionState,
  action: PayloadAction<LiveSessionState['rotationUpdates']>
) => {
  state.rotationUpdates = [...(state.rotationUpdates || []), action.payload];
};

export const emptyRotateModelUpdates = (state: LiveSessionState) => {
  state.rotationUpdates = [];
};

export const setIsFrozen = (state: LiveSessionState, action: PayloadAction<boolean>) => {
  state.isFrozen = action.payload;
};

export const setIsInvalidCode = (
  state: LiveSessionState,
  action: PayloadAction<boolean>
) => {
  state.isInvalidCode = action.payload;
};

export const setUrlCopied = (state: LiveSessionState, action: PayloadAction<boolean>) => {
  state.urlCopied = action.payload;
};

export const setUserInputtedCode = (
  state: LiveSessionState,
  action: PayloadAction<string>
) => {
  state.code = action.payload;
};

export const resetLiveSession = (state: LiveSessionState) => {
  state.isSessionLive = false;
  state.roomId = '';
  state.lastConfig = {};
  state.rotationUpdates = [];
  state.isFrozen = false;
  state.isInvalidCode = false;
  state.urlCopied = false;
  state.code = '';
  state.closingDate = undefined;
  state.code = '';
  state.isExpireModalShowing = false;
  state.roomClosedDueToInactivity = false;
  state.isSharingModalOpen = false;
  localStorage.removeItem(LOCAL_STORAGE_ROOM_ID_KEY);
};
