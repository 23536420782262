import { PayloadAction } from "@reduxjs/toolkit";
import { ThreekitState } from "..";

export const viewUpdateReducers = {
  setViewUpdate(state: ThreekitState,
    action: PayloadAction<ThreekitState['viewUpdate']>) {
    state.viewUpdate = action.payload;
  },

  setRotationUpdate(state: ThreekitState,
    action: PayloadAction<ThreekitState['rotationUpdate']>) {
    state.rotationUpdate = action.payload;
  },
};