import { OptionsButton } from '../../../../components';
import { t } from 'i18next';
import { useRef } from 'react';
import {
  QRCODE_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL,
  START_SHARING_BUTTON_LABEL,
  COPIED_URL_MODAL_BUTTON_LABEL_DISABLED,
  VALID_ROOM_ID_LENGTH,
  QRCODE_SHARE_BUTTON_LABEL,
  QRCODE_SCAN_INSTRUCTION,
  QRCODE_GENERATION_LABEL,
} from '../../../../utils/constants';
import QRCode from 'react-qr-code';
import { ButtonRow } from '../ButtonRow';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_ICON } from '../../../../assets';

import {
  getIsInvalidCode,
  getRoomId,
  getUrlCopied,
  setIsInvalidCode,
  setRoomId,
} from '../../../../store/liveSessionSlicer';
import {
  TvCode,
  InvalidCodeValidationMessage,
  Input,
  FakePlaceHolder,
  Clear,
  QRCodeContainer,
  InstructionsText,
} from './styles';
import {
  getParams,
  paramsObjectToNavigationString,
} from '../../../../utils/function/navigationParams';
import { useNavigate } from 'react-router-dom';
import { getIsChina } from '../../../../store/threekitSlicer';
import { deleteRoomIdFromParams } from '../../../../utils/deleteRoomIdFromParams';

interface StartSharingProps {
  handleCloseModal: () => void;
  isMobile: boolean;
  QRModalOpen: boolean;
  setQrModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleStartSession: (code: string) => void;
  copyURL: () => void;
  clientUrl: string;
}

function StartSharing({
  isMobile,
  QRModalOpen,
  setQrModalOpen,
  handleStartSession,
  copyURL,
  clientUrl,
}: StartSharingProps) {
  const dispatch = useDispatch();
  const isInvalidCode = useSelector(getIsInvalidCode);
  const urlCopied = useSelector(getUrlCopied);
  const roomId = useSelector(getRoomId);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const isChina = useSelector(getIsChina);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (value: string) => {
    dispatch(setIsInvalidCode(false));
    const regex = /^\d{0,7}$/;
    if (regex.test(value) && value.length <= VALID_ROOM_ID_LENGTH) {
      dispatch(setRoomId(value + ''));
    }
  };

  const handleClear = () => {
    dispatch(setRoomId(''));
    dispatch(setIsInvalidCode(false));
    deleteRoomIdFromParams({ navigate, isChina });
  };

  const handleMouseDown = (e: any) => {
    e.currentTarget.classList.add('spinningForward');
  };

  const handleMouseUp = (e: any) => {
    e.currentTarget.classList.remove('spinningForward');
  };

  return (
    <>
      <TvCode onClick={handleClick}>
        <Input
          error={!!isInvalidCode}
          maxLength={VALID_ROOM_ID_LENGTH}
          value={roomId}
          ref={inputRef}
          onChange={(e) => handleChange(e.target.value)}
        />
        <FakePlaceHolder error={!!isInvalidCode}>
          {t('info.input.tv_code', 'Enter TV code')} :{' '}
        </FakePlaceHolder>
        <Clear
          roomId={roomId}
          src={CLOSE_ICON}
          alt={'close'}
          onClick={() => handleClear()}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        />
      </TvCode>
      {isInvalidCode && (
        <InvalidCodeValidationMessage>
          {t('error.invalid.code', 'Error: Invalid Code')}
        </InvalidCodeValidationMessage>
      )}
      {roomId.length === VALID_ROOM_ID_LENGTH && (
        <OptionsButton
          buttonName={START_SHARING_BUTTON_LABEL}
          fnButton={() => handleStartSession(roomId)}
          isDisabled={roomId.length !== VALID_ROOM_ID_LENGTH}
        />
      )}
      <ButtonRow>
        <OptionsButton
          buttonName={
            urlCopied
              ? COPIED_URL_MODAL_BUTTON_LABEL_DISABLED
              : COPIED_URL_MODAL_BUTTON_LABEL
          }
          buttonText={
            clientUrl === 'loading'
              ? 'Loading Sharing URL...'
              : clientUrl === 'error'
              ? 'Error while loading Sharing URL'
              : urlCopied
              ? 'URL Copied to clipboard'
              : 'Copy URL to clipboard again'
          }
          fnButton={['loading', 'error'].includes(clientUrl) ? () => {} : copyURL}
          isDisabled={['loading', 'error'].includes(clientUrl)}
        />
        <OptionsButton
          buttonName={QRCODE_BUTTON_LABEL}
          fnButton={() => setQrModalOpen(true)}
          buttonText={t('info.label.generate_qrcode', QRCODE_GENERATION_LABEL)}
        />
      </ButtonRow>
      {QRModalOpen && (
        <Modal
          opacity={QRModalOpen ? 0 : 1}
          isOpen={true}
          onClose={() => {
            setQrModalOpen(false);
          }}
          title={t('button.label.sharing_qrcode', QRCODE_SHARE_BUTTON_LABEL)}
          isMobile={isMobile}
          overlappingModal={true}
        >
          <QRCodeContainer>
            <QRCode value={clientUrl} />
            <InstructionsText>
              {t('info.label.scan_qrcode', QRCODE_SCAN_INSTRUCTION)}
            </InstructionsText>
          </QRCodeContainer>
        </Modal>
      )}
    </>
  );
}

export default StartSharing;
