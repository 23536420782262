import { io, Socket, ManagerOptions, SocketOptions } from 'socket.io-client';
import { emitSocketAction, SocketActions } from '../utils/function/apiFn';

class SocketSingleton {
  private static instance: SocketSingleton;
  public socket: Socket | null = null;

  private constructor() { }

  public static getInstance(): SocketSingleton {
    if (!SocketSingleton.instance) {
      SocketSingleton.instance = new SocketSingleton();
    }
    return SocketSingleton.instance;
  }

  public connectSocket({
    url,
    path,
    query,
  }: {
    url: string;
    path: string;
    query: { role: string; roomId: string, hub: string };
  }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.disconnectSocket();
      }

      const options: Partial<ManagerOptions & SocketOptions> = {
        path,
        query,
        transports: ['websocket'],
        reconnection: true
      };

      this.socket = io(url, options);

      this.socket.emit(
        'checkIsRoomValid',
        { roomId: query.roomId },
        (isValid: boolean) => {
          resolve(isValid);
        }
      );

      this.socket.on('connect_error', (error: Error) => {
        reject(error);
      });
    });
  }

  public disconnectSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public emit({
    roomId,
    action,
    data,
  }: {
    roomId: string;
    action: SocketActions;
    data: any;
  }): void {
    if (!this.socket || !roomId || !action) return;
    emitSocketAction({ socket: this.socket, roomId, action, data });
  }
}

export default SocketSingleton.getInstance();
