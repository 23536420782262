import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, stateRoot } from '../../index';
import {
  ConfigurationState,
  IAttribute,
  ThreekitState,
  setAttributes,
  setForm,
  setPrice,
} from '..';
import { addRotateModelUpdate } from '../../liveSessionSlicer';
import { getMergedAttributes } from '../../../utils/function/functions';
import { emitSocketAction } from '../../../utils/function/apiFn';
import socketInstance from '../../../socket/socket';
// import io from 'socket.io-client';

interface LaunchConfig {
  orgId: string;
  threekitEnv: string;
  serverUrl: string;
  language?: string;
  additionalTools?: any;
  threekitProductEnv: string;
  authProductToken: string;
  isChina?: boolean;
  compression?: boolean;
  isLocalHost?: boolean;
  [key: string]: any;
}

export const configurationReducers = {
  setProduct: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['product']>
  ) => {
    state.configuration.product = action.payload;
  },
  setInitialConfiguration: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['initialConfiguration']>
  ) => {
    state.configuration.initialConfiguration = action.payload;
  },
  setValidAttributes: (
    state: ThreekitState,
    action: PayloadAction<
      ConfigurationState['formValidAttributes']['validAttributes']
    >
  ) => {
    state.configuration.dataDrivenConfiguratorExtensionStatus.validAttributes =
      action.payload;
  },
  setSku: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['formValidAttributes']['sku']>
  ) => {
    state.configuration.dataDrivenConfiguratorExtensionStatus.sku =
      action.payload;
  },
  setFormValidAttributes: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['formValidAttributes']>
  ) => {
    state.configuration.formValidAttributes = action.payload;
  },
  setPrice: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['price']>
  ) => {
    state.configuration.price = action.payload;
  },
  setName: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['name']>
  ) => {
    state.configuration.name = action.payload;
  },
  setActiveAttribute: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['activeAttribute']>
  ) => {
    state.configuration.activeAttribute = action.payload;
  },
  setDependencies: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['dependencies']>
  ) => {
    state.configuration.dependencies = action.payload;
  },
  setForm: (
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['form']>
  ) => {
    state.configuration.form = action.payload;
  },
  setMetadata(
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['metadata']>
  ) {
    state.configuration.metadata = action.payload;
  },
  setAttributes(
    state: ThreekitState,
    action: PayloadAction<ConfigurationState['attributes']>
  ) {
    const newAttributes = Object.assign(
      {},
      state.configuration.attributes,
      action.payload
    );
    state.configuration.attributes = { ...newAttributes };
  },
};

export const getAllAttributesForSetConfiguration = () => {
  const attributes = window?.threekit?.controller?.getAttributes() || {};
  const dataDriven = window?.dataDrivenConfiguratorExtension?.getStatus()?.validAttributesAndTheirValues || [];
  const mergedAttributes = getMergedAttributes(attributes, dataDriven) as Record<string, IAttribute>;
  return mergedAttributes;
};

export const setConfiguration = createAsyncThunk(
  'threekit/setConfiguration',
  async (config: LaunchConfig | any, { dispatch, getState }) => {
    const preppedConfig = config || {};
    const state: RootState = getState() as RootState;
    const isSingleRotateModel = Object.keys(preppedConfig)?.length === 1 && Object.keys(preppedConfig)[0] === 'Rotate Model';
    const isSocket = !!socketInstance?.socket;
    const mergedAttributesBeforeUpdate = getAllAttributesForSetConfiguration() as Record<string, IAttribute>;

    if (isSingleRotateModel) {
      const key = Object.keys(preppedConfig)[0];
      const rawValue = Object.values(preppedConfig)[0];
      const value = Math.abs(rawValue as number);
      const attributeRotationToUpdate = { [key]: value };

      await window.dataDrivenConfigurator.setConfiguration(attributeRotationToUpdate);
      if (isSocket) {
        dispatch(addRotateModelUpdate(preppedConfig));
      }
    }
    else if (Object.keys(preppedConfig).length === 1) {
      await window.dataDrivenConfigurator.setConfiguration(preppedConfig);
    } else {
      await setDataDrivenFewConfiguration(preppedConfig);
    }
    const updatedAttributes = await window.threekit.controller.getAttributes();
    const mergedAttributesWithDataDriven = getAllAttributesForSetConfiguration() as Record<string, IAttribute>;

    if (isSocket && !isSingleRotateModel) {
    //   const mergedAttributesWithDataDrivenNew = Object.entries(mergedAttributesWithDataDriven).filter(([attributeName, attributeData]: any[]) => {
    //     const value = attributeData?.value;
    //     if (['number', 'string'].includes(typeof value)) return mergedAttributesBeforeUpdate[attributeName]?.value !== value;
    //     return mergedAttributesBeforeUpdate[attributeName]?.value?.assetId !== attributeData?.value?.assetId
    // }
    //   )?.reduce((acc: any, [key, value]: [string, any]) => {
    //     acc[key] = value;
    //     return acc;
    //   }, {});

    // First emit with all the attributes
    const attributeToValue = Object.entries(mergedAttributesWithDataDriven).map(([key, value]: [string, any]) => {
        return { [key]: value?.value }
      })
      emitSocketAction({
        socket: socketInstance?.socket,
        roomId: state.liveSession?.roomId,
        action: 'updateProductData',
        data: JSON.stringify(attributeToValue),
      });

      // Second emit to solve errors in some products
      const config = {
        ...preppedConfig,
        'Rotate Model': mergedAttributesWithDataDriven['Rotate Model']?.value,
        'Model Presentation': mergedAttributesWithDataDriven['Model Presentation']?.value,
        Camera: mergedAttributesWithDataDriven['Camera']?.value,
      };
      const attributeToValue2 = Object.entries(config)?.map(([key, value]:any[])=>{return {[key]: value}});
      emitSocketAction({
        socket: socketInstance?.socket,
        roomId: state.liveSession?.roomId,
        action: 'updateProductData',
        data: JSON.stringify(attributeToValue2),
      });
    }

    dispatch(setForm(window.threekit.controller.getForm(updatedAttributes)));
    dispatch(setAttributes({ ...mergedAttributesWithDataDriven }));
    dispatch(setPrice(window.threekit.controller.getPrice()));
    // dispatch(setPlayerLoading(false));
  }
);

const setDataDrivenFewConfiguration = async (
  attributes: Record<string, any>
) => {
  for (const [key, value] of Object.entries(attributes)) {
    await window.dataDrivenConfigurator.setConfiguration({
      [key]: value,
    });
  }
};

export const updateModelPresentationOnDataDriven =
  () => async (dispatch: any, getState: () => stateRoot) => {
    const { threekit } = getState();
    const currentModelPresentation =
      threekit?.configuration?.attributes?.['Model Presentation']?.value;
    await window.dataDrivenConfigurator.setConfiguration({
      'Model Presentation': currentModelPresentation,
    });
  };
